import React from "react"
import {
  InstagramLargeFilledMono,
  TiktokLargeFilledMono,
  YoutubeLargeFilledMono,
  BoardSaveLargeFilledMono,
  InstagramLargeFilledColored,
  TiktokLargeFilledColored,
  YoutubeLargeFilledColored,
  PinterestLargeFilledMono,
  PinterestLargeFilledColored,
  PencilSmallFilledMono,
  InfoMediumFilledMono,
  FacebookColor,
  FacebookMono,
  SnapchatColor,
  SnapchatMono,
  BlogMono,
  PkgMono,
  UGCColor,
  UGCMono,
  PlayLargeFilledMono,
  SqaureLargeFilledMono,
  DownCaret,
  TrashSmallFilledMono,
  EmailPositiveBlue,
  EmailNegative,
  SmileyFaceMono,
  RegenerateSmall,
  CheckMediumFilledMono,
  ChatgptIcon,
  BanSolid,
} from "./Icons"
import { FullLoader, Loader } from "../components/loaders"
import ReactTooltip from "react-tooltip"
import { COMMENT_STATE_MESSAGES, LINK_TO_TERMS } from "../consts"
import { useEffect, useRef, useState } from "react"
import { convertToPercentage, num2Hip, copyToClipboard, cents2USD } from "../helpers/utils"
import { AutoHeightTextarea } from "./input"
import { useClickAway } from "../hooks/helperHooks"
import EmojiPicker from "emoji-picker-react"

function PlatformIcon({ platform, color = false }) {
  switch (platform) {
    case "instagram":
      return color ? InstagramLargeFilledColored : InstagramLargeFilledMono
    case "tiktok":
      return color ? TiktokLargeFilledColored : TiktokLargeFilledMono
    case "youtube":
      return color ? YoutubeLargeFilledColored : YoutubeLargeFilledMono
    case "facebook":
      return color ? FacebookColor : FacebookMono
    case "snapchat":
      return color ? SnapchatColor : SnapchatMono
    case "pinterest":
      return color ? PinterestLargeFilledColored : PinterestLargeFilledMono
    case "blog":
      return color ? BlogMono : BlogMono
    case "package":
      return color ? PkgMono : PkgMono
    case "packages":
      return color ? PkgMono : PkgMono
    case "ugc":
      return color ? UGCColor : UGCMono
    default:
      return null
  }
}

function ContentTypesIcon({ type, color = false }) {
  switch (type) {
    case "package":
      return color ? PkgMono : PkgMono
    case "packages":
      return color ? PkgMono : PkgMono
    case "video":
      return color ? PlayLargeFilledMono : PlayLargeFilledMono
    case "photo":
      return color ? SqaureLargeFilledMono : SqaureLargeFilledMono
    default:
      return null
  }
}

function RoundButton({ className = "", onClick, icon = PencilSmallFilledMono }) {
  return (
    <div
      onClick={onClick}
      className={`cs-edit-button no-flex-shrink flex hcenter vcenter nc05-bg round nc50-fg cursor-pointer ${className}`}
    >
      {icon}
    </div>
  )
}

function InfoIcon({ className, text, location = "right", dark = false }) {
  const jibberish = text.replace(/\s+/g, "").toLowerCase()
  return (
    <>
      <div data-tip data-for={jibberish} className={`info-icon flex vcenter ${className} cursor-pointer`}>
        {InfoMediumFilledMono}
      </div>
      <ReactTooltip
        className="tooltip-info-icon"
        id={jibberish}
        backgroundColor={dark ? "#000000" : "#F1F2F3"}
        textColor={!dark ? "#000000" : "#F1F2F3"}
        place={location}
        effect="solid"
      >
        {text}
      </ReactTooltip>
    </>
  )
}

function TabSeparator({ index, total, className }) {
  if (index < total - 1) return <div className={className}></div>
  return null
}

function MailtoHellozelf({ className, address = "hello@hellozelf.com" }) {
  return (
    <a className={`mailto-hellozelf txt-underlined nc00-fg ${className}`} href={`mailto:${address}`}>
      {address}
    </a>
  )
}
function TermsLink({ className, text, defaultClass = "mailto-hellozelf txt-underlined nc00-fg" }) {
  return (
    <a target="_blank" rel="noreferrer" className={`${defaultClass} ${className}`} href={LINK_TO_TERMS}>
      {text ? text : "Terms of Service"}
    </a>
  )
}

const StepCounter = ({ currentStep = 4, totalStep = 4 }) => {
  let steps = []
  for (let i = 0; i < totalStep; i++) {
    steps.push(<div key={i} className={`user-tour-step ${i === currentStep - 1 ? "active" : ""}`}></div>)
  }

  return <div className="flex a-center">{steps}</div>
}

function ArrowLessTooltip({
  id,
  containerElement,
  tooltipConent,
  additionalTooltipConentClassName = "",
  place = "bottom",
  tipID,
}) {
  return (
    <>
      <div data-tip data-for={id}>
        {containerElement}
      </div>
      <ReactTooltip
        className={"arrowless-tooltip"}
        id={id}
        place={place}
        effect="solid"
        backgroundColor="rgba(255,255,255, 0.0)"
        textColor="#000000"
        arrowColor="rgba(255,255,255, 0.0)"
        opacity={1}
      >
        <div className={`arrowless-tooltip-content nc00-bg ${additionalTooltipConentClassName}`}>{tooltipConent}</div>
      </ReactTooltip>
    </>
  )
}

function FullContentSyncedTop({ data }) {
  if (!data?.on_creator_dashboard) return null
  if (
    data?.me?.connected_accounts?.[data?.creator_current_platform]?.connection_status_is_init_full_content_synced !==
    false
  )
    return null
  return (
    <div className="creator-contentload-indicator-top headline-small-highlight padding-y-2x padding-x-8x ig-yellow-bg nc100-fg txt-center margin-top-8x">
      Please note: It can take up to 15 minutes for all your content and information to load.Don’t be alarmed when you
      see only a few posts initially.
    </div>
  )
}
function FullContentSyncedBottom({ data }) {
  if (!data?.on_creator_dashboard) return null
  if (
    data?.me?.connected_accounts?.[data?.creator_current_platform]?.connection_status_is_init_full_content_synced !==
    false
  )
    return null
  return (
    <div className="creator-contentload-indicator-bottom body-regular-font padding-y-2x padding-x-8x nc50-fg txt-center margin-top-8x">
      Please note: It can take up to 15 minutes for all your content and information to load.Don’t be alarmed when you
      see only a few posts initially.
    </div>
  )
}
function QuantityPicker({
  quantity,
  onIncreament,
  onDecreament,
  customElement,
  decrementDisabled,
  incrementDisabled,
  className = "",
}) {
  return (
    <div className={`flex a-center ${className}`}>
      <span
        className={`change-quantity-action flex a-center j-center cursor-pointer  icon-container-large round mobile-nc05-bg ${decrementDisabled ? "pointer-event-none nc50-fg opacity-half" : "nc50-fg"}`}
        onClick={onDecreament}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path d="M9.203 12.723v-1.446h5.593v1.446H9.203Z" />
        </svg>
      </span>
      <div className="flex a-center j-center margin-x-2x no-select-text body-highlight-font">
        {customElement ? customElement : quantity}{" "}
      </div>
      <span
        className={`change-quantity-action flex a-center j-center cursor-pointer  icon-container-large round mobile-nc05-bg ${incrementDisabled ? "pointer-event-none nc50-fg opacity-half" : "nc50-fg"}`}
        onClick={onIncreament}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path d="M8.396 12.688v-1.377h7.208v1.377H8.396Zm2.907-4.47h1.411v7.565h-1.41V8.216Z" />
        </svg>
      </span>
    </div>
  )
}

function DummyThumb({ platform }) {
  return (
    <div className="cb-summary-cart-item-number-container flex a-center j-center margin-right-2x">
      <div className="cb-cart-summary-item-number nc00-bg flex a-center j-center ultralight-fg">
        <PlatformIcon platform={platform} color={false} />
      </div>
    </div>
  )
}

function ExpandableText({
  text,
  lines = 1,
  defaultExpanded = false,
  className,
  showMoreClasses,
  showLessClasses,
  onChange,
  showMoreText = "See More",
  showLessText = "See Less",
}) {
  const [expanded, changeExpanded] = useState(defaultExpanded)
  const tel = useRef(null)
  const [hasMore, setHasMore] = useState(false)
  const collapse = () => {
    changeExpanded(true)
    if (onChange) onChange(true)
  }
  const fold = () => {
    changeExpanded(false)
    if (onChange) onChange(false)
  }
  useEffect(() => {
    if (tel?.current?.scrollHeight > tel?.current?.clientHeight) {
      setHasMore(true)
    } else setHasMore(false)
  }, [expanded])

  if (!text) return null
  return (
    <div className={`expandable-text-container ${expanded ? "expanded" : ""} ${className}`}>
      <span ref={tel} className={`${hasMore && !expanded ? "morecontent" : ""} exp-lines-${lines} expandable-text`}>
        {text}
      </span>
      {hasMore || expanded ? (
        <>
          {expanded ? (
            <span onClick={fold} className={`${showLessClasses} showless-expanded expand-actions`}>
              {showLessText}
            </span>
          ) : (
            <span onClick={collapse} className={`showmore-expandable expand-actions ${showMoreClasses}`}>
              {showMoreText}
            </span>
          )}
        </>
      ) : null}
    </div>
  )
}

function Separator({ content }) {
  return (
    <div className="date-separatot-for-content headline-medium-highlight margin-y-3x nc75-fg">{content.content}</div>
  )
}
function DotSeparator({ className = "" }) {
  return <div className={`dot-separator ${className}`}></div>
}
function BarSeparator(props) {
  return <div className={`bar-separator ${props.className}`}></div>
}
function StatBlock({
  title,
  data,
  hip = true,
  align = "center",
  separator = true,
  missingText = "N/A",
  className = "",
  percentage = false,
  percentageBase = 1,
  titleFont = "caption-regular-font",
  isCent = false,
  padding = "padding-2x",
}) {
  let classes = `stat-info-block border-box ${padding} flex vertical ${className}`
  if (separator) classes += " border-right-1 nc05-border"
  return (
    <div className={classes}>
      <div className={`stat-info-data headline-medium-regular margin-bottom nc100-fg txt-${align}   txt-uppercase`}>
        {data || data === 0 ? (
          <>
            {" "}
            {percentage
              ? convertToPercentage(data, percentageBase, "N/A")
              : hip
                ? num2Hip(data, 1, null)
                : isCent
                  ? `$${num2Hip(Number(cents2USD(data, false, 2)))}`
                  : data}{" "}
          </>
        ) : (
          missingText
        )}
      </div>
      <div className={`stat-info-title no-flex-shrink ${titleFont} nc50-fg txt-${align}`}>{title}</div>
    </div>
  )
}
function Card({ children, className, ...props }) {
  return (
    <div
      className={`generic-card nc05-border border-radius-12 border-2 border-bottom-4 nc00-bg ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}
function ProfilePictureCloud({ images = [], links = [], cutoff = 5, total = 0, className = "" }) {
  const rest = total ? total - cutoff : images.length - cutoff
  return (
    <div className={`pp-cloud flex ${className}`}>
      {images.slice(0, cutoff).map((_i, idx) => (
        <a
          className="pp-cloud-link-holder"
          style={{ zIndex: images.length - idx }}
          key={`${_i}_${idx}`}
          href={links[idx] ?? "#"}
        >
          <img alt={links[idx] || "creator"} className="pp-cloud-image nc00-border border-2" src={_i} />
        </a>
      ))}
      {rest > 0 ? (
        <div className="rest-stat-pp-cloud flex hcenter vcenter border-circle nc00-border border-2 nc10-bg button-small-font nc100-fg">
          {rest <= 99 ? `+${rest}` : `${rest}+`}
        </div>
      ) : null}
    </div>
  )
}

function ContentCloud({ images = [], cutoff = 6, total = 0, className = "" }) {
  const rest = total ? total - cutoff : images.length - cutoff
  return (
    <div className={`content-cloud flex ${className}`}>
      {images.slice(0, cutoff - 1).map((_img, _i) => (
        <img key={_i} src={_img} alt="Content" className="content-cloud-image border-radius-4 has-broken-fallback" />
      ))}
      {rest > 0 ? (
        <div className="content-cloud-rest">
          <img
            src={images[cutoff - 1]}
            className="content-cloud-image border-radius-4 has-broken-fallback"
            alt="rest"
          ></img>
          <div className="rest-text nc100-fg headline-medium-highlight flex hcenter vcenter">
            {rest <= 99 ? `+${rest}` : `${rest}+`}
          </div>
        </div>
      ) : null}
    </div>
  )
}

function WrappedInLoader({ loader, children, data = null, noContentText = "", silent = false }) {
  if (!loader && !data && !noContentText) return null
  if (loader && !silent) return <FullLoader />
  if (loader === false && (!data || data?.length === 0) && noContentText)
    return <div className="padding-y-4x txt-center nc50-fg headline-small-highlight">{noContentText}</div>
  return children
}

function Collapsible({ expanded = false, children, className = "" }) {
  return <div className={`collapsible-div ${expanded ? "state-expanded" : ""} ${className}`}>{children}</div>
}

function ExpandButtonInline({
  expandText = "View detail",
  collapseText = "Hide detail",
  trigger = false,
  onClick,
  textClassName = "",
  fontClassName = "button-medium-font nc50-fg",
}) {
  return (
    <span
      onClick={onClick}
      className={`expand-button-inline cursor-pointer ${fontClassName}  ${trigger ? "btn-state-expanded" : ""}`}
    >
      {trigger ? collapseText : expandText}
      <span className="expand-btn-icon margin-left-2x">{DownCaret}</span>
    </span>
  )
}

// remove action if we want to same element on order confirmation page
const SummaryCartItem = ({ className = "", title = "", chipElement = "", secondaryChipElement = "", onRemove }) => {
  // 'content' / 'addon'
  return (
    <div className={`flex   ${className}`}>
      <div className="full flex a-center nc75-fg">
        <span className="nc75-fg headline-small-highlight txt-left">{title}</span>
      </div>
      <div className="flex a-center">
        {secondaryChipElement ? (
          <p className="chip margin-left-2x txt-center nc50-fg  ">{secondaryChipElement}</p>
        ) : null}
        {chipElement ? <p className="chip margin-left-2x txt-center  ">{chipElement}</p> : null}
        {onRemove ? (
          <div
            className="icon-container-stroked-2px nc05-border-override nc30-fg cursor-pointer margin-left-2x"
            role="button"
            onClick={onRemove}
          >
            {TrashSmallFilledMono}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const CommonContentStats = ({
  data = {},
  gap = 4,
  globalSeparator = true,
  leftSeparator = false,
  rightSeparator = false,
  align = "center",
  prefixOfDataName = "",
  postfixOfDataName = "",
  className = "",
  showEngagementRateByFollower = false,
  skipCreatorPublic = true,
  padding,
  width = "",
}) => {
  return (
    <div className={`creator-circle-stats flex ${className}`}>
      {skipCreatorPublic ? null : (
        <StatBlock
          className={`padding-x-${gap}x ${width}`}
          align="center"
          padding={padding}
          separator={false}
          title={
            <>
              Creators <br /> posted
            </>
          }
          data={data?.total_creators ?? data?.total_creators_public}
        />
      )}
      <StatBlock
        className={`padding-x-${gap}x ${width} ${leftSeparator ? "border-left-1 nc05-border" : ""}`}
        separator={globalSeparator}
        align={align}
        padding={padding}
        title={
          <>
            Posts <br /> total
          </>
        }
        data={data?.total_posts ?? data?.[`${prefixOfDataName}total_posts${postfixOfDataName}`]}
      />

      <StatBlock
        className={`padding-x-${gap}x ${width}`}
        align={align}
        separator={globalSeparator}
        padding={padding}
        title={
          <>
            Total <br /> views
          </>
        }
        data={data?.total_views ?? data?.[`${prefixOfDataName}total_views${postfixOfDataName}`]}
      />
      <StatBlock
        className={`padding-x-${gap}x ${width}`}
        align={align}
        separator={globalSeparator}
        padding={padding}
        isCent={true}
        percentage={false}
        hip={false}
        title={
          <>
            Total
            <br />
            EMV
          </>
        }
        data={data?.total_emv_in_cents ?? data?.[`${prefixOfDataName}total_emv_in_cents${postfixOfDataName}`]}
      />
      <StatBlock
        className={`padding-x-${gap}x ${width}`}
        align={align}
        separator={globalSeparator}
        padding={padding}
        title={
          <>
            Total <br /> Comments
          </>
        }
        data={data?.total_comments ?? data?.[`${prefixOfDataName}total_comments${postfixOfDataName}`]}
      />
      <StatBlock
        className={`padding-x-${gap}x ${width}`}
        align={align}
        separator={globalSeparator}
        padding={padding}
        title={
          <>
            Total <br /> eng
          </>
        }
        data={data?.total_engagement ?? data?.[`${prefixOfDataName}total_engagement${postfixOfDataName}`]}
      />

      {!showEngagementRateByFollower ? (
        <StatBlock
          className={`padding-x-${gap}x ${width}`}
          align={align}
          padding={padding}
          separator={globalSeparator && rightSeparator}
          title={
            <>
              Eng <br />
              rate
            </>
          }
          data={data?.engagement_rate ?? data?.[`${prefixOfDataName}engagement_rate${postfixOfDataName}`] ?? 0}
          percentage
        />
      ) : (
        <StatBlock
          className={`padding-x-${gap}x ${width}`}
          align={align}
          padding={padding}
          separator={globalSeparator && rightSeparator}
          title={
            <>
              Engagement <br />
              rate of followers
            </>
          }
          data={
            data?.engagement_rate_by_followers ??
            data?.[`${prefixOfDataName}engagement_rate_by_followers${postfixOfDataName}`] ??
            0
          }
          percentage
        />
      )}
    </div>
  )
}

const EmailIndicator = ({ id, email, api, isGuest = true, toast, className = "flex a-center j-center padding-x" }) => {
  const copyEmailAddress = (e) => {
    e?.stopPropagation?.()
    if (!isGuest && email) copyToClipboard(email, () => toast && toast("Copied to clipboard"))
    else if (isGuest && email) api?.alert?.unlockFullExperience()
  }
  return (
    <div className={className}>
      <div
        onClick={copyEmailAddress}
        className="email-indicator flex vcenter"
        data-tip
        data-for={`${id}-email-tooltip`}
      >
        {email ? EmailPositiveBlue : EmailNegative}
      </div>
      {email ? (
        <ReactTooltip id={`${id}-email-tooltip`}>
          {!isGuest ? (
            <span> {email}</span>
          ) : (
            <span>Join Zelf to see email address and work with favorite creators</span>
          )}
        </ReactTooltip>
      ) : null}
    </div>
  )
}

const NewLineParser = ({ value }) => (
  <>
    {value.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
      </React.Fragment>
    ))}
  </>
)

const PreviousCommentWithState = ({ comment, failed, postingMassage = "", loading }) => {
  return (
    <div className="border-1  padding-5x border-radius-6 nc10-border margin-bottom-2x">
      <div className="flex a-center margin-bottom-2x">
        <span className="nc30-fg caption-regular-font">Your last comment</span>
        {loading ? (
          <span className="caption-regular-font nc50-fg margin-x-2x">
            <Loader className="margin-right-2x" /> posting
          </span>
        ) : null}

        {failed ? (
          <span className="caption-regular-font negative-fg margin-x-2x"> Comment failed</span>
        ) : postingMassage ? (
          <span className="chip margin-left-2x">
            <span className="caption-regular-font nc50-fg">{postingMassage}</span>{" "}
          </span>
        ) : null}
      </div>
      <div className="caption nc75-fg">{comment}</div>
    </div>
  )
}

const AICommentDumbUI = ({
  comment,
  setComment,
  platform,
  commentEnable,
  approveEnable = true,
  generateLoader,
  postingCommentLoader,
  onSend,
  onGenerate,
  onChatGPTClick,
  hideChatGPT,
  noBorder,
  enableYoutube = false,
  showNoCommentButton = false,
  onArchiveContent,
}) => {
  const [showEmoji, setShowEmoji] = useState(false)
  const emojiClickAwayRef = useClickAway(() => setShowEmoji(!showEmoji))
  const onEmojiClick = (e) => {
    setComment(comment + e.emoji)
  }
  const acceptedPlatform = ["tiktok"]
  if (enableYoutube) acceptedPlatform.push("youtube")
  const otherPlatform = !acceptedPlatform.includes(platform)
  return (
    <>
      <div
        data-tip={"comment-message"}
        data-for={"comment-message"}
        className={`full  a-center  ${noBorder ? "" : "border-1  nc10-border border-radius-8"}   nc02-bg`}
      >
        <div className="padding-3x ">
          <AutoHeightTextarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className={`full  bear-input  nc02-bg ${otherPlatform ? "nc10-placeholder" : ""}`}
            placeholder="leave a comment..."
          />
          <div className="flex j-end position-relative">
            <div className="nc30-fg" onClick={() => setShowEmoji(!showEmoji)}>
              {SmileyFaceMono}
            </div>
            {showEmoji ? (
              <div ref={emojiClickAwayRef} style={{ top: -365, right: 0, zIndex: 3000 }} className="position-absolute">
                <EmojiPicker height={350} onEmojiClick={onEmojiClick} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex border-top-1 nc10-border a-center j-space-between padding-3x nc00-bg border-radius-bottom-right-8 border-radius-bottom-left-8">
          {commentEnable ? (
            <>
              {" "}
              <div className="nc50-fg flex a-center button-tiny-font ">
                {generateLoader ? (
                  <div className="flex a-center">
                    <span className="margin-left margin-right">Generating comment...</span>
                    <Loader />
                  </div>
                ) : (
                  <div className=" flex a-center">
                    <span onClick={onGenerate} className="flex a-center j-center cursor-pointer">
                      {RegenerateSmall}
                    </span>
                    {hideChatGPT ? null : (
                      <span
                        onClick={() => onChatGPTClick && onChatGPTClick()}
                        className="margin-left-2x flex a-center j-center cursor-pointer"
                      >
                        {ChatgptIcon}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div></div>
          )}
          <div className="flex a-center">
            {showNoCommentButton ? (
              <div
                onClick={onArchiveContent}
                className="button-tiny-font flex a-center nc50-fg margin-right-2x cursor-pointer"
              >
                {BanSolid} <span className="">Don't comment</span>{" "}
              </div>
            ) : null}

            {approveEnable ? (
              <>
                {postingCommentLoader ? (
                  <div className="margin-left  nc50-fg caption-regular ">
                    <Loader /> Approving comment...
                  </div>
                ) : (
                  <div
                    className={`flex a-center  j-center ${otherPlatform ? "nc10-fg" : "primary-fg cursor-pointer"} ${generateLoader ? "action-disabled" : ""}`}
                    onClick={onSend}
                    role="button"
                  >
                    {CheckMediumFilledMono}
                    <span className="margin-x button-tiny-font">Approve</span>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {otherPlatform ? (
        <ReactTooltip id={"comment-message"}>Comment is not available for {platform} </ReactTooltip>
      ) : null}
    </>
  )
}

const PreviousComment = ({
  pending,
  failed,
  comment,
  className = "",
  headerText = "Your comment",
  failedText = "Comment failed",
}) => {
  return (
    <div className={`border-1  padding-5x border-radius-6 nc10-border ${className}`}>
      <div className="flex a-center margin-bottom-2x">
        <span className="nc30-fg caption-regular-font">{headerText}</span>
        {pending ? (
          <span className="caption-regular-font nc50-fg margin-x-2x">
            <Loader className="margin-right-2x" /> posting
          </span>
        ) : null}
        {failed ? <span className="caption-regular-font negative-fg margin-x-2x"> {failedText}</span> : null}
      </div>
      <div className="caption nc75-fg">{comment}</div>
    </div>
  )
}

const NewnessIndicator = ({ text = "New", className = "" }) => {
  return (
    <div
      style={{ backgroundColor: "#14BD58" }}
      className={`flex hcenter vcenter nc00-fg border-radius-4 border-1 nc00-border button-tiny-font ${className}`}
    >
      {text}
    </div>
  )
}

const SavedOnBoardStatus = ({ creator, className = "" }) => {
  return creator?.board_ids?.length ? (
    <>
      <div
        data-tip={`${creator?.id}-saved-on-boards`}
        data-for={`${creator?.id}-saved-on-boards`}
        className={`flex a-center j-center positive-fg ${className}`}
      >
        {BoardSaveLargeFilledMono}
      </div>
      <ReactTooltip id={`${creator?.id}-saved-on-boards`}>Saved in {creator?.board_names?.join(", ")}</ReactTooltip>
    </>
  ) : null
}

const YoutubeDataAggregationWarning = () => {
  return (
    <div className="padding-x-8x  txt-center padding-y-12x body-regular-font nc30-fg">
      In compliance with Youtube's Terms of Service, we cannot show you aggregate data, but you can still find the best
      performing content below
    </div>
  )
}

const Steps = ({ number, text = "", className = "" }) => {
  return (
    <div className={`flex a-center ${className}`}>
      <div className="onboarding-steps-number margin-right-3x">{number}</div>
      <p className="headline-small-highlight ">{text}</p>
    </div>
  )
}

export {
  PlatformIcon,
  ContentTypesIcon,
  RoundButton,
  InfoIcon,
  TabSeparator,
  MailtoHellozelf,
  TermsLink,
  StepCounter,
  ArrowLessTooltip,
  FullContentSyncedTop,
  FullContentSyncedBottom,
  QuantityPicker,
  DummyThumb,
  ExpandableText,
  Separator,
  DotSeparator,
  BarSeparator,
  StatBlock,
  Card,
  ProfilePictureCloud,
  ContentCloud,
  WrappedInLoader,
  Collapsible,
  ExpandButtonInline,
  SummaryCartItem,
  CommonContentStats,
  EmailIndicator,
  NewLineParser,
  AICommentDumbUI,
  PreviousComment,
  NewnessIndicator,
  SavedOnBoardStatus,
  YoutubeDataAggregationWarning,
  Steps,
  PreviousCommentWithState,
}
