import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import { Header } from "./components/structural/header"
import { Authenticated, Unauthenticated } from "./components/functional/auth_components"
import { useContext, useEffect, useState } from "react"
import { dataContext } from "./contexts/datacontext"
import { apiContext } from "./contexts/apicontext"
// Pages
import { CreatorHome } from "./components/pages/creatorHomePage"
import { NotSignedInZelfCreatorHome } from "./components/pages/creatorHomePage"
import { OnboardingStartPage } from "./components/pages/onboardingStartPage"
import { OnbaordingStepsPage } from "./components/pages/onbaordingStepsPage"
import { OnBoardingConnectSocialPage } from "./components/pages/onboardingConnectSocialPage"
import { OnBoardingConnectPaymentPage } from "./components/pages/onboardingConnectPaymentPage"
import { OnbaordingBrandDetails } from "./components/pages/onbaordingBrandDetails"
import { DetectDestination } from "./components/pages/detectDestination"
import { OauthCallBack } from "./components/pages/oauthCallback"
import { NotFound } from "./components/pages/notfound"
import { StartWithName } from "./components/pages/startWithName"
import { SendToLogin } from "./components/pages/sendToLogin"
import { ReferralPage } from "./components/pages/referralPage"
import { ReconnectLanding } from "./components/pages/reconnectLanding"
import { OnBoardingCreatorInvitedByX } from "./components/pages/onboardingCreatorInvitedByXBrand"
import { OnboardingCreatorStepDescription } from "./components/pages/onboardingCreatorStepsDescription"
import { OnBoardingCreatorConnectGraphApi } from "./components/pages/onboardingCreatorConnectIGGraphAPI"
import { OnboardingCreatorBioAndAddressInfo } from "./components/pages/onboardingCreatorBioAndAddressInfo"
import { OnboardingThankYou } from "./components/pages/onboardingThankyouPage"
import { Footer } from "./components/footer"

import {
  BrandEcosystem,
  DiscoverContent,
  DiscoverCreator,
  ReportingAndAnalysis,
} from "./components/pages/brandEcosystem"
import { DiscoverHashtags } from "./components/pages/discoverHashTags"

// dialogs
import { GraphAPITour } from "./components/graphAPITour"
import { GraphAPITourPreDialog } from "./components/dialogs/graphAPITourPreDialog"
import { InstagramReconnectDialog } from "./components/dialogs/instagramReconnectDialog"
import { SearchResult } from "./components/pages/searchResult"
import { ContentModal } from "./components/dialogs/creatorContentModal"
import { hardwareProbe, getQueryParam } from "./helpers/utils"
import { NotAvailableOnMobile } from "./components/not_on_mobile"
import { GuestWelcome } from "./components/guestwelcome"
import { DateSpecificContentContentPage, DateSpecificContentPopup } from "./components/structural/dateSpecificContent"
import { ContentSection, CreatorSection, TrendingContents } from "./components/structural/BrandEcosystem/discovery"
import { BoardDetails, Boards } from "./components/structural/BrandEcosystem/boards"
import { BrandStats } from "./components/pages/brandStats"
import { BrandCommentStats } from "./components/pages/brandCommentStats"
import { Engage, EngageSection, OwnedPostSection, Replies } from "./components/structural/BrandEcosystem/engage"
import { MyCreatorCircle } from "./components/structural/BrandEcosystem/myCreatorCircle"
import { MyCampaigns } from "./components/structural/BrandEcosystem/campaigns"
import { CampaignDetailPage } from "./components/structural/BrandEcosystem/campaignDetail"
import { PoliticalDashboard } from "./components/pages/politicalDashboard"
import { BrandCompetitorsStats } from "./components/pages/brandCompetitorsStats"

export default function App() {
  const [data, dispatch] = useContext(dataContext)
  const [searchParams] = useSearchParams()
  const [guest, setGuest] = useState("")
  // GUEST LOGIN THING

  const showAPopUp =
    data?.dialog_payload ||
    data?.ecosystem_selected_content?.content ||
    data?.date_specific_dialog_data ||
    data?.show_self_serve_brand_settings ||
    data?.show_priority_post_settings
  useEffect(() => {
    ; (async () => {
      const guest_brand_info = getQueryParam(window.location.search, "brand")
      const brand_id = guest_brand_info?.split("-")?.[1]
      if (brand_id) {
        setGuest(brand_id)
      }
    })()
  }, [])

  const sub_brand_id = searchParams.get("sub_brand_id")
  const be_section_view_all = searchParams.get("be_section_view_all")
  useEffect(() => {
    dispatch({
      sub_brand_id: sub_brand_id ? +sub_brand_id : null,
      // be_section_view_all:null
    })
  }, [sub_brand_id])

  useEffect(() => {
    if (be_section_view_all) {
      dispatch({
        be_section_view_all: be_section_view_all,
      })
      return
    }
    dispatch({
      be_section_view_all: null,
    })
  }, [be_section_view_all])

  const clearGuest = () => {
    setGuest("")
    window.location.href = "/"
  }

  const hardwareInfo = hardwareProbe()

  useEffect(() => {
    if (showAPopUp) {
      let scroll_bar_size =
        hardwareInfo?.is_mobile || !(document.body.scrollHeight > document.body.clientHeight) ? "0px" : "15px"
      document.body.style.setProperty("--removed-body-scroll-bar-size", scroll_bar_size)
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.setProperty("--removed-body-scroll-bar-size", "0px")
      document.body.style.overflow = "unset"
    }
  }, [showAPopUp])

  if (hardwareInfo?.is_mobile && data.role === "brand") return <NotAvailableOnMobile />
  if (guest) return <GuestWelcome brandID={guest} onGuestProcessEnd={clearGuest} />
  return (
    <>
      <div className={`creatorstock-app ${data?.is_me_creator ? "creators-own-page" : ""}`}>
        <Header />
        <div className=" full-min-height-without-header-and-footer">
          <Unauthenticated showWhileLoading={false}>
            <Routes>
              <Route path="/start-from-brand-invite" element={<OnBoardingCreatorInvitedByX />} />
              <Route path="/:creator_name" element={<CreatorHome />} />
              <Route path="/" element={<OnboardingStartPage />} />
              <Route path="/notfound" element={<NotFound />} />
              <Route path="/begin-creator" element={<StartWithName />} />
              <Route path="/login" element={<SendToLogin />} />
              <Route path="/search" element={<SearchResult />} />
            </Routes>
          </Unauthenticated>

          <Authenticated>
            <Routes>
              {/* creator specific routes */}
              <Route path="/start-from-brand-invite" element={<OnBoardingCreatorInvitedByX />} />
              <Route path="/start-description" element={<OnboardingCreatorStepDescription />} />
              <Route path="/start-graph" element={<OnBoardingCreatorConnectGraphApi />} />
              <Route path="/start-social" element={<OnBoardingConnectSocialPage />} />
              <Route path="/start-basic-info" element={<OnboardingCreatorBioAndAddressInfo />} />
              <Route path="/start-thank-you" element={<OnboardingThankYou />} />

              <Route path="/start-creator" element={<OnbaordingStepsPage />} />
              <Route path="/start-connect-socials" element={<OnBoardingConnectSocialPage />} />
              <Route path="/start-connect-payment" element={<OnBoardingConnectPaymentPage />} />
              <Route path="/creator-dashboard" element={<CreatorHome self={true} />} />
              <Route path="/create-your-referral" element={<ReferralPage />} />

              {/* brand specific routes  */}
              <Route path="/start-brand" element={<OnbaordingBrandDetails />} />
              {/* brand dashboard links */}
              <Route path="/brand-dashboard" element={<BrandEcosystem />}>
                <Route element={<ReportingAndAnalysis />}>
                  <Route path="" element={<BrandStats />}></Route>
                  <Route path="competitors" element={<BrandCompetitorsStats />}></Route>
                  <Route path="comments" element={<BrandCommentStats />}></Route>
                </Route>
              </Route>

              <Route path="/engage" element={<BrandEcosystem />}>
                <Route element={<Engage />} path="">
                  <Route
                    path=""
                    exact
                    element={
                      <EngageSection section={data?.engage_tab_section_name} key={data?.engage_tab_section_name} />
                    }
                  />
                  <Route path="owned" element={<OwnedPostSection />} />
                  <Route path="replies" element={<Replies />} />
                  <Route
                    path="archive"
                    exact
                    element={<EngageSection section={"mass_engage_archive"} key={"mass_engage_archive"} />}
                  />
                  <Route exact path="creator-boards" element={<Boards type="normal" category="creator" />} />
                  <Route
                    exact
                    path="creator-boards/:board_id"
                    element={<BoardDetails isEmailCreatorsEnable={true} />}
                  />
                </Route>
              </Route>

              <Route path="/discover-content" element={<BrandEcosystem />}>
                <Route path="" element={<DiscoverContent />}>
                  <Route exact path="" element={<ContentSection />} />
                  <Route exact path="trending" element={<TrendingContents />} />
                  <Route
                    exact
                    path="tracking-boards"
                    element={<Boards type="tracking" filter={{ board_type: 2 }} section={"board_list_tracking"} pageTitle="Boards for hashtag tracking" />}
                  />
                  <Route exact path="tracking-boards/:board_id" element={<BoardDetails hideCreator={true} />} />
                  <Route
                    exact
                    path="saved-content-boards"
                    element={<Boards type="normal" filter={{
                      board_type: 1,
                      category: 1
                    }} section={"board_list_content"} pageTitle={"Content Boards"} category="content" enableHashtagsInAddForm={false} />}
                  />
                  <Route
                    exact
                    path="saved-content-boards/:board_id"
                    element={<BoardDetails hideCreator={true} enableHashtagsInEditForm={false} />}
                  />
                  <Route
                    exact
                    path="ai-analytics-boards"

                    element={<Boards type="normal" filter={{
                      board_type: 1,
                      category: 1,
                      analytics_type: 1
                    }} pageTitle={"AI Insights"} hideCreateBoard section={"board_list_ai_insight"} category="content" enableHashtagsInAddForm={false} />}
                  />
                  <Route
                    exact
                    path="ai-analytics-boards/:board_id"
                    element={<BoardDetails hideCreator={true} enableHashtagsInEditForm={false} />}
                  />
                </Route>
              </Route>
              <Route path="/discover-creator" element={<BrandEcosystem />}>
                <Route path="" element={<DiscoverCreator />}>
                  <Route exact path="" element={<CreatorSection />} />
                  <Route exact path="creator-circle" element={<MyCreatorCircle />} />
                  <Route exact path="campaigns" element={<MyCampaigns />} />
                  <Route exact path="campaigns/:campaign_id" element={<CampaignDetailPage />} />
                  <Route
                    exact
                    path="creator-boards"
                    element={<Boards filter={{
                      board_type: 1,
                      category: 2
                    }} type="normal" pageTitle={"Saved Creators"} category="creator" section={"board_list_creator"} enableHashtagsInAddForm={false} />}
                  />
                  <Route
                    exact
                    path="creator-boards/:board_id"
                    element={<BoardDetails isEmailCreatorsEnable={true} enableHashtagsInEditForm={false} />}
                  />
                </Route>
              </Route>
              <Route path="/discover-contents" element={<BrandEcosystem _tab="discovery" _subtab="content" />} />
              <Route path="/discover-creators" element={<BrandEcosystem _tab="discovery" _subtab="creator" />} />
              <Route path="/discover-hashtags-new" element={<DiscoverHashtags />} />
              <Route path="/my-creator-circle" element={<BrandEcosystem _tab="circle" />} />
              <Route path="/engage" element={<BrandEcosystem _tab="engage" hideTabs />} />
              <Route path="/my-campaigns-live" element={<BrandEcosystem _tab="campaigns" _campaignTab="live" />} />
              <Route path="/my-campaigns-draft" element={<BrandEcosystem _tab="campaigns" _campaignTab="draft" />} />
              <Route
                path="/my-campaigns-boosted"
                element={<BrandEcosystem _tab="campaigns" _campaignTab="boosted" />}
              />

              <Route path="/date-specific-content" element={<DateSpecificContentContentPage />} />

              <Route path="/oauth-callback-pinterest" element={<OauthCallBack platform="pinterest" />} />
              <Route path="/oauth-callback-tiktok" element={<OauthCallBack platform="tiktok" />} />
              <Route path="/oauth-callback-youtube" element={<OauthCallBack platform="youtube" />} />
              <Route path="/oauth-callback-paypal" element={<OauthCallBack platform="paypal" />} />
              <Route path="/oauth-callback-instagram" element={<OauthCallBack platform="instagram" />} />
              <Route path="/oauth-callback-facebook" element={<OauthCallBack platform="ig_graph" />} />
              {/* Social reconnect email routes */}
              <Route path="/reconnect-instagram" element={<ReconnectLanding platform="instagram" />} />
              {/* <Route path="/reconnect-facebook" element={<ReconnectLanding platform="ig_graph"/>}/> */}
              <Route path="/reconnect-tiktok" element={<ReconnectLanding platform="tiktok" />} />
              <Route path="/reconnect-youtube" element={<ReconnectLanding platform="youtube" />} />
              <Route path="/reconnect-paypal" element={<ReconnectLanding platform="paypal" />} />
              <Route path="/reconnect-pinterest" element={<ReconnectLanding platform="pinterest" />} />
              {/* System pages */}
              <Route path="/search" element={<SearchResult />} />
              <Route path="/:creator_name" element={<CreatorHome />} />
              <Route path="/discovered_creator" element={<NotSignedInZelfCreatorHome />} />
              <Route path="/onboarded_creator" element={<NotSignedInZelfCreatorHome isZelfCreator />} />
              <Route path="*" element={<DetectDestination />} />
            </Routes>
          </Authenticated>
        </div>
        <Footer />
        {/* <MadeWithZelf/> */}
        {/* dialogs */}
        <GraphAPITour />
        <GraphAPITourPreDialog />
        <InstagramReconnectDialog />
        {/* to prevent left right arrow event from registering   */}
      </div>
      <DateSpecificContentPopup />
      {data?.ecosystem_selected_content?.content ? <ContentModal content={data?.ecosystem_selected_content} /> : null}
    </>
  )
}
