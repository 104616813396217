import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { Card, PlatformIcon, WrappedInLoader, ExpandButtonInline, Collapsible } from "../dumbs"
import { Input } from "../input"
import {
  EmailLarge,
  SearchMediumFilledMono,
  LinkLargeMono,
  PhoneLargeMono,
  DropdownLargeFilledMono,
  ThinTimesLargeMono,
  Lock,
  SearchSetting,
  ThinTimesMediumMono,
  Email,
  EmailPositiveBlue,
  NotInfluencer,
  NotInfluencerActive,
  DropdownRightLargeFilledMono,
  ReelsIcon,
  PostsIcon,
  MentionFilter,
  SmileyFaceMono,
  NeutralIcon,
  PokerFaceMono,
  AngryFaceMono,
  PostOnlyMention,
  BoardSaveLargeFilledMono,
  BoardSaveLargeOutLineMono,
  BoardCrossMono,
  IsNotInterestingMark,
  DropDown,
  PlusLargeFilledMono,
  SearchSettingsMedium,
  AICommentLarge,
  NoAiCommentLarge,
  VerticalDivider,
  SmallInfluencerMono,
  SmallInfluencerColor,
  SmallNotInfluencerActive,
} from "../Icons"
import { Select } from "../select"
import { Button } from "../button"
import { IfGuest, IfNotGuest } from "../functional/auth_components"
import { _empty, _nopropagate, convertToNumber, moveIdsToFront } from "../../helpers/utils"
import IS_NOT_INFLUENCER_PNG from "../../assets/is_influencer.png"
import { useCampaignPopupHelper } from "./BrandEcosystem/campaignPopup"
import { BoardPopup } from "./BrandEcosystem/boardPopup"
import { useBrandConfig, useSearchParamsHelper, useSetSubBrandID } from "../../hooks/helperHooks"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { POLITICAL_CANDIDATE_NAME_SERIAL } from "../../consts"

import TrumpImg from "../../assets/political/trump.png"
import BidenImg from "../../assets/political/biden.png"
import KamalaImg from "../../assets/political/kennedy.png"
import RFKImg from "../../assets/political/rfk.png"
import VanceImg from "../../assets/political/vance.png"
import WalzImg from "../../assets/political/Walz.png"
import { IconRadio } from "../radio"
import { CandidatesNps } from "../dialogs/creatorContentModal"

const _setBoolField = (fieldName, filters, setFilters) => {
  let _fltr = { ...filters }
  if (filters?.[fieldName]) {
    delete _fltr[fieldName]
    setFilters(_fltr)
    return
  }
  _fltr[fieldName] = true
  setFilters(_fltr)
  Boolean(_fltr?.[fieldName])
}

const _setNumberField = (fieldName, value, filters, setFilters) => {
  let _fltr = { ...filters }
  const number = convertToNumber(value)
  if (number || number === "0") {
    _fltr[fieldName] = convertToNumber(value)
  } else {
    delete _fltr[fieldName]
  }
  setFilters(_fltr)
}

const IconButton = ({ children, className = "", active, disabled, ...rest }) => {
  let _class = "line-shadow nc10-border nc50-fg"
  if (active) {
    _class = "line-shadow-light light-border primary-fg"
  }
  if (disabled) {
    _class = "action-disabled-faded line-shadow nc10-border nc50-fg"
  }
  return (
    <button
      className={`nc00-bg padding-x-3x max-height-44 min-height-44 ${_class}  border-1  border-radius-capsule flex a-center j-center  cursor-pointer ${className}`}
      {...rest}
    >
      {children}{" "}
    </button>
  )
}

const DropDownButton = ({ children, title = "Metrics", afterTitle = null, className = "", active, ...rest }) => {
  let _class = "line-shadow nc10-border nc100-fg"
  if (active) {
    _class = "line-shadow-light light-border primary-fg"
  }
  return (
    <button
      className={`border-radius-capsule max-height-44 min-height-44 nc00-bg border-1 padding-x-3x flex gap-x-2 a-center j-center button-medium-font ${_class} ${className}`}
      {...rest}
    >
      <span className="">{title}</span>
      {afterTitle}
      <div className="nc50-fg flex">{DropdownLargeFilledMono}</div>
    </button>
  )
}

const MetricsCompactInput = ({
  className = "",
  title = "",
  minValue = "",
  onMinChange,
  maxValue,
  onMaxChange,
  minPlaceholder = "minimum",
  maxPlaceHolder = "maximum",
}) => {
  return (
    <div className={className}>
      <div className="flex j-space-between a-center">
        <p className="primary-fg button-small-font margin-bottom-3x">{title}</p>
      </div>

      <div className="group-border-horizontal flex">
        {onMinChange ? (
          <div className="full group-border-horizontal-item padding-3x button-small-font">
            <input value={minValue} onChange={onMinChange} className="bear-input" placeholder={minPlaceholder} />
          </div>
        ) : null}
        {onMaxChange ? (
          <div className="full group-border-horizontal-item padding-3x button-small-font">
            <input value={maxValue} onChange={onMaxChange} className="bear-input" placeholder={maxPlaceHolder} />
          </div>
        ) : null}
      </div>
    </div>
  )
}

const MinMaxFields = ({ fieldsName = [], onValueChange, className, filters, title }) => {
  return (
    <MetricsCompactInput
      className={(className = className)}
      title={title}
      minValue={filters?.[fieldsName[0]] ?? ""}
      onMinChange={fieldsName[0] ? (e) => onValueChange(fieldsName[0], e.target.value) : null}
      maxValue={filters?.[fieldsName[1]] ?? ""}
      onMaxChange={fieldsName[1] ? (e) => onValueChange(fieldsName[1], e.target.value) : null}
    />
  )
}

const CreatorMetrics = ({ className = "", show, setShow, setNumberField, filters, onClose, clearAll, onDone }) => {
  return (
    <div className={`position-relative filter-metrics-dropdown-container ${className} margin-left-2x`}>
      <DropDownButton active={false} onClick={() => setShow(!show)} />
      {show ? (
        <Card className={`filter-metrics-dropdown padding-5x`}>
          <div className="flex j-space-between a-center margin-bottom-2x">
            <div className="headline-small-highlight primary-fg">Metrics</div>
            <div className="nc50-fg flex a-center">
              <div className="caption-highlight-font cursor-pointer" onClick={clearAll}>
                Clear all
              </div>
              <div onClick={onClose} className="flex margin-left-5x cursor-pointer">
                {ThinTimesLargeMono}
              </div>
            </div>
          </div>
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["followers__gte", "followers__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Number of followers"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["posts_for_brand__gte", "posts_for_brand__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Number of posts for my brand"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["views_for_brand__gte", "views_for_brand__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Number of views for my brand"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["engagement_for_brand__gte", "engagement_for_brand__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Total engagement for my brand"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["engagement_rate_for_brand__gte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Engagement rate for my brand"}
          />
          <MinMaxFields
            className={"margin-bottom-5x"}
            fieldsName={["impact__gte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Impact generated"}
          />
          <div className="flex j-end">
            <Button onClick={onDone}>Done</Button>
          </div>
        </Card>
      ) : null}
    </div>
  )
}

const ContentMetrics = ({ className = "", show, setShow, setNumberField, filters, onClose, clearAll, onDone }) => {
  return (
    <div className={`position-relative filter-metrics-dropdown-container ${className} margin-left-2x`}>
      <DropDownButton active={false} onClick={() => setShow(!show)} />
      {show ? (
        <Card className={`filter-metrics-dropdown padding-5x`}>
          <div className="flex j-space-between a-center margin-bottom-2x">
            <div className="headline-small-highlight primary-fg">Metrics</div>
            <div className="nc50-fg flex a-center">
              <div className="caption-highlight-font cursor-pointer" onClick={clearAll}>
                Clear all
              </div>
              <div onClick={onClose} className="flex margin-left-5x cursor-pointer">
                {ThinTimesLargeMono}
              </div>
            </div>
          </div>

          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["views__gte", "views__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Views"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["total_engagement__gte", "total_engagement__lte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Total engagements"}
          />
          <MinMaxFields
            className={"margin-bottom-2x"}
            fieldsName={["engagement_rate__gte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Engagement rate"}
          />
          <MinMaxFields
            className={"margin-bottom-5x"}
            fieldsName={["impact__gte"]}
            filters={filters}
            onValueChange={setNumberField}
            title={"Impact generated"}
          />
          <div className="flex j-end">
            <Button onClick={onDone}>Done</Button>
          </div>
        </Card>
      ) : null}
    </div>
  )
}

const NPS_MAP = [
  {
    title: "Happy",
    icon: SmileyFaceMono,
    value: "1",
    activeClass: "positive-fg",
  },
  {
    title: "Neutral",
    icon: PokerFaceMono,
    value: "0",
    activeClass: "attention-fg",
  },
  {
    title: "Unhappy",
    icon: AngryFaceMono,
    value: "-1",
    activeClass: "negative-fg",
  },
]
const Sentiment = ({
  className = "",
  show,
  setShow,
  setValue,
  value,
  onClose,
  clearAll,
  onDone,
  isPoliticalNPS = false,
  politicalNPS = {},
}) => {
  const activeValue = value

  const setNPS = (s) => {
    if (s === value) {
      setValue("")
      return
    }
    setValue(s)
  }
  const candidatesImg = useMemo(
    () => [
      {
        name: "Harris",
        img: KamalaImg,
        key: "kamala_nps",
      },
      {
        name: "Trump",
        img: TrumpImg,
        key: "trump_nps",
      },
      {
        name: "RFK",
        img: RFKImg,
        key: "rfk_nps",
      },
      {
        name: "Walz",
        img: WalzImg,
        key: "tim_nps",
      },
      {
        name: "Vance",
        img: VanceImg,
        key: "vance_nps",
      },
      {
        name: "Biden",
        img: BidenImg,
        key: "biden_nps",
      },
    ],
    [],
  )

  const reactions = useMemo(() => {
    return [
      { title: "Smiley", icon: SmileyFaceMono, value: 1, color: "positive-fg" },
      { title: "Poker", icon: PokerFaceMono, value: 0, color: "attention-fg" },
      { title: "Angry", icon: AngryFaceMono, value: -1, color: "negative-fg" },
    ]
  }, [])

  const handleEditInputChange = (key, value) => {
    // if value exists, set it to undefined, otherwise set it to value
    const nps = { ...politicalNPS }
    nps[key] = nps[key] !== undefined ? undefined : value
    setValue(nps)
  }

  return (
    <div className={`position-relative filter-metrics-dropdown-container ${className} margin-left-2x`}>
      <DropDownButton
        active={false}
        onClick={() => setShow(!show)}
        title="sentiment"
        afterTitle={
          isPoliticalNPS ? (
            <CandidatesNps
              className="center wrapped gap-1"
              candidates={{
                harris: politicalNPS?.kamala_nps ?? null,
                trump: politicalNPS?.trump_nps ?? null,
                rfk: politicalNPS?.rfk_nps ?? null,
                vance: politicalNPS?.vance_nps ?? null,
                walz: politicalNPS?.tim_nps ?? null,
                biden: politicalNPS?.biden_nps ?? null,
              }}
            />
          ) : null
        }
      />
      {show && (
        <>
          {isPoliticalNPS ? (
            <Card className={`filter-metrics-dropdown min-width-190-important candidate-nps`}>
              <div className="a-center flex j-space-between padding-x-4x padding-y-3x">
                <div className="headline-small-highlight primary-fg">Sentiment</div>
                <div className="nc50-fg flex a-center">
                  <div onClick={onClose} className="flex margin-left-5x cursor-pointer">
                    {ThinTimesLargeMono}
                  </div>
                </div>
              </div>
              {candidatesImg.map((c, i) => (
                <div key={i} className={`flex gap-4 vcenter padding-x-4x padding-y-2x border-top-1 nc10-border`}>
                  <img height={34} width={34} className="border-radius-round" src={c.img} alt={c.title} />
                  {reactions.map((reaction, j) => (
                    <IconRadio
                      key={j}
                      className={`cursor-pointer ${politicalNPS[c.key] == reaction.value ? reaction.color : "nc30-fg"}`}
                      icon={reaction.icon}
                      value={reaction.value}
                      selected={true}
                      justify="center"
                      onClick={(val) => handleEditInputChange(c.key, val)}
                    />
                  ))}
                </div>
              ))}
            </Card>
          ) : (
            <Card className={`filter-metrics-dropdown padding-5x`}>
              <div className="flex j-space-between a-center margin-bottom-2x">
                <div className="headline-small-highlight primary-fg">Sentiment</div>
                <div className="nc50-fg flex a-center">
                  <div onClick={onClose} className="flex margin-left-5x cursor-pointer">
                    {ThinTimesLargeMono}
                  </div>
                </div>
              </div>
              <div className="group-border-horizontal border-radius-8 flex a-center overflow-hidden">
                {NPS_MAP.map((_v) => (
                  <div
                    onClick={(e) => setNPS(_v.value)}
                    className={`full group-border-horizontal-item cursor-pointer flex a-center j-center  button-small-font padding-y-3x ${activeValue === _v.value ? `${_v.activeClass} nc00-bg` : "nc30-fg nc02-bg"}  `}
                  >
                    {_v.icon}
                    {_v.title}
                  </div>
                ))}
              </div>
            </Card>
          )}
        </>
      )}
    </div>
  )
}

const CreatorBoardFilterValues = {
  no_filter: {
    icon: <BoardCrossMono />,
    className: "",
    active: false,
    filter: {},
  },
  not_saved: {
    icon: <BoardCrossMono />,
    className: "primary-fg",
    active: true,
    filter: { is_saved: false },
  },
  saved: {
    icon: <div className="flex a-center j-center positive-fg">{BoardSaveLargeFilledMono}</div>,
    className: "primary-fg",
    active: true,
    filter: { is_saved: true },
  },
  not_interesting: {
    icon: <div className="flex a-center j-center nc30-fg">{IsNotInterestingMark}</div>,
    className: "nc30-fg",
    active: false,
    filter: { is_not_interesting: false },
  },
}

const CreatorFilter = ({
  onApply,
  filters,
  setFilters,
  sorts,
  sortAndTimeFrame,
  setSortAndTimeFrame,
  onBoardClick,
  showIsNewFilter = true,
}) => {
  const [show, setShow] = useState(false)
  const boardPopup = useCampaignPopupHelper()
  const [data, dispatch] = useContext(dataContext)

  const setField = (value, fieldName) => {
    let _fltr = { ...filters }
    _fltr[fieldName] = value
    setFilters(_fltr)
  }
  const setBoolField = (fieldName) => {
    _setBoolField(fieldName, filters, setFilters)
  }

  const setNumberField = (fieldName, value) => {
    _setNumberField(fieldName, value, filters, setFilters)
  }
  const _onApply = () => {
    onApply(filters)
    setShow(false)
  }

  const onSearchChange = (value) => {
    let _fltr = { ...filters }
    if (!value) {
      delete _fltr["search_text"]
    } else {
      _fltr["search_text"] = value
    }
    setFilters(_fltr)
  }

  const clearAll = () => {
    setShow(false)
    setFilters({})
  }
  const closeMetrics = () => setShow(false)

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      _onApply()
    }
  }

  const changeBoardFilter = () => {
    if (!filters["board_filter"]) {
      setField("not_saved", "board_filter")
      return
    }
    if (filters["board_filter"] === "not_saved") {
      setField("saved", "board_filter")
      return
    }
    if (filters["board_filter"] === "saved") {
      setField("not_interesting", "board_filter")
      return
    }
    if (filters["board_filter"] === "not_interesting") {
      setField(null, "board_filter")
      return
    }
  }

  const changeInfluencerFilter = () => {
    // 1 is influencer, 0 is not influencer, null is all
    if (filters["is_influencer"] == 1) {
      setField(0, "is_influencer")
      return
    } else if (filters["is_influencer"] == 0) {
      setField(null, "is_influencer")
      return
    } else {
      setField(1, "is_influencer")
      return
    }
  }

  const boardFilterValue = filters["board_filter"]
    ? CreatorBoardFilterValues[filters["board_filter"]]
    : CreatorBoardFilterValues["no_filter"]

  return (
    <div className="flex a-center full">
      <div className="margin-right-auto"></div>
      <Input
        onKeyDown={handleKeyDown}
        className=""
        value={filters?.["search_text"] ?? ""}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder={`Add keyword`}
        beforeIconElement={
          <div className="input-icon-group-wrapper  before-element nc50-fg margin-left-3x">
            {SearchMediumFilledMono}
          </div>
        }
        inputElementClass="line-shadow border-1 nc10-border-override button-small-font border-radius-capsule-important min-width-318 max-width-318 padding-left-9x-important max-height-44"
      />
      <IconButton
        className="margin-left-2x"
        active={Boolean(filters["has_email"])}
        onClick={() => setBoolField("has_email")}
      >
        {Boolean(filters["has_email"]) ? EmailPositiveBlue : EmailLarge}
      </IconButton>
      {/* <IconButton className="margin-left-2x" active={false} onClick={()=>setBoolField("is_saved")}>{ Boolean(filters["is_saved"]) ?   BoardSaveLargeFilledMono : <BoardCrossMono/> }</IconButton> */}
      <IconButton className="margin-left-2x" active={boardFilterValue?.active} onClick={changeBoardFilter}>
        {boardFilterValue?.icon}
      </IconButton>
      <IconButton className="margin-left-2x" active={filters["is_influencer"] != null} onClick={changeInfluencerFilter}>
        {filters["is_influencer"] == 1
          ? SmallInfluencerColor
          : filters["is_influencer"] == 0
            ? SmallNotInfluencerActive
            : SmallInfluencerMono}
      </IconButton>
      {showIsNewFilter ? (
        <IconButton
          className="margin-left-2x"
          active={Boolean(filters["is_new"])}
          onClick={() => setBoolField("is_new")}
        >
          new{" "}
        </IconButton>
      ) : null}
      {/* <IconButton className="margin-left-2x" active={Boolean(filters["is_tagged_post"])} onClick={()=>setBoolField("is_tagged_post")}>{ MentionFilter }</IconButton> */}

      {sorts ? (
        <>
          {" "}
          <Select
            customSelectText="Sort by"
            placeholder=""
            type="content"
            styleType="capsule"
            onChange={(e) =>
              setSortAndTimeFrame((s) => {
                return { ...s, sort_by: e.target.value }
              })
            }
            value={sortAndTimeFrame["sort_by"] ?? ""}
            className="sort-filter margin-left-2x "
          >
            {sorts?.map((_srt) => (
              <option key={_srt?.value} value={_srt?.value}>
                {_srt?.title}
              </option>
            ))}
          </Select>{" "}
        </>
      ) : null}
      <CreatorMetrics
        show={show}
        setShow={setShow}
        setNumberField={setNumberField}
        filters={filters}
        clearAll={clearAll}
        onClose={closeMetrics}
        onDone={closeMetrics}
      />
      <Button onClick={_onApply} type="ghost transparent-bg-override margin-left-2x">
        Apply
      </Button>

      <Button
        className="margin-left-auto no-flex-shrink z-index-100"
        type="tertiary"
        onClick={() => {
          _onApply()
          boardPopup.show()
        }}
      >
        Add to
        {boardPopup.shown ? (
          <BoardPopup
            hideNew
            className="left-bottom-mounted"
            type="normal"
            showContentBoard={false}
            hasDraft={true}
            onBoardClick={onBoardClick}
            onDismiss={() => {
              boardPopup.hide()
            }}
          />
        ) : null}
      </Button>
    </div>
  )
}

const AIFilterFilterValues = {
  no_ai_comment_filters: {
    icon: AICommentLarge,
    className: "",
    active: false,
    filter: {},
  },
  has_ai_generated_comment: {
    icon: AICommentLarge,
    className: "primary-fg",
    active: true,
    filter: { has_ai_generated_comment: true },
  },
  no_has_ai_generated_comment: {
    icon: <div className="flex a-center j-center nc30-fg">{NoAiCommentLarge}</div>,
    className: "nc30-fg",
    active: false,
    filter: { has_ai_generated_comment: false },
  },
}

const ContentFilter = ({
  onApply,
  filters,
  setFilters,
  sorts,
  sortAndTimeFrame,
  setSortAndTimeFrame,
  showPostOnlyFiler = false,
  showIsNewFilter = true,
  showNotInfluencer = true,
  showBarNps = false,
  showNpsFilter = true,
  isPoliticalNPS = false,
  preAppliedFilters = {},
  showAICommentFilter = false,
}) => {
  const [metricsShow, setMetricsShow] = useState(false)
  const [npsShow, setNpsShow] = useState(false)

  const { view_ai_generated_comment_only } = useBrandConfig()

  const setBoolField = (fieldName) => {
    _setBoolField(fieldName, filters, setFilters)
  }
  const setField = (value, fieldName) => {
    let _fltr = { ...filters }
    _fltr[fieldName] = value
    setFilters(_fltr)
  }

  const setNumberField = (fieldName, value) => {
    _setNumberField(fieldName, value, filters, setFilters)
  }
  const _onApply = () => {
    onApply(filters)
    setMetricsShow(false)
    setNpsShow(false)
  }

  const onSearchChange = (value) => {
    let _fltr = { ...filters }
    if (!value) {
      delete _fltr["search_text"]
    } else {
      _fltr["search_text"] = value
    }
    setFilters(_fltr)
  }

  const clearAll = () => {
    setMetricsShow(false)
    setFilters(preAppliedFilters)
  }
  const closeMetrics = () => setMetricsShow(false)

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      _onApply()
    }
  }

  const setNps = (v) => {
    const fltr = isPoliticalNPS ? v : { nps_score: v }

    if (isPoliticalNPS) {
      Object.keys(v).forEach((_n) => {
        if (v[_n] == 0 || v[_n] == 1 || v[_n] == -1) {
          fltr[_n] = v[_n]
          return
        }
        delete filters[_n]
        delete fltr[_n]
      })
    }
    setFilters({ ...filters, ...fltr })
  }

  const setNPSbyButton = (value) => {
    setNps(value)
  }

  const changeAIFilters = () => {
    // only comment
    if (!filters["ai_comment_filter"]) {
      setField("no_has_ai_generated_comment", "ai_comment_filter")
      return
    }

    if (filters["ai_comment_filter"] === "has_ai_generated_comment") {
      setField(null, "ai_comment_filter")
      return
    }
    if (filters["ai_comment_filter"] === "no_has_ai_generated_comment") {
      setField("has_ai_generated_comment", "ai_comment_filter")
      return
    }
  }

  let aiCommentFilter = filters["ai_comment_filter"]
    ? AIFilterFilterValues[filters["ai_comment_filter"]]
    : AIFilterFilterValues["no_ai_comment_filters"]

  useEffect(() => {
    if (view_ai_generated_comment_only) {
      setFilters({ ...filters, ai_comment_filter: "has_ai_generated_comment" })
    }
  }, [view_ai_generated_comment_only])

  useEffect(() => {
    if (!showBarNps) {
      return
    }
    _onApply()
  }, [filters.nps_score, showBarNps])

  return (
    <div className="flex a-center j-center">
      {showBarNps ? (
        <div className="flex margin-right-auto">
          <IconButton active={!filters.nps_score} className="margin-right-2x" onClick={() => setNPSbyButton("")}>
            All posts{" "}
          </IconButton>
          {NPS_MAP.map((x, i) => (
            <IconButton
              key={i}
              className="margin-right-2x"
              active={filters.nps_score === x.value}
              onClick={() => setNPSbyButton(x.value)}
            >
              {x.title}{" "}
            </IconButton>
          ))}
        </div>
      ) : null}

      <Input
        onKeyDown={handleKeyDown}
        className=""
        value={filters?.["search_text"] ?? ""}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder={`Add keyword`}
        beforeIconElement={
          <div className="input-icon-group-wrapper  before-element nc50-fg margin-left-3x">
            {SearchMediumFilledMono}
          </div>
        }
        inputElementClass="line-shadow border-1 nc10-border-override button-small-font border-radius-capsule-important min-width-318 max-width-318 padding-left-9x-important max-height-44"
      />
      {sorts ? (
        <>
          {" "}
          <Select
            customSelectText="Sort"
            placeholder=""
            type="content"
            styleType="capsule"
            onChange={(e) =>
              setSortAndTimeFrame((s) => {
                return { ...s, sort_by: e.target.value }
              })
            }
            value={sortAndTimeFrame["sort_by"] ?? ""}
            className="sort-filter margin-left-2x "
          >
            {sorts?.map((_srt) => (
              <option key={_srt?.value} value={_srt?.value}>
                {_srt?.title}
              </option>
            ))}
          </Select>{" "}
        </>
      ) : null}
      {showNotInfluencer ? (
        <IconButton
          className="margin-left-2x"
          active={Boolean(filters["is_not_influencer"])}
          onClick={() => setBoolField("is_not_influencer_content")}
        >
          {Boolean(filters["is_not_influencer_content"]) ? NotInfluencerActive : NotInfluencer}
        </IconButton>
      ) : null}
      {/* <IconButton className="margin-left-2x" active={Boolean(filters["is_tagged_post"])} onClick={()=>setBoolField("is_tagged_post")}>{ MentionFilter }</IconButton> */}
      {showPostOnlyFiler ? (
        <IconButton
          className="margin-left-2x"
          active={Boolean(filters["exc_comment_mentioned"])}
          onClick={() => setBoolField("exc_comment_mentioned")}
        >
          {PostOnlyMention}
        </IconButton>
      ) : null}
      {showIsNewFilter ? (
        <IconButton
          className="margin-left-2x"
          active={Boolean(filters["is_new"])}
          onClick={() => setBoolField("is_new")}
        >
          new{" "}
        </IconButton>
      ) : null}
      {showAICommentFilter ? (
        <IconButton className="margin-left-2x" active={aiCommentFilter?.active} onClick={changeAIFilters}>
          {aiCommentFilter?.icon}
        </IconButton>
      ) : null}
      <ContentMetrics
        show={metricsShow}
        setShow={(v) => {
          setMetricsShow(v)
          setNpsShow(false)
        }}
        setNumberField={setNumberField}
        filters={filters}
        clearAll={clearAll}
        onClose={closeMetrics}
        onDone={closeMetrics}
      />
      {showNpsFilter && (
        <Sentiment
          show={npsShow}
          setShow={(v) => {
            setMetricsShow(false)
            setNpsShow(v)
          }}
          onClose={() => setNpsShow(false)}
          value={filters?.nps_score}
          setValue={setNps}
          isPoliticalNPS={isPoliticalNPS}
          politicalNPS={{
            trump_nps: filters?.trump_nps,
            biden_nps: filters?.biden_nps,
            kamala_nps: filters?.kamala_nps,
            rfk_nps: filters?.rfk_nps,
            vance_nps: filters?.vance_nps,
            tim_nps: filters?.tim_nps,
          }}
        />
      )}
      <Button onClick={_onApply} type="ghost transparent-bg-override margin-left-2x">
        Apply
      </Button>
    </div>
  )
}

const DiscoveryPlatformFilter = ({ className = "", skipYoutube = false }) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const changePlatform = (_p) => dispatch({ discovery_section_platform: _p })
  const changeContentType = (_p) => dispatch({ discovery_section_content_form: _p })
  const { searchParams } = useSearchParamsHelper()
  const enableInstagram = searchParams.get("force_instagram_connect")
  return (
    <div className={`flex j-end a-center ${className}`}>
      <Button
        onClick={() => changePlatform("tiktok")}
        className={`capsule-button round-button flex vcenter margin-right-2x ${data?.discovery_section_platform === "tiktok" ? "selected" : ""}`}
        type="tertiary"
      >
        <div className="flex a-center j-center">
          <PlatformIcon color={data?.discovery_section_platform === "tiktok"} platform="tiktok" />
        </div>
      </Button>
      <IfNotGuest>
        {enableInstagram ? <Button onClick={() => changePlatform("instagram")}
          className={`capsule-button round-button flex vcenter margin-right-2x ${data?.discovery_section_platform === "instagram" ? "selected" : ""}`} type="tertiary"
        >
          <div className="flex a-center j-center">
            <PlatformIcon color={data?.discovery_section_platform === "instagram"} platform="instagram" />
          </div>
        </Button> : null}
        {/* {data?.discovery_section_platform === "instagram" ? (
          <>
            <div className="nc50-fg">{DropdownRightLargeFilledMono}</div>

            <Button
              onClick={() => changeContentType("VIDEO")}
              className="capsule-button flex vcenter margin-right-2x"
              type="tertiary"
              beforeElement={
                <div
                  className={`${data?.discovery_section_content_form === "VIDEO" ? "primary-fg" : ""} flex a-center j-center margin-right`}
                >
                  {ReelsIcon}
                </div>
              }
            >
              <span className={`${data?.discovery_section_content_form === "VIDEO" ? "primary-fg" : ""} `}>Reels</span>
            </Button>

            <Button
              onClick={() => changeContentType("IMAGE")}
              className="capsule-button flex vcenter margin-right-2x"
              type="tertiary"
              beforeElement={
                <div
                  className={`${data?.discovery_section_content_form === "IMAGE" ? "primary-fg" : ""} flex a-center j-center margin-right`}
                >
                  {PostsIcon}
                </div>
              }
            >
              <span className={`${data?.discovery_section_content_form === "IMAGE" ? "primary-fg" : ""}`}>Posts</span>
            </Button>
          </>
        ) : null} */}
        {skipYoutube || data?.me?.brand?.settings?.is_political_dashboard_enabled ? null : (
          <Button
            onClick={() => changePlatform("youtube")}
            className={`capsule-button round-button flex vcenter margin-right-2x ${data?.discovery_section_platform === "youtube" ? "selected" : ""}`}
            type="tertiary"
          >
            <div className="flex a-center j-center">
              <PlatformIcon color={data?.discovery_section_platform === "youtube"} platform="youtube" />
            </div>
          </Button>
        )}
      </IfNotGuest>
      <IfGuest>
        {/* <Button
        onClick={api?.alert?.unlockFullExperience}
        className="capsule-button flex vcenter margin-right-2x" type="tertiary">
            <span className="nc50-fg flex vcenter margin-right icon-equal-text">
            <span className="margin-right icon-equal-text flex vcenter">{Lock}</span>
            <PlatformIcon platform="instagram"/>
            </span>
            <span>Instagram</span>
        </Button> */}
        <Button onClick={api?.alert?.unlockFullExperience} className="capsule-button flex vcenter" type="tertiary">
          <span className="nc50-fg flex vcenter margin-right icon-equal-text">
            <span className="margin-right icon-equal-text flex vcenter">{Lock}</span>
            <PlatformIcon platform="youtube" />
          </span>
          <span>YouTube</span>
        </Button>
      </IfGuest>
    </div>
  )
}

const CompetitorModeSelector = ({ value, selectedValue, title = "", className = "", onClick }) => {
  const isSelected = value === selectedValue
  const { searchParams, replaceQueryParams, removeQueryParams } = useSearchParamsHelper()
  const best_worst_selector = searchParams.get("best_worst_selector")

  const onBestWorstClick = (value) => {
    if (!value) {
      removeQueryParams(["best_worst_selector", "be_section_view_all"])
      return
    }

    replaceQueryParams({ best_worst_selector: value, competitorMode: selectedValue })

  }

  return <>
    <Button
      className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${isSelected ? "selected" : ""} ${className}`}
      onClick={() => onClick(value)}
      type="tertiary"
      size="small"
      afterElement={<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.89648 1.19141L5.14648 4.41602C5.29883 4.59375 5.375 4.79688 5.375 5C5.375 5.22852 5.29883 5.43164 5.14648 5.58398L1.89648 8.80859C1.66797 9.0625 1.3125 9.13867 1.00781 9.01172C0.703125 8.88477 0.5 8.58008 0.5 8.25L0.5 1.77539C0.5 1.44531 0.703125 1.16602 1.00781 1.03906C1.3125 0.912109 1.66797 0.962891 1.89648 1.19141Z" fill="#ACAFB9" />
      </svg>
      }
    >
      {title}

    </Button>
    {
      isSelected ? <>
        <Button
          className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${!best_worst_selector ? "selected" : ""} ${className}`}
          onClick={() => onBestWorstClick("")}
          type="tertiary"
          size="small"
        >
          {"ALL"}
        </Button>
        <Button
          className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${best_worst_selector === "best-five" ? "selected" : ""} ${className}`}
          onClick={() => onBestWorstClick("best-five")}
          type="tertiary"
          size="small"
        >
          {"Top Five"}
        </Button>
        <Button
          className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${best_worst_selector === "worst-five" ? "selected" : ""} ${className}`}
          onClick={() => onBestWorstClick("worst-five")}
          type="tertiary"
          size="small"
        >
          {"Bottom Five"}
        </Button>

      </> : null
    }
  </>
}

const SubBrandFilterTabs = ({
  className = "",
  onBrandTypeClick = () => { },
  onBrandClick = () => { },
  showAllCompetitors = false,
  showTrending = false,
}) => {
  const [selected, setSelected] = useState("")
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const _subBrands = data?.me?.brand?.sub_brands ?? []
  const { setSubBrandId } = useSetSubBrandID()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { is_political_engage_kamala_harris_subbrand } = useBrandConfig()

  const isPolitical = data?.me?.brand?.settings?.is_political_dashboard_enabled
  const isPoliticalEngage = data?.me?.brand?.settings?.is_political_engage_enabled
  let competitors = _subBrands?.filter((x) => x?.sub_brand_type === 1) ?? []
  let themes = _subBrands?.filter((x) => x?.sub_brand_type === 2) ?? []
  let others = _subBrands?.filter((x) => x?.sub_brand_type === 3) ?? []
  competitors = isPolitical ? moveIdsToFront(competitors, POLITICAL_CANDIDATE_NAME_SERIAL, (x) => x.title) : competitors

  const competitorMode = searchParams.get("competitorMode")

  const onBrandSettingsClick = (e) => {
    _nopropagate(e)

    if (selected === "others") {
      dispatch({
        show_priority_post_settings: true,
      })
      return
    }
    dispatch({
      show_self_serve_brand_settings: true,
    })
  }

  const onMainBrandClick = () => {
    onBrandTypeClick("")
    setSelected("")
    setSubBrandId(null, true)
    if (showTrending) {
      navigate("/discover-content")
    }
  }
  const onCompetitorModeClick = (mode) => {
    setSubBrandId(null, true)
    navigate(`/discover-content?competitorMode=${mode}`)
  }
  const onCompetitorClick = () => {
    onBrandTypeClick("competitor")
    setSelected("competitor")
    if (showAllCompetitors) {
      setSubBrandId(null, true)
      onCompetitorModeClick("owned")
    } else {
      setSubBrandId(competitors?.[0]?.id ?? null)
      if (showTrending) {
        navigate("/discover-content?sub_brand_id=" + competitors?.[0]?.id)
      }
    }
  }
  const onThemesClick = () => {
    onBrandTypeClick("themes")
    setSelected("themes")
    setSubBrandId(_subBrands?.filter((x) => x?.sub_brand_type === 2)?.[0]?.id ?? null, false, [
      "competitorMode",
      "be_section_view_all",
    ])
    if (showTrending) {
      navigate("/discover-content?sub_brand_id=" + themes?.[0]?.id)
    }
  }
  const onOtherClick = () => {
    onBrandTypeClick("others")
    setSelected("others")
    setSubBrandId(others?.[0]?.id ?? null)
  }

  const onTrendingClick = () => {
    setSubBrandId(null, true)
    onBrandTypeClick("trending")
    setSelected("trending")
    const trendingCategories = data?.me?.god_view_brands ?? []
    navigate("/discover-content/trending?category_id=" + trendingCategories[0]?.category_id)
  }

  const onSubBrandClick = (sub_brand) => {
    onBrandClick(sub_brand)
    const removeParamsArray = ["competitorMode"]
    if (competitorMode) {
      removeParamsArray.push("be_section_view_all")
    }
    setSubBrandId(sub_brand?.id, false, removeParamsArray)
  }

  const SearchSettingsElement = (
    <span
      className="cursor-pointer nc50-fg primary-fg-onhover padding-left flex a-center"
      onClick={onBrandSettingsClick}
    >
      {SearchSettingsMedium}
    </span>
  )

  useEffect(() => {
    if (searchParams.get("sub_brand_id")) {
      const _subBrand = _subBrands.find((x) => x.id == searchParams.get("sub_brand_id"))
      if (_subBrand) {
        setSelected(
          _subBrand?.sub_brand_type === 1 ? "competitor" : _subBrand?.sub_brand_type === 2 ? "themes" : "others",
        )
        setSubBrandId(_subBrand?.id)
      }
    } else if (isPoliticalEngage) {
      setSelected("competitor")
      setSubBrandId(competitors?.[0]?.id ?? null)
    } else if (searchParams.get("competitorMode")) {
      setSelected("competitor")
    } else if (pathname === "/discover-content/trending") {
      setSelected("trending")
    }
  }, [_subBrands])

  useEffect(() => {
    if (data?.sub_brand_id) return
    if (selected) return

    if (!isPolitical) {
      return
    }

    onCompetitorClick()
  }, [data?.sub_brand_id])

  return (
    <div className={className}>
      <div className="flex j-space-between a-center">
        <div className="flex">
          {!isPolitical && !isPoliticalEngage && (
            <Button
              className={`capsule-button flex vcenter margin-right-2x ${selected === "" ? "selected" : ""}`}
              type="tertiary"
              size="small"
              onClick={onMainBrandClick}
            >
              {data?.me?.brand?.title}
              {(!isPoliticalEngage || data?.me?.user_group == 1) && (
                <>{selected === "" ? SearchSettingsElement : null}</>
              )}
            </Button>
          )}
          <Button
            className={`capsule-button flex vcenter margin-right-2x ${selected === "competitor" ? "selected" : ""}`}
            type="tertiary"
            size="small"
            onClick={onCompetitorClick}
          >
            <span className="margin-right">{isPolitical || isPoliticalEngage ? "Candidates" : "Competitors"}</span>
            {DropDown}
          </Button>
          {!isPoliticalEngage && (
            <Button
              className={`capsule-button flex vcenter margin-right-2x ${selected === "themes" ? "selected" : ""}`}
              type="tertiary"
              size="small"
              onClick={onThemesClick}
            >
              <span className="margin-right">{isPolitical || isPoliticalEngage ? "Issues" : "Themes"}</span>
              {DropDown}
            </Button>
          )}

          {isPolitical || isPoliticalEngage ? (
            <Button
              className={`capsule-button flex vcenter margin-right-2x ${selected === "others" ? "selected" : ""}`}
              type="tertiary"
              size="small"
              onClick={onOtherClick}
            >
              <span className="margin-right">{isPolitical ? "Kamala Surrogates" : "Priority Posts"}</span>
              {DropDown}
            </Button>
          ) : null}

          {showTrending && data?.me?.brand?.settings?.is_god_view_enabled && (
            <Button
              className={`capsule-button flex vcenter margin-right-2x ${selected === "trending" ? "selected" : ""}`}
              type="tertiary"
              size="small"
              onClick={onTrendingClick}
            >
              <span className="margin-right">Trending now</span>
              {DropDown}
            </Button>
          )}
        </div>
        {(!isPoliticalEngage || data?.me?.user_group == 1) && (
          <div>
            {(data?.me?.user_group != null || data?.me?.brand?.settings?.is_sub_brand_creation_enabled) && (
              <button
                onClick={api?.alert?.createNewSpace}
                className=" nc00-bg max-height-44 min-height-44 min-width-44 max-width-44 border-radius-round flex a-center j-center primary-fg border-1 nc10-border line-shadow cursor-pointer"
              >
                {PlusLargeFilledMono}
              </button>
            )}
          </div>
        )}
      </div>

      {selected === "competitor" && (
        <div className="flex wrapped padding-y-2x">
          {!!showAllCompetitors && (
            <>
              <CompetitorModeSelector title="All owned" value={"owned"} selectedValue={competitorMode} onClick={onCompetitorModeClick} />
              <div className="flex a-center j-center margin-right-2x margin-bottom">{VerticalDivider}</div>
              <CompetitorModeSelector title="All Influencers" value={"influencer"} selectedValue={competitorMode} onClick={onCompetitorModeClick} />
              <div className="flex a-center j-center margin-right-2x margin-bottom">{VerticalDivider}</div>
              <CompetitorModeSelector title="All Earned" value={"earned"} selectedValue={competitorMode} onClick={onCompetitorModeClick} />
              <div className="flex a-center j-center margin-right-2x margin-bottom">{VerticalDivider}</div>
            </>
          )}

          {competitors?.map((subBrand, i) => (
            <Button
              key={i}
              className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${data?.sub_brand_id == String(subBrand?.id) ? "selected" : ""} `}
              onClick={() => onSubBrandClick(subBrand)}
              type="tertiary"
              size="small"
            >
              {subBrand.title}
              {(!isPoliticalEngage ||
                (isPoliticalEngage &&
                  !(is_political_engage_kamala_harris_subbrand === true) &&
                  data?.me?.user_group === 1)) && (
                  <>{String(data?.sub_brand_id) === String(subBrand?.id) ? SearchSettingsElement : null}</>
                )}
            </Button>
          ))}
        </div>
      )}
      {selected === "themes" && (
        <div className="flex wrapped  padding-y-2x">
          {themes?.map((subBrand, i) => (
            <Button
              key={i}
              className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${data?.sub_brand_id == String(subBrand?.id) ? "selected" : ""} `}
              onClick={() => onSubBrandClick(subBrand)}
              type="tertiary"
              size="small"
            >
              {subBrand.title}
              {!isPoliticalEngage && (
                <>{String(data?.sub_brand_id) === String(subBrand?.id) ? SearchSettingsElement : null}</>
              )}
            </Button>
          ))}
        </div>
      )}

      {selected === "others" && (
        <div className="flex wrapped padding-y-2x">
          {others?.map((subBrand, i) => (
            <Button
              key={i}
              className={`capsule-button flex vcenter margin-right-2x margin-bottom-2x ${data?.sub_brand_id == String(subBrand?.id) ? "selected" : ""} `}
              onClick={() => onSubBrandClick(subBrand)}
              type="tertiary"
              size="small"
            >
              {subBrand.title}
              {(!isPoliticalEngage || data?.me?.user_group == 1) && (
                <>{String(data?.sub_brand_id) === String(subBrand?.id) ? SearchSettingsElement : null}</>
              )}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

export {
  CreatorFilter,
  ContentFilter,
  DiscoveryPlatformFilter,
  CreatorBoardFilterValues,
  SubBrandFilterTabs,
  AIFilterFilterValues,
}
