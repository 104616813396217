import { Modal } from "./modal";
import { Button } from "../button";
import { useEffect, useRef, useState } from "react";
import { Steps } from "../dumbs";
import QRCODEPLACEHOLDER from "../../assets/qr_code_placeholder.svg"
import { Input, OtpInput } from "../input";
import { useLocalDataMutation } from "../../hooks/useBoundData";
import { MUTATION_CALLS } from "../../consts";
import { Loader } from "../loaders";
import { useToast } from "../../hooks/useToast";
import { BanSolid } from "../Icons";
import { useServer } from "../../hooks/useServer";

let steps = [
    <span className="body-regular-font"> <span className="body-highlight-font"> Open TikTok </span>on your smartphone and log into your account.</span>,
    <span className="body-regular-font"> <span className="body-highlight-font"> Scan the QR  </span>Code using your phone and tap the link that pops up.</span>,
]
const QRCodeLogin = () => {
    const [isActive, setIsActive] = useState(false); // Initially false
    const [timeLeft, setTimeLeft] = useState(55); // Timer starts at 55 seconds
    const [isSuccess, setIsSuccess] = useState(false)
    const [pullingEndpointUrl, setPullEndpointUrl] = useState(null)
    const { } = useLocalDataMutation({ endpoint: "", skip: true })
    const server = useServer()
    const { executeCall: qrCodeConnect, payload, loader: genQrLoading } = useLocalDataMutation(MUTATION_CALLS.connect_new_social)


    const onGenerateQrCode = async () => {
        // setIsActive(true)
        const res = await qrCodeConnect({
            platform: "tiktok",
            "authorization_type": "qr",
            code: "whatever"
        })
        if (res) {
            console.log(res)
            setIsActive(true)
            setPullEndpointUrl(`/Social/platform/${res?.id}/`)
        }

    }



    useEffect(() => {
        let timerInterval;
        if (isActive) {
            setTimeLeft(55);

            timerInterval = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev > 1) {
                        const new_time = prev - 1;
                        return new_time
                    } else {
                        setIsActive(false);
                        clearInterval(timerInterval);
                        return 0;
                    }
                });
            }, 1000);
        }

        // Cleanup on component unmount or `isActive` changes
        return () => clearInterval(timerInterval);
    }, [isActive]);

    useEffect(() => {
        if (!isActive) return
        if (!timeLeft) return
        if (!pullingEndpointUrl) return
        if (timeLeft % 5 !== 0) return

        (async () => {
            const res = await server.get(pullingEndpointUrl)
            if (res?.is_healthy) {
                setIsSuccess(true)

            }

        })()
    }, [timeLeft, pullingEndpointUrl])

    if (isSuccess) {
        return <div className="margin-x-4x margin-y-4x  flex">
            Connection Successful
        </div>
    }

    return <div className="margin-x-4x margin-y-4x  flex">
        <div className="full padding-3x">
            <p className="body-regular-font margin-bottom-3x">To connect you TikTok using QR Code, follow the following steps:</p>
            <div className="">
                {steps.map((text, i) => (
                    <Steps key={i} className="margin-bottom-4x" number={i + 1} text={text} />
                ))}
            </div>
        </div>
        <div className="full flex a-center j-center">
            {isActive ? <div>
                {payload?.authorization_details?.qr_code ? <img style={{ width: 200 }} src={payload?.authorization_details?.qr_code ?? QRCODEPLACEHOLDER} /> : null}
                <br />
                {isActive && <span>Time left: {timeLeft} seconds</span>}
            </div> : <div className="position-relative">
                <img style={{ filter: "blur(2px)" }} src={QRCODEPLACEHOLDER} width={200} />
                <Button type="tertiary" onClick={onGenerateQrCode} disabled={genQrLoading} className="position-absolute old-center-trick" style={{ minWidth: "max-content" }}> Show QRCode {genQrLoading ? <Loader></Loader> : null}</Button>
            </div>}

        </div>

    </div>
}

const PasswordLogin = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [errorText, setErrorText] = useState("")
    const { executeCall: sendToBE, payload: socialConnectPayload, loader: loaderPasswordLogin } = useLocalDataMutation(MUTATION_CALLS.connect_new_social)
    const { executeCall: multifactorAuthentication, loader: loaderMultiFactor } = useLocalDataMutation(MUTATION_CALLS.multi_factor_auth)
    const [isSuccess, setIsSuccess] = useState(false)
    const [showMultiFactor, setShowMultiFactor] = useState(false)
    const [connectID, setConnectID] = useState()
    const [OPTinput, setOTPInput] = useState("")
    const server = useServer()
    const toast = useToast()
    const attemptRef = useRef(0)

    const onConnectClick = async () => {
        attemptRef.current = 0
        if (!username || !password) {
            setErrorText("Username or password can't be empty")
            return

        }

        const res = await sendToBE({
            "platform": "tiktok",
            "username": username,
            "password": password,
            "authorization_type": "username_password"

        })
        if (res) {
            setErrorText("")
            // setIsSuccess(true)
            setConnectID(res?.id)
            if (res?.auth_state === "TWO_FA_WAITING") {

                setShowMultiFactor(true)
                return
            }
            if (res?.auth_state === "SUCCESS") {
                setIsSuccess(true)
            }

        } else {
            setConnectID("")
        }

    }

    const sendMultifactor = async () => {
        setErrorText("")
        if (!OPTinput) {
            setErrorText("Code can't be empty")
            return
        }
        if (!connectID) {
            setShowMultiFactor(false)
            return
        }
        const res = await multifactorAuthentication(
            {
                "social_platform_id": connectID,
                "multi_factor_code": OtpInput
            }
        )
        if (!res) {
            setErrorText("Something went wrong ...")
            return
        }

        if (res?.auth_state === "SUCCESS") {
            setIsSuccess(true)
        }
    }

    useEffect(() => {
        if (!connectID) {
            return
        }
        if (showMultiFactor) {
            return
        }
        if (isSuccess) {
            return
        }
        let interval
        const pull = async (resource) => {
            if (attemptRef.current >= 60) {
                setConnectID("")
                attemptRef.current = 0
                return
            }
            if (showMultiFactor) return
            const res = await server.get(resource)

            if (res?.auth_state !== "TWO_FA_WAITING" || res?.auth_state !== "SUCCESS") {
                attemptRef.current = attemptRef.current + 1
                console.log(`pulling ${resource} ${attemptRef.current}`)
                return
            }
            if (res?.auth_state === "TWO_FA_WAITING") {
                attemptRef.current = 0
                setShowMultiFactor(true)
                // setConnectID("")
            }
            if (res?.auth_state === "SUCCESS") {
                setIsSuccess(true)
            }
        }

        if (connectID) {
            interval = setInterval(() => {
                pull(`/Social/platform/${connectID}/`)
            }, 2000)
        } else {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [connectID, showMultiFactor, isSuccess])

    if (isSuccess) {
        return <div className="margin-x-4x margin-y-4x  flex">
            Connection Successful
        </div>
    }

    return <div className="padding-y-10x padding-x-15x">
        {showMultiFactor ? <>
            <div>

                <div className="max-width-290 margin-x-auto">
                    <p className="txt-center button-medium-font nc30-fg margin-bottom-5x">
                        A verification code has been sent to
                        <br />your [email/phone]
                    </p>
                    <p className="button-medium-font margin-bottom-2x"> Enter Verification Code</p>
                    <OtpInput onChange={(v) => setOTPInput(v)} />
                    {!!errorText && <p className="caption margin-bottom-3x negative-fg">{errorText}</p>}
                    <Button onClick={sendMultifactor} fluid className="margin-top-5x" disabled={loaderMultiFactor}>Submit Code  {loaderMultiFactor ? <Loader></Loader> : null}</Button>

                </div>
            </div>
        </> :
            <>
                <h5 className="button-medium-font nc100-fg margin-bottom-2x">Email or Username</h5>
                <Input value={username} onChange={(e) => setUsername(e.target.value)} className="margin-bottom-3x" placeholder={'Enter your email address'} />
                <h5 className="button-medium-font nc100-fg margin-bottom-2x">Password</h5>
                <Input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="margin-bottom-3x" placeholder={'Enter your Password'} />
                {!!errorText && <p className="caption margin-bottom-3x negative-fg">{errorText}</p>}
                <Button onClick={onConnectClick} disabled={loaderPasswordLogin} fluid >Connect {loaderPasswordLogin || !!connectID ? <Loader></Loader> : null}</Button>
            </>}
    </div>
}

const TikTokConnectWithZelf = ({ triggerElement }) => {
    const [isQRTabSelected, setIsQRTabSelected] = useState(true)
    return <Modal>
        <Modal.Button asChild>
            {triggerElement}
            {/* <Button >Connect Tiktok via Zelf</Button> */}
        </Modal.Button>
        <Modal.Content overlayClassName="backdrop-blur-4" title="Connect Tiktok With Zelf" contentBodyWidthClassName="dialog-max-width-690">
            <div className="">
                <div className="flex">
                    <div className={`txt-center cursor-pointer full padding-y-3x headline-small-highlight border-bottom-2 ${isQRTabSelected ? "primary-border" : "transparent-border"} `} onClick={() => setIsQRTabSelected(true)}>QR Code</div>
                    <div className={`txt-center cursor-pointer full padding-y-3x headline-small-highlight border-bottom-2 ${!isQRTabSelected ? "primary-border" : "transparent-border"}`} onClick={() => setIsQRTabSelected(false)}>Login Credential</div>
                </div>
                <hr className="hr nc10-border"></hr>

                {isQRTabSelected ? <QRCodeLogin /> : <PasswordLogin />}
                <hr className="hr nc10-border"></hr>
                <div className="flex padding-y-3x padding-x-5x">
                    <Modal.Close asChild>
                        <Button className="secondary margin-left-auto">
                            Dismiss
                        </Button>
                    </Modal.Close>
                </div>
            </div>
        </Modal.Content>
    </Modal>
}


export {
    TikTokConnectWithZelf
}