
import {
  Lock,
  LogoutExtraLarge,
  PlaneExtraLarge,
  TiktokExtraLarge,
  CheckExtraLarge,
  YoutubeLargeFilledMono,
} from "../Icons"
import { Button } from "../button"
import { Unauthenticated, Authenticated, IfBrand, IfNotGuest, IfGuest } from "../functional/auth_components"
import { useContext, useState, useEffect } from "react"
import { apiContext } from "../../contexts/apicontext"
import { useLocation, useNavigate } from "react-router-dom"
import { dataContext } from "../../contexts/datacontext"
import zlogo from "../../assets/zelflogo.png"
import { useSocial } from "../../hooks/useSocial"
import { useAuthHelper } from "../../hooks/useAuthHelper"
import { connectTikapi } from "../../helpers/utils"
import { TikTokConnectWithZelf } from "../dialogs/allModalBasedDialogs"
import { useBrandConfig, useSearchParamsHelper } from "../../hooks/helperHooks"

// Supplimentary components
const Item = ({ children, onClick }) => (
  <div className="header-menu-item button-large-font margin-x-3x cursor-pointer" onClick={onClick}>
    {children}
  </div>
)

const DropDownItem = ({ className = "", disabled = false, icon = null, title, ...rest }) => {
  return (
    <div
      disabled={disabled}
      className={`${disabled ? "nc30-fg " : "nc75-fg"}  padding-y-2x padding-left-3x padding-right-11x flex a-center ${className}`}
      {...rest}
    >
      {icon ? (
        <span className={`flex a-center margin-right-2x ${disabled ? "nc30-fg" : "nc50-fg"}`}>{icon}</span>
      ) : null}
      {title}
    </div>
  )
}
function Header() {
  const { user } = useAuthHelper()
  const api = useContext(apiContext)
  const [data, dispatch] = useContext(dataContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [menuOn, setMenuOn] = useState(false)
  const [searchmode, setSearchmode] = useState(false)
  const { connect } = useSocial()
  const { is_youtube_connect_enabled, is_zelf_tiktok_connect_enabled } = useBrandConfig()
  const { searchParams } = useSearchParamsHelper()
  const force_youtube_connect = searchParams.get("force_youtube_connect")

  const isTikApiConnected = data?.me?.is_user_tikapi_connected || data?.me?.brand?.is_tikapi_connected
  const isPoliticalEnabled = data?.me?.brand?.settings?.is_political_dashboard_enabled
  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  useEffect(() => {
    if (window.TikAPI && data?.me?.brand) {
      window.TikAPI.onLogin((data) => {
        console.log("sending sesssion id to the backend ")
        api.brand.addTikapiSession(data)
        api?.alert?.dismiss()
      })
    }
  }, [window.TikAPI, data?.me?.brand])

  const openUpTikapiPopup = () => {
    if (isTikApiConnected) {
      api?.alert?.connectTikAPI()
      return
    }

    connectTikapi()
  }

  const connectGraph = () => {
    connect("ig_graph")
  }

  const updateSearchText = (search_text_raw) => {
    dispatch({ search_text_raw })
  }

  const search = (e, direct = false) => {
    if (e.key === "Enter" || direct) {
      navigate(`/search?q=${data.search_text_raw}`)
      dispatch({ search_query: data.search_text_raw })
    }
  }

  const onJoinClick = () => {
    if (window.location.href.match("/search"))
      api.analytics.action(
        "search",
        `signup_click_from_${data?.search_tab === "book" ? "content" : "creator"}_search`,
        data?.search_tab,
      )
    return navigate("/")
  }

  const onSignInClick = () => {
    if (window.location.href.match("/search"))
      api.analytics.action(
        "search",
        `login_click_from_${data?.search_tab === "book" ? "content" : "creator"}_search`,
        data?.search_tab,
      )
    api.login(false)
  }

  const logoClick = () => {
    if (searchmode) return setSearchmode(false)
    if (data.role) return navigate("/")
    else window.location.href = "https://hellozelf.com"
  }

  return (
    <div className={`nc00-bg nc05-border border-bottom-1 sticky-header`}>
      <div className="cs-header flex vcenter padding-y-2x content-area">
        {/* <div onClick={()=>setMenuOn(true)} className="header-hamburger hide-on-desktop cursor-pointer margin-x-3x">
                    {HamburgerMenu}
                </div> */}
        <div className="logo cursor-pointer" onClick={logoClick}>
          {/* {ZelfLogo} */}
          <img className="header-logo" src={zlogo} alt="zelf" />
        </div>
        {/* <Input value={data.search_text_raw} onSecondIconClick={(e)=>search(e, true)} onChange={e=>updateSearchText(e.target.value)} onKeyUp={search} className={`margin-left-4x cs-header-search ${searchmode? "":"hide-on-mobile"}`} placeholder="Search photo, video, creator etc." secondIconElement={SearchMediumFilledMono} secondIconClasses="cursor-pointer"/> */}
        <div className="full"></div>
        <Unauthenticated>
          {/* <Button type="ghost" onClick={()=>navigate("/find-content")} size="small" className="margin-right-2x nc100-fg-override">Find content</Button> */}
          <Button onClick={onSignInClick} size="small" type="ghost" className="margin-left-3x">
            Sign In
          </Button>
          <Button onClick={onJoinClick} size="small" className="margin-left">
            Join
          </Button>
        </Unauthenticated>
        <Authenticated>
          {/* <div className="heade-auth-user-section header-notification-icon margin-right-3x">
                    {NotificationLargeFilledMono}
                    </div> */}

          <IfBrand showLoader={false}>
            {(!isPoliticalEngageEnabled || data?.me?.user_group == 1) && (
              <>
                <Button
                  type="ghost"
                  onClick={() => navigate("/brand-dashboard", "_self")}
                  size="small"
                  className="margin-right-2x nc100-fg-override"
                >
                  <span className={pathname.startsWith("/brand-dashboard") ? "primary-fg" : ""}>
                    Reporting & Analytics
                  </span>
                </Button>
                <Button
                  type="ghost"
                  onClick={() => navigate("/discover-content", "_self")}
                  size="small"
                  className="margin-right-2x nc100-fg-override"
                >
                  <span className={pathname.startsWith("/discover-content") ? "primary-fg" : ""}>Discover Content</span>
                </Button>
              </>
            )}
            {!isPoliticalEnabled && (
              <>
                <Button
                  type="ghost"
                  onClick={() => navigate("/engage", "_self")}
                  size="small"
                  className="margin-right-2x nc100-fg-override"
                >
                  <span className={pathname.startsWith("/engage") ? "primary-fg" : ""}>Engage with Content</span>
                </Button>
              </>
            )}

            {(!isPoliticalEngageEnabled || data?.me?.user_group == 1) && (
              <Button
                type="ghost"
                onClick={() => navigate("/discover-creator", "_self")}
                size="small"
                className="margin-right-2x nc100-fg-override"
              >
                <span className={pathname.startsWith("/discover-creator") ? "primary-fg" : ""}>Creator Outreach</span>
              </Button>
            )}
          </IfBrand>
          <div
            className={`heade-auth-user-section header-user-avatar-section cursor-pointer flex vcenter ${searchmode ? "hide-on-mobile" : ""}`}
          >
            <img src={data?.me?.profile_picture_url || user?.picture} className="header-user-avatar" alt={user?.name} />
            <div className="user-avater-drop-down-section body-regular-font border-radius-4 line-shadow border-1 nc10-border nc00-bg ">
              <DropDownItem title={"Logout"} icon={LogoutExtraLarge} onClick={api?.logout} />
              {data?.me?.brand ? (
                <>
                  <IfNotGuest>
                    {!isPoliticalEngageEnabled && (
                      <DropDownItem
                        onClick={() => api?.alert?.copyBrandInviteToCreatorLink()}
                        icon={PlaneExtraLarge}
                        title={"Invite creators"}
                      />
                    )}
                    <hr className="hr" />
                    {/* <DropDownItem onClick={() => {}} icon={TiktokExtraLarge} title={"Config"} /> */}

                    <DropDownItem
                      onClick={() => openUpTikapiPopup()}
                      disabled={isTikApiConnected}
                      icon={isTikApiConnected ? CheckExtraLarge : TiktokExtraLarge}
                      title={isTikApiConnected ? "Tiktok connected" : "Connect Tiktok"}
                    />

                    {/* {!isPoliticalEngageEnabled && (
                      <DropDownItem
                        onClick={() => connectGraph()}
                        disabled={data?.me?.is_ig_graph_connected}
                        icon={
                          data?.me?.is_ig_graph_connected ? (
                            CheckExtraLarge
                          ) : (
                            <div className="flex icon-container-xl a-center j-center">{InstagramLargeFilledMono} </div>
                          )
                        }
                        title={data?.me?.is_ig_graph_connected ? "Instagram connected" : "Connect Instagram"}
                      />
                    )} */}
                    {!!(force_youtube_connect || is_zelf_tiktok_connect_enabled || false) && <TikTokConnectWithZelf triggerElement={
                      <div>
                        <DropDownItem
                          icon={false ? CheckExtraLarge : TiktokExtraLarge}
                          title={false ? "Tiktok connected" : "Connect Tiktok via Zelf  "}

                        />
                      </div>
                    } />}

                    {!is_youtube_connect_enabled && !force_youtube_connect ? null : (
                      <DropDownItem
                        onClick={() => connect("youtube")}
                        disabled={false}
                        icon={
                          data?.me?.brand?.is_youtube_connected ? (
                            CheckExtraLarge
                          ) : (
                            <div className="flex icon-container-xl a-center j-center">{YoutubeLargeFilledMono} </div>
                          )
                        }
                        title={data?.me?.brand?.is_youtube_connected ? "Youtube connected" : "Youtube connect"}
                      ></DropDownItem>
                    )}
                  </IfNotGuest>
                  <IfGuest>
                    <DropDownItem
                      onClick={api?.alert?.unlockFullExperience}
                      icon={Lock}
                      title="Invite creators"
                    ></DropDownItem>
                    <DropDownItem
                      onClick={api?.alert?.unlockFullExperience}
                      icon={Lock}
                      title="Connect Tiktok"
                    ></DropDownItem>
                    <DropDownItem
                      onClick={api?.alert?.unlockFullExperience}
                      icon={Lock}
                      title="Connect Instagram"
                    ></DropDownItem>
                  </IfGuest>
                </>
              ) : null}
            </div>
          </div>
        </Authenticated>
        <div className="mobile-cover-holder hide-on-desktop">
          {menuOn ? (
            <div onClick={() => setMenuOn(false)} className="mobile-menu-cover flex hcenter vcenter vertical">
              <div className="mobile-menu-item margin-y-5x nc00-fg cursor-pointer headline-large">Explore</div>
              <Authenticated>
                <div className="mobile-menu-item margin-y-5x nc00-fg cursor-pointer headline-large">Profile</div>
                <div className="mobile-menu-item margin-y-5x nc00-fg cursor-pointer headline-large">Settings</div>
              </Authenticated>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export { Header }
