import React, { useContext, useEffect, useRef, useState } from "react"
import {
  BEBoardList,
  BELocalContentList,
  ContentOverviewSection,
  BECreatorList,
  CreatorSelectList,
} from "./beSections"
import { dataContext } from "../../../contexts/datacontext"
import { useBDBoundData, useBDPostData, useLocalBoundData } from "../../../hooks/useBoundData"
import { Card, WrappedInLoader, ExpandButtonInline, Collapsible, CommonContentStats, DotSeparator, ExpandableText } from "../../dumbs"
import { getBrandInviteUrl, replaceTemplateVariables, scrollToTop, validateEmail } from "../../../helpers/utils"
import {
  BanSolid,
  ChevronBackwardSmallOutlinedMono,
  ChevronDownMediumFilledMono,
  ChevronFrowardMediumOutlinedMono,
  PenMediumFilledMono,
} from "./../../Icons"
import { apiContext } from "../../../contexts/apicontext"
import { Button } from "../../button"
import { Toggle, ToggleWithTitle } from "../../toggle"
import { Input } from "../../input"
import { Select } from "../../select"
import { useToast } from "../../../hooks/useToast"
import { API_BASE, BRAND_FE_CONFIG } from "../../../consts"
import { useBrandConfig } from "../../../hooks/helperHooks"
import EditorJS from "@editorjs/editorjs"
import Header from "@editorjs/header"
import List from "@editorjs/list"
import { Loader } from "../../loaders"
import ImageTool from "@editorjs/image"
import { useNavigate, useParams } from "react-router-dom"
import { GET_CALLS } from "../../../consts"

const creator_select_state_name = "selected_board_creator_email_creator_select"
const default_set_variable_name = creator_select_state_name + "_default_select"

const SelectCreators = ({ payload, setCreators, onNext }) => {
  const [data, dispatch] = useContext(dataContext)
  return (
    <div className="margin-y-4x">
      <p className="margin-y-4x">Select</p>
      <div className="border-1 border-radius-4 overflow-hidden nc10-border ">
        <CreatorSelectList
          dataContextCustomSection={creator_select_state_name}
          selectAllDefault={true}
          className="top-bottom-fade  max-height-816 overflow-auto"
          keepOldState={true}
          showFollowers={true}
          previousSelectedState={payload?.creators?.map((x) => x.id)}
          filterFunc={(x) => x?.email}
          section={"selected_board_creator_email"}
          isNotExclusion
          onChange={(_crs) => setCreators(_crs)}
        />
      </div>
      <div className="flex j-end margin-top-4x">
        <Button onClick={onNext}>Next</Button>
      </div>
      <p className="margin-y-4x">Couldn’t find email address for:</p>
      <div className="border-1 border-radius-4 overflow-hidden nc10-border ">
        <CreatorSelectList
          showFollowers={true}
          className="top-bottom-fade  max-height-816 overflow-auto"
          dataContextCustomSection="dumb_selection_state"
          skipCheckbox
          filterFunc={(x) => !x?.email}
          section={"selected_board_creator_no_email"}
          isNotExclusion
          onChange={(c) => console.log(c)}
        />
      </div>
    </div>
  )
}

const SenderInfo = ({ payload, setPayload, onNext }) => {
  return (
    <div className="flex j-center a-center full ">
      <div className="min-width-400 max-width-400 button-medium-font padding-y-4x">
        <p className="padding-y-2x">Name</p>
        <Input
          value={payload?.reply_to_name ?? ""}
          placeholder={`John doe`}
          onChange={(e) => setPayload("reply_to_name", e.target.value)}
        />
        <p className="padding-y-2x">Email</p>
        <Input
          value={payload?.reply_to_email ?? ""}
          placeholder={`example@gamil.com`}
          onChange={(e) => setPayload("reply_to_email", e.target.value)}
        />

        <div className="flex j-end margin-top-4x">
          <Button onClick={onNext}>Next</Button>
        </div>
      </div>
    </div>
  )
}

const Subject = ({ payload, setPayload, onNext }) => {
  return (
    <div className="flex j-center a-center full ">
      <div className="min-width-400 max-width-400 button-medium-font padding-y-4x">
        <p className="padding-y-2x">Subject</p>
        <Input
          value={payload?.subject ?? ""}
          placeholder={`subject`}
          onChange={(e) => setPayload("subject", e.target.value)}
        />
        <p className="padding-y-2x">Preview text</p>
        <Input
          value={payload?.preview ?? ""}
          placeholder={`Preview Text`}
          onChange={(e) => setPayload("preview", e.target.value)}
        />

        <div className="flex j-end margin-top-4x">
          <Button onClick={onNext}>Next</Button>
        </div>
      </div>
    </div>
  )
}

const convertJsonToHtml = (jsonData) => {
  let htmlContent = ""

  jsonData?.blocks?.forEach((block) => {
    switch (block.type) {
      case "header":
        htmlContent += `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
        break
      case "paragraph":
        htmlContent += `<p>${block.data.text}</p>`
        break
      case "list":
        htmlContent += `<ul>${block.data.items.map((item) => `<li>${item}</li>`).join("")}</ul>`
        break
      case "linkTool":
        htmlContent += `<a href="${block.data.link}">${block.data.link}</a>`
        break
      case "image":
        // Handling for the image block
        const imageUrl = block.data.file.url
        const imageAlt = block.data.caption || ""
        htmlContent += `<div class="editorjs-image"><img src="${imageUrl}" alt="${imageAlt}"/></div>`
        break
      default:
        console.warn(`Unsupported block type: ${block.type}`)
    }
  })

  if (htmlContent) return "<div>" + htmlContent + "</div>"

  return htmlContent
}

class CustomImageTool extends ImageTool {
  // Override the renderSettings method to remove the settings panel
  renderSettings() {
    return null
  }
}

const Design = ({ payload, setPayload, editorData, onNext, handleEditorChange }) => {
  const template_id = payload?.template_id
  const { payload: templates, loader } = useBDBoundData({
    section: "email_templates",
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const { loader: template_create_loader, executeCall: createTemplate } = useBDPostData({
    section: "create_brand_email_template",
  })
  const { loader: template_update_loader, executeCall: updateTemplate } = useBDPostData({
    section: "update_brand_email_template",
    uniqueSectionPostfix: template_id,
  })
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const editorInstance = useRef(null)
  const [subject, setSubject] = useState("")
  const toast = useToast()
  const [isCaretInside, setIsCaretInside] = useState(false)
  const editorContainerRef = useRef(null)

  const checkFocus = (e) => {
    if (editorContainerRef?.current?.contains(e.target)) {
      setIsCaretInside(true)
    } else {
      setIsCaretInside(false)
    }
  }

  const saveTemplate = async () => {
    if (!subject) {
      toast("subject can't be empty", BanSolid)
      return
    }
    const html_content = convertJsonToHtml(editorData)

    if (!editorData || !html_content) {
      toast("Template can not be empty", BanSolid)
      return
    }

    const _payload = {
      name: subject,
      subject: subject,
      html_content: html_content,
      json_content: editorData,
      brand: data?.me?.brand?.id,
    }

    const res = template_id ? await updateTemplate(_payload) : await createTemplate(_payload)
    if (res) {
      setPayload("template_id", res.id)
      setPayload("template", res)
    }
    return res
  }

  const _onNext = async () => {
    const res = await saveTemplate()
    if (res) {
      onNext()
    }
  }

  const handleBackspace = (event) => {
    if (event.key === "Backspace") {
      const selection = window.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        if (range.startOffset === 0 && range.endOffset === 0) {
          const previousNode = range.startContainer.previousSibling
          if (previousNode && previousNode?.classList?.contains("readonly-text")) {
            event.preventDefault()
            previousNode.remove()
          }
        }
      }
    }
  }

  const initEditor = () => {
    editorInstance.current = new EditorJS({
      holder: "editorjs",
      autofocus: true,
      onChange: async () => {
        const a = await editorInstance?.current?.save()
        handleEditorChange(a)
      },
      data: editorData,
      placeholder: "Write your email here",
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
            preserveBlank: true,
          },
        },
        paragraph: {
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        image: {
          class: ImageTool,
          config: {
            caption: false,
            uploader: {
              uploadByFile: (file) => {
                if (file.size >= 1048576) {
                  toast("The image cannot be larger than 1MB.", BanSolid)
                  return
                }
                if (!["image/png", "image/jpeg"].includes(file.type)) {
                  toast("Only PNG and JPG files are allowed.", BanSolid)
                  return
                }
                return api.common.uploadImage(file)
              },
            },
          },
        },
      },
    })
  }

  const updateEditorFromJson = (jsonData) => {
    const editor = editorInstance?.current
    if (editor) {
      editor.isReady.then(() => {
        editor
          .render(jsonData)
          .then(() => {
            console.log("Editor content updated from JSON")
          })
          .catch((error) => {
            console.error("Error updating editor content from JSON:", error)
          })
      })
    }
  }

  const onChange = (e) => {
    const _template_id = Number(e.target.value)
    setPayload("template_id", _template_id)
    const selected_template = templates.find((x) => x?.id === _template_id)
    if (selected_template) {
      setPayload("template", selected_template)
      setSubject(selected_template?.subject)
      if (selected_template?.json_content) {
        updateEditorFromJson(selected_template?.json_content)
        handleEditorChange(selected_template?.json_content)
      }
    }
  }

  useEffect(() => {
    // if(effectRan.current) return
    if (!editorInstance.current) {
      console.log("init the editor ")
      initEditor()
      document.addEventListener("keydown", handleBackspace)
      document.addEventListener("click", checkFocus)
      document.addEventListener("focusin", checkFocus)
      document.addEventListener("focusout", checkFocus)
    }

    return () => {
      if (editorInstance.current) {
        document.addEventListener("keydown", handleBackspace)
        document.addEventListener("click", checkFocus)
        document.addEventListener("focusin", checkFocus)
        document.addEventListener("focusout", checkFocus)
        // effectRan.current = true
        editorInstance.current.destroy && editorInstance.current.destroy()
        editorInstance.current = null
      }
    }
  }, [])

  const insertReadOnlyText = (text) => {
    const selection = window.getSelection()
    if (!selection.rangeCount) return

    const range = selection.getRangeAt(0)
    range.deleteContents()

    const span = document.createElement("span")
    span.textContent = text
    span.contentEditable = false
    span.classList.add("readonly-text")

    range.insertNode(span)

    // Move the cursor to the end of the inserted text
    range.setStartAfter(span)
    range.setEndAfter(span)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  return (
    <div className="flex j-center a-center full ">
      <div className="full button-medium-font padding-y-4x">
        <WrappedInLoader data={templates} loader={loader}>
          <div className="flex a-center j-space-between">
            <h4 className="headline-medium-highlight full">Design Your Email</h4>

            <div className="full no-flex-shrink max-width-215">
              <Select
                selectTextClass="primary-fg"
                dropdownIcon={<div className="flex a-center j-center primary-fg">{ChevronDownMediumFilledMono}</div>}
                customSelectText="Load from templates"
                noBorder={true}
                presentationalBitClass="primary-fg-important"
                className="primary-fg-important"
                value={String(payload?.template_id)}
                onChange={onChange}
              >
                <option value={""}>Select a template</option>
                {templates?.map((template) => (
                  <option onClick={() => setPayload("template", template)} value={String(template.id)}>
                    {template?.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
        </WrappedInLoader>
        <p className="margin-bottom-2x margin-top-4x">Subject line</p>
        <Input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="margin-bottom-4x"
          placeholder={"Subject"}
        />
        <p className="margin-bottom-2x margin-bottom">Email body</p>
        <div ref={editorContainerRef}>
          <Card className={`padding-5x`}>
            <div id="editorjs"></div>
          </Card>
          <div className="flex">
            <Button
              type="ghost"
              disabled={!isCaretInside}
              size="small"
              onClick={() => insertReadOnlyText("{{ creator_name }}")}
            >
              {" "}
              Insert creator name
            </Button>
          </div>
        </div>
        <div className="flex a-center j-space-between margin-top-4x">
          {template_create_loader || template_update_loader ? (
            <Loader />
          ) : (
            <Button type="tertiary" onClick={saveTemplate}>
              Save as template
            </Button>
          )}
          {template_create_loader || template_update_loader ? <Loader /> : <Button onClick={_onNext}>Next</Button>}
        </div>
      </div>
    </div>
  )
}


const PreviewAndSendEmail = ({ payload, onNext }) => {
  const [data, dispatch] = useContext(dataContext)

  return (
    <div className="padding-top-2x">
      <div className="margin-y-2x body-regular-font">We will dispatch this email to the following creators:</div>
      <div className="flex margin-y-2x">
        {/* <span className='button-medium-font margin-right-2x'>To: </span>  */}
        <div className="flex wrapped">
          {payload?.creators?.map((c, i) => (
            <span key={i} className={`chip no-flex-shrink margin-right-2x margin-bottom-2x`}>
              <span className="caption-regular-font">
                {c?.name ?? c?.username} : {c?.email}
              </span>
            </span>
          ))}
        </div>
      </div>

      <div className="flex margin-y-4x">
        <span className="button-medium-font margin-right-2x">From : </span>
        <div className="flex wrapped button-medium-font">
          {payload?.reply_to_name} : {payload?.reply_to_email}
        </div>
      </div>
      <div className="flex margin-y-4x">
        <span className="button-medium-font margin-right-2x">Subject : </span>
        <div className="flex wrapped button-medium-font">{payload?.template?.subject}</div>
      </div>
      <div className="wrap-text-inner-pre body-regular-font nc02-bg padding-5x margin-y-3x border-radius-4">
        <div
          className="browser-defaults"
          dangerouslySetInnerHTML={{
            __html: replaceTemplateVariables(payload?.template?.html_content, {
              creator_name: "John doe",
              link: getBrandInviteUrl(data?.me?.brand?.ref_code),
            }),
          }}
        ></div>
      </div>

      <div>
        Click the “Send Email Now” to send your email instantly to your selected creators. You cannot undo this feature.
      </div>
      <div className="flex j-end margin-top-4x">
        <Button onClick={onNext}>Send now</Button>
      </div>
    </div>
  )
}

const sectionNames = [
  {
    title: "To",
    subTitle: "Select recipient",
  },
  {
    title: "From",
    subTitle: "Select sender.",
  },
  // {
  //   title:"Subject",
  //   subTitle:"Add a subject line for this campaign."
  // },
  {
    title: "Design",
    subTitle: "Create your email content.",
    skipSubtile: true,
  },
  // {
  //   title:"Preview & Test",
  //   subTitle:"See how your email looks"
  // },
  {
    title: "Send",
    subTitle: "Preview and send email",
  },
]

const CRMContainer = ({ className = "", setShowCRM }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const api = useContext(apiContext)
  const { executeCall, loader } = useBDPostData({ section: "send_email" })
  const [data, dispatch] = useContext(dataContext)
  const [crmPayload, setCRMPayload] = useState({
    creators: [],
    reply_to_email: "",
    reply_to_name: "",
  })
  const toast = useToast()
  const [editorData, setEditorData] = useState({})

  const handleEditorChange = (data) => {
    setEditorData(data)
  }

  console.log(crmPayload)

  const setPayload = (field, value) => {
    setCRMPayload((_s) => {
      const state = { ..._s }
      state[field] = value
      return state
    })
  }

  const _onNext = (_selectedTab) => {
    if (!crmPayload.creators?.length) {
      toast("Please select creators")
      setSelectedTab(0)
      return
    }
    if (_selectedTab > 1) {
      if (!crmPayload?.reply_to_email || !crmPayload.reply_to_name) {
        toast("Please fill out from info")
        return
      }
      if (!validateEmail(crmPayload.reply_to_email)) {
        toast("Please enter a valid email")
        return
      }
    }
    if (_selectedTab > 2) {
      if (!crmPayload?.template_id) {
        toast("Please select a template")
        return
      }
    }

    setSelectedTab(_selectedTab)
  }

  const onNext = () => _onNext(selectedTab + 1)

  const convertPayloadForSending = (payload) => {
    const _payload = {
      template_id: Number(payload.template_id),
      reply_to: payload?.reply_to_email,
      reply_to_name: payload?.reply_to_name,
    }

    const link = getBrandInviteUrl(data?.me?.brand?.ref_code)

    const _email_data = [
      ...payload.creators?.map((c) => ({
        account: c?.id,
        board: data?.selected_board_for_viewing?.id,
        email: c?.email,
        data: {
          creator_name: c?.name ?? c?.username,
          link: link,
        },
      })),
    ]

    _payload.email_data = _email_data
    return _payload
  }

  const onSend = async () => {
    const res = await executeCall(convertPayloadForSending(crmPayload))
    if (res) {
      api?.alert?.EmailSent()
      dispatch({
        [creator_select_state_name]: [],
        [default_set_variable_name]: false,
      })
    }

    setShowCRM(false)
  }

  const componentMap = {
    0: <SelectCreators payload={crmPayload} onNext={onNext} setCreators={(_cre) => setPayload("creators", _cre)} />,
    1: <SenderInfo onNext={onNext} setPayload={setPayload} payload={crmPayload} />,
    //  2: <Subject onNext={onNext} setPayload={setPayload} payload={crmPayload} />,
    2: (
      <Design
        onNext={() => setSelectedTab((tab) => tab + 1)}
        editorData={editorData}
        setPayload={setPayload}
        payload={crmPayload}
        handleEditorChange={handleEditorChange}
      />
    ),
    3: <PreviewAndSendEmail onNext={onSend} setPayload={setPayload} payload={crmPayload} />,
  }

  useEffect(() => {
    api.brand.hardRefreshDashboardSections({
      sectionsArray: ["selected_board_creator_email", "selected_board_creator_no_email"],
    })
    dispatch({
      [creator_select_state_name]: [],
      [default_set_variable_name]: false,
    })
  }, [])

  return (
    <Card className={`flex overflow-hidden ${className}`}>
      <div className="name-tag-section-titles min-width-318 border-right-1 nc10-border">
        {sectionNames.map((x, i) => (
          <div key={i} onClick={() => _onNext(i)} className={`flex border-bottom-1 nc10-border cursor-pointer`}>
            <div className={`min-width-3 ${i === selectedTab ? "primary-bg" : ""} `}></div>
            <div className="padding-x-5x  padding-y-4x full">
              <h4 className="headline-medium-highlight">{x.title}</h4>
              <p className="body-regular-font margin-top nc50-fg">{x.subTitle}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="full padding-x-7x padding-y-5x">
        {/* <input type='datetime-local' className='padding-3x body-regular-font'/> */}
        <div className="flex column">
          {sectionNames[selectedTab]?.skipSubtile ? null : (
            <h4 className="headline-medium-highlight">{sectionNames[selectedTab]?.subTitle}</h4>
          )}
          {componentMap[selectedTab]}
        </div>
      </div>
    </Card>
  )
}

const Boards = ({
  type = "normal",
  filter = {},
  category,
  pageTitle,
  enableHashtagsInAddForm = true,
  enableCreatorsInAddForm = false,
  hideCreateBoard = false,
  section
}) => {
  const [data, dispatch] = useContext(dataContext)

  useEffect(() => {
    dispatch({
      board_category: category,
    })
  }, [category])
  return (
    <div className="margin-y-6x">
      <BEBoardList
        pageTitle={pageTitle}
        type={type}
        section={section}
        filter={filter}
        hideCreateBoard={hideCreateBoard}
        enableCreatorsInAddForm={enableCreatorsInAddForm}
        enableHashtagsInAddForm={enableHashtagsInAddForm}
      />
    </div>
  )
}

const transformStats = (payload) => {
  const obj = {
    total_comments_public: payload?.total_comments,
    total_creators_public: payload?.total_creators,
    total_posts_public: payload?.total_posts,
    total_views_public: payload?.total_views,
    total_engagement_public: payload?.total_engagement,
  }
  if (payload?.total_views && payload?.total_engagement) {
    obj.engagement_rate_public = payload?.total_engagement / payload?.total_views
  }
  return obj
}

const AIAnalytics = ({ boardDetails }) => {
  return boardDetails?.analytics_type === 1 ? <div className="margin-y-5x ">
    <h3 className="headline-medium-highlight margin-bottom-4x margin-top-6x">
      AI reports
    </h3>
    {!boardDetails?.board_analysis?.length ? <div className="body-regular-font nc50-fg">
      This might take a few minutes. We'll notify you once it's ready.
    </div> : null}
    <div className="board-ai-analytics-container gap-2">
      {boardDetails?.board_analysis?.sort((a, b) => { return a?.analysis_type?.length - b?.analysis_type?.length })?.map((aiAnalytic, i) => <div key={i} className="">
        <Card>
          <div className="primary-fg headline-medium-highlight margin-5x">{aiAnalytic?.analysis_type === "VIDEO" ? "Content" : "Comment"}</div>
          <hr className="hr" />
          <div className="margin-5x">
            <h5 className="headline-small-highlight margin-bottom-2x">
              Summary
            </h5>
            <p className="body-regular-font nc75-fg">
              {/* {aiAnalytic?.analysis_json?.summary} */}
              <ExpandableText
                text={aiAnalytic?.analysis_json?.summary}
                lines={3}
                showMoreText="Read more"
                showMoreClasses={"font-medium margin-left-auto padding-2"}
                showLessClasses={"font-medium"} />

            </p>
          </div>
          <hr className="hr" />
          <div className="margin-5x">
            <h5 className="headline-small-highlight margin-bottom-2x">
              Topic
            </h5>
            <div className="body-regular-font nc75-fg">
              <div className="margin-y-3x">

                <p className="margin-bottom-2x">Here are top five trends discussed in the videos: </p>
                <ul>
                  {aiAnalytic?.analysis_json?.topics?.trends?.map((x, i) => <li key={i} className="margin-bottom nc75-fg flex a-center"> <DotSeparator className="margin-x-2x" /> {x}</li>)}
                </ul>
              </div>
              {!!aiAnalytic?.analysis_json?.topics?.positive?.length && <div className="margin-y-3x">
                <p className="margin-bottom-2x">Here are top five positive things discussed in the videos: </p>
                <ul>
                  {aiAnalytic?.analysis_json?.topics?.positive?.map((x, i) => <li key={i} className="margin-bottom nc75-fg flex a-center"> <DotSeparator className="margin-x-2x" /> {x}</li>)}
                </ul>
              </div>}
              {!!aiAnalytic?.analysis_json?.topics?.negative?.length && <div className="margin-y-3x">
                <p className="margin-bottom-2x">Here are top five Negative things discussed in the videos: </p>
                <ul>
                  {aiAnalytic?.analysis_json?.topics?.negative?.map((x, i) => <li key={i} className="margin-bottom nc75-fg flex a-center"> <DotSeparator className="margin-x-2x" /> {x}</li>)}
                </ul>
              </div>}
            </div>
          </div>
          <hr className="hr" />
          <div className="margin-5x">
            <h5 className="headline-small-highlight margin-bottom-2x">
              Actions
            </h5>
            <div className="body-regular-font nc75-fg">
              {aiAnalytic?.analysis_json?.actions?.map((action, i) => <div key={i} className="margin-y-2x">
                <p className="margin-bottom nc100-fg"> &gt;  {action?.action}</p>
                <small className="caption">Reason : {action?.reason}</small>
              </div>)}

            </div>
          </div>
          <hr className="hr" />
          <div className="margin-5x">
            <h5 className="headline-small-highlight margin-bottom-2x">
              Sentiments
            </h5>
            <p className="body-regular-font nc75-fg">

              {Object.keys(aiAnalytic?.analysis_json?.sentiment ?? {})?.sort().reverse().map((senKey, i) => {
                if (senKey === "sentiment_summary") {
                  return <div key={i} className="margin-y-2x">
                    <p className="margin-bottom">Summary</p>
                    <ExpandableText
                      text={aiAnalytic?.analysis_json?.sentiment?.[senKey]}
                      lines={3}
                      showMoreText="Read more"
                      showMoreClasses={"font-medium margin-left-auto padding-2"}
                      showLessClasses={"font-medium"} />
                  </div>
                }

                return <div key={i}>{senKey} : {aiAnalytic?.analysis_json?.sentiment?.[senKey]}</div>
              })}

            </p>
          </div>
          <hr className="hr" />

        </Card>

      </div>)}
    </div>


  </div> : null
}

const BoardDetails = ({
  hideCreator = false,
  isEmailCreatorsEnable = false,
  enableHashtagsInEditForm = true,
  enableCreatorsInEditForm = false,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const [detailOpen, sDO] = useState(false)
  let { board_id } = useParams()
  const { loader, payload: boardDetails, refetch } = useLocalBoundData({
    ...GET_CALLS.selected_board_details,
    endpoint: `/Board/${board_id}`,
    // skip: !data?.selected_board_for_viewing?.id
  })
  const [showCreator, setShowCreator] = useState(false)
  const api = useContext(apiContext)
  const [showCRM, setShowCRM] = useState(false)
  const { is_email_enabled } = useBrandConfig()
  const toast = useToast()
  const navigate = useNavigate()
  const [deleteCreators, setDeletedCreators] = useState({})

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (!board_id) {
      navigate("/")
    }
    let _id = Number(board_id)

    if (data?.selected_board_for_viewing?.id === _id) return
    dispatch({
      selected_board_for_viewing: {
        id: _id,
      },
    })
  }, [board_id])
  const need_details_refresh = data?.["need_refresh_selected_board_content"]
  useEffect(() => {
    if (need_details_refresh) {

      refetch()
    }
  }, [need_details_refresh])

  const onBack = () => {
    if (showCRM) {
      setShowCRM(false)
      return
    }
    const currentPath = window.location.pathname;
    const newPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
    dispatch({
      selected_board_for_viewing: null,
    })
    navigate(newPath)

  }

  const removeCreators = async (creator) => {
    const res = await api.brand.brandPostRequestHelper({
      section: "remove_creators_from_board",
      payload: { board: data?.selected_board_for_viewing?.id, creators: [creator.id] },
    })

    if (res) {
      setDeletedCreators((state) => {
        return {
          ...state,
          [creator?.id]: true,
        }
      })
    }
    toast(`${creator?.name ?? creator?.username} was removed from this board`)

    // api.brand.hardRefreshDashboardSections({ sectionsArray: ["selected_board_added_creator"] })
  }

  if (data?.selected_board_for_viewing?.id != board_id) {
    return <Loader />
  }

  return (
    <div className="">
      <WrappedInLoader loader={loader} data={boardDetails}>
        <div className="board-title-section headline-large flex a-center j-space-between margin-top-6x">
          <div onClick={onBack} className="flex a-center cursor-pointer nc75-fg">
            <div className=" campaign-detail-back-button margin-right-2x nc50-fg">
              {ChevronBackwardSmallOutlinedMono}
            </div>
            {data?.selected_board_for_viewing?.name ?? boardDetails?.name}{" "}
            {showCRM ? (
              <span className="headline-small-highlight margin-left-2x nc75-fg"> / contract creators</span>
            ) : null}
          </div>
          {showCRM ? null : (
            <div className="flex a-center j-center">
              {!hideCreator && (
                <ToggleWithTitle
                  firstTitle="Posts"
                  lastTitle="Creators"
                  value={showCreator}
                  onClick={() => setShowCreator((x) => !x)}
                ></ToggleWithTitle>
              )}
              <Button
                className="transparent-bg-override"
                type="ghost"
                size="large"
                onClick={() => {
                  console.log("hello")
                  api?.alert?.createOrEditBoard({
                    board: boardDetails,
                    enableCreatorsSelect: enableCreatorsInEditForm,
                    enableHashtagsInput: enableHashtagsInEditForm,
                  })
                }
                }
                beforeElement={PenMediumFilledMono}
              >
                Edit board
              </Button>
            </div>
          )}
        </div>

        {showCRM && boardDetails?.id ? (
          <CRMContainer setShowCRM={setShowCRM} key={boardDetails.id} className="margin-y-6x" />
        ) : (
          <Card className="margin-y-5x ">
            <div className="flex j-space-between a-center">
              <div className="margin-x-5x padding-top-4x padding-bottom-7x ">
                <div className="stat-headline margin-bottom-5x margin-top-4x">
                  <span className="headline-medium-highlight primary-fg margin-right">{"Board summary"}</span>
                  <span className="button-small-font nc30-fg">{"(does not include data for YouTube content)"}</span>
                </div>
                <CommonContentStats
                  showEngagementRateByFollower={false}
                  postfixOfDataName="_public"
                  data={transformStats(boardDetails)}
                  globalSeparator={false}
                  skipCreatorPublic={false}
                />
              </div>

              {is_email_enabled && isEmailCreatorsEnable ? (
                <Button
                  type="ghost"
                  onClick={() => setShowCRM(!showCRM)}
                  className="margin-right-6x nc10-border-override nc100-fg-override"
                >
                  Email creators
                </Button>
              ) : null}
            </div>

            <div
              className={`${detailOpen ? "nc10-border border-top-1 border-bottom-1" : ""} padding-y-4x padding-x-6x flex vcenter`}
            >
              <div className="full"></div>
              <ExpandButtonInline trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
            </div>
            <Collapsible expanded={detailOpen}>
              {!!boardDetails?.id && <ContentOverviewSection skipSubBrand skipPlatform section={"selected_board_content"} customSectionDetailsOverwrite={{ endpoint: `/Board/${boardDetails?.id}/contents` }} createEngagementRateClientSideIfMissing />}
            </Collapsible>
          </Card>
        )}
      </WrappedInLoader>
      {showCRM ? null : (
        <>
          {boardDetails?.hashtags?.length ? (
            <>
              <h3 className="headline-medium-highlight nc100-fg">Tracked hashtags</h3>
              <div className="flex margin-top-4x margin-bottom-2x wrapped">
                {boardDetails?.hashtags?.map((x, i) => (
                  <div key={i} className="chip  nc10-bg-override margin-right-2x margin-bottom-2x">
                    <span className="padding-x  button-medium-font ">#{x}</span>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          <AIAnalytics boardDetails={boardDetails} />
          {!hideCreator && (
            <>
              <BECreatorList
                guestBlurAfter={20}
                cutoffAt={6}
                section={"selected_board_added_creator"}
                mini={true}
                title={`Tracked creators`}
                showTailIcon={true}
                onTailIconClick={(c) => removeCreators(c)}
                itemFilterFunction={(c) => !deleteCreators[c?.id]}
                skipBrandRefresh
                skipDiscoveryPlatformRefresh
                skipSettingQueryFilter
                hideCreatorTimeFramedRow={true}
              >
                {/* {data?.bd_sec_selected_board_creator_raw?.pagination?.total_items? `(${data?.bd_sec_selected_board_creator_raw?.pagination?.total_items} creators)`:null} */}
              </BECreatorList>
            </>
          )}
          {!showCreator ? (
            <>
              {!!boardDetails?.id && <BELocalContentList
                className={`margin-top-9x`}
                showContentCountInTitle
                section={"selected_board_content"}
                showStats={false}
                showFilter={false}
                title={`Posts in this board`}
                customSectionDetailsOverwrite={{ endpoint: `/Board/${boardDetails?.id}/contents` }}>
              </BELocalContentList>}
            </>

          ) : (
            <BECreatorList
              className={`margin-top-9x`}
              guestBlurAfter={20}
              section={"selected_board_creator"}
              mini={true}
              title={`Creators`}
            >
              {/* {data?.bd_sec_selected_board_creator_raw?.pagination?.total_items? `(${data?.bd_sec_selected_board_creator_raw?.pagination?.total_items} creators)`:null} */}
            </BECreatorList>
          )}
        </>
      )}
    </div>
  )
}

export { Boards, BoardDetails }
