import { useDialog } from "../../hooks/useDialog"
import {
  LinkMediumMono,
  SearchMediumFilledMono,
  CopyLargeFilledMono,
  DownCaret,
  BanSolid,
  SmileyFaceMono,
  PokerFaceMono,
  AngryFaceMono,
} from "../../components/Icons"
import { useToast } from "../../hooks/useToast"
import { MailtoHellozelf, WrappedInLoader, SummaryCartItem, Card, PlatformIcon } from "../../components/dumbs"
import { useAnalytics } from "./analytics"
import igPermission from "../../assets/ig_permission.png"
import ttPermission from "../../assets/tt_permission.png"
import { useContext, useEffect, useMemo, useState } from "react"
import { dataContext } from "../datacontext"
import { AutoHeightTextarea, Input, TextArea } from "../../components/input"
import {
  makeURLReady,
  validateEmail,
  copyToClipboard,
  _nopropagate,
  prettyCent2USD,
  cloneObject,
  createCampaignInviteObject,
  rebindLinkClick,
  getArrayFromSeperatorString,
  cents2USD,
  capitalizeFirstLetter,
  getExternalUrlFromSocialAccount,
  connectTikapi,
} from "../../helpers/utils"
import { Button } from "../../components/button"
import { apiContext } from "../apicontext"
import { CreatorSelectList } from "../../components/structural/BrandEcosystem/beSections"
import { useLoading } from "../loaderContext"
import { Loader } from "../../components/loaders"
import { CampaignPopup, useCampaignPopupHelper } from "../../components/structural/BrandEcosystem/campaignPopup"
import { useBDBoundData, useLocalDataMutation, useRefreshLocalBoundDataHelper } from "../../hooks/useBoundData"
import { IconRadio } from "../../components/radio"
import { CopyableInput } from "../../components/copyableInput"
import { useNavigate } from "react-router-dom"
import { UnlockFullAction, UnlockFullExperience } from "../../components/structural/guestUnlockUI"
import { useBDPostData } from "../../hooks/useBoundData"
import { GET_CALLS, TIKAPI_OAUTH_ID } from "../../consts"
import { DateSpecificContent, OpenInAPageButton } from "../../components/structural/dateSpecificContent"
import { useBrandConfig } from "../../hooks/helperHooks"
import {
  AIBrandChatSettings,
  PlayWithGPT,
  UpdateBrandAISettingsAction,
} from "../../components/structural/aiAgentUpdate"
import { SelfServePopupHolder } from "../../components/structural/BrandEcosystem/selfServeSearchSettings"
import { MUTATION_CALLS } from "../../consts"

import TrumpImg from "../../assets/political/trump.png"
import BidenImg from "../../assets/political/biden.png"
import KamalaImg from "../../assets/political/kennedy.png"
import RFKImg from "../../assets/political/rfk.png"
import WalzImg from "../../assets/political/Walz.png"
import VanceImg from "../../assets/political/vance.png"
import { EmptyCheckBoX } from "../../components/checkbox"
import c from "@editorjs/header"

function ErrorSocialConnection({ platform }) {
  const [data] = useContext(dataContext)

  return (
    <>
      {data?.social_connection_error?.error ??
        `Unfortunately we could not establish a connection with your ${platform} account. Please make sure you used the correct login and try again.`}
    </>
  )
}

const RemoveCreatorFromEcosystem = ({ creator_user_id }) => {
  const { loader, executeCall, payload } = useLocalDataMutation(MUTATION_CALLS.remove_from_ecosystem)
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()
  console.log(`loader ${loader}`)
  console.log(loader)
  const onClick = async () => {
    const reutrnthing = await executeCall({
      creator_id: Number(creator_user_id),
    })
    if (!reutrnthing) {
      toast(BanSolid, "Something went wrong while removing creator")
      return
    }
    dispatch({
      client_remove_from_circle: [...data?.client_remove_from_circle, Number(creator_user_id)],
    })
    toast("Creator removed from circle creators.")
    api?.alert?.dismiss()
  }
  return (
    <Button onClick={onClick}>
      {loader ? (
        <>
          <Loader className="margin-right" />
          <span>Removing</span>
        </>
      ) : (
        <>Remove</>
      )}{" "}
    </Button>
  )
}

function DeleteSubBrand({}) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loader, executeCall } = useBDPostData({ section: "delete_subbrand" })
  const toast = useToast()
  const navigate = useNavigate()

  const deleteHandler = async () => {
    const res = await executeCall()
    toast("space deleted")
    dispatch({
      sub_brand_id: null,
    })
    api.brand.me()
    api?.alert?.dismiss()
    navigate("/discover-content")
  }
  return <Button onClick={deleteHandler}>{loader ? <Loader /> : "Delete page"}</Button>
}

function AddUrlContentToCampaignForm({ campaign_creator_info, array_index, old_value }) {
  const [data, dispatch] = useContext(dataContext)
  const arr = ["tiktok", "instagram", "youtube"]
  const _values = { ...data?.add_urls_to_campaign_inputs }

  const onChangeHandler = (value, platform) => {
    dispatch({
      add_urls_to_campaign_inputs: {
        ..._values,
        [platform]: value,
      },
    })
  }
  useEffect(() => {
    if (old_value?.content_url) {
      dispatch({
        add_urls_to_campaign_inputs: old_value?.content_url,
      })
    }
    return () => dispatch({ add_urls_to_campaign_inputs: null })
  }, [])

  return (
    <div>
      <p className="body-regular-font margin-bottom-5x">
        Once you are done reposting to your social profiles, you can add the post links below to track performance.{" "}
      </p>
      <div className="group-border body-regular-font nc00-bg">
        {arr.map((x) => (
          <div key={x} className="flex a-center padding-3x group-border-item ">
            <PlatformIcon platform={x} color />
            <div className="divider compact-input-divider"></div>
            <input
              className="bear-input full full-width"
              value={_values[x] ?? ""}
              onChange={(e) => onChangeHandler(e.target.value, x)}
              placeholder={`Paste your ${x} here`}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
function AddUrlContentToCampaignAction({ campaign_creator_info, array_index, old_value }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { payload, executeCall } = useBDPostData({ section: "add_content_to_campaign" })
  const _values = { ...data?.add_urls_to_campaign_inputs }
  const payloadHandler = async () => {
    const payload = []
    for (let key in _values) {
      if (_values[key]) {
        payload.push(key)
      }
    }
    if (payload.length) {
      await executeCall([
        {
          array_index: array_index,
          campaign_creator: campaign_creator_info.id,
          content_url: _values,
        },
      ])
      await api.brand.dashboardSection({ section: "campaign_creators" })
      api?.alert?.dismiss()
    }
  }
  return (
    <Button onClick={payloadHandler} className="margin-left-2x">
      Done
    </Button>
  )
}

function ArchiveCreatorAction({ creator }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loader, executeCall: archiveCreator } = useBDPostData({ section: "archive_creator" })
  console.log(loader)
  const onConfirm = async () => {
    await archiveCreator({
      id: Number(data?.discover_selected_creator_id),
      brand_id: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id,
    })
    api.brand.dashboardSection({ section: "discover_selected_creator" })
    api.brand.dashboardSection({ section: "creator_public_not_zelf_profile_content" })
    api.brand.dashboardSection({ section: "creator_public_not_zelf_profile_content_stats" })
    api?.alert?.dismiss()
  }

  return (
    <Button onClick={onConfirm} disabled={loader}>
      {" "}
      {loader ? <Loader></Loader> : "Remove Creator"}{" "}
    </Button>
  )
}

function ConfirmCreatorCampaignPopupAction({ campaign_creator_info }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loader: creator_update_post_loader, executeCall: updateCreator } = useBDPostData({
    section: "change_creator_payout_campaign",
  })
  const onConfirm = async () => {
    console.log(campaign_creator_info)

    const currentValue = data?.current_creator_payout_table?.[campaign_creator_info.creator] ?? {}
    const payload = createCampaignInviteObject({
      currentValue: currentValue,
      campaignID: data?.bd_sec_campaign_summary?.id,
      creatorID: campaign_creator_info.creator,
      is_contract_confirmed: true,
    })
    if (!payload) return
    delete payload["is_posting_enabled"]

    await updateCreator([payload])
    await api.brand.dashboardSection({ section: "campaign_creators" })
    api?.alert?.dismiss()
  }

  return <Button onClick={onConfirm}>Confirm & send Contract</Button>
}

function ConnectPlatform({ platform = "tiktok" }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const openUpTikapiPopup = () => {
    if (!window.TikAPI) {
      console.error("Tikapi popup is not loaded")
      return
    }
    window.TikAPI.popup({
      client_id: TIKAPI_OAUTH_ID,
      //scope: ['VIEW_PROFILE', 'USER_MESSAGES'], (optional)
    })
  }
  const onConfirm = async () => {
    openUpTikapiPopup()
    api?.alert?.dismiss()
  }

  return <Button onClick={openUpTikapiPopup}>Connect {capitalizeFirstLetter(platform)}</Button>
}

function GuestCheckoutForm() {
  const [data, dispatch] = useContext(dataContext)
  const updateGCInfo = (field, value) => {
    dispatch({ gc_form: { ...data.gc_form, [field]: value } })
  }
  useEffect(() => {
    const validName = Boolean(data.gc_form.full_name)
    const validCompany = Boolean(data?.gc_form.company_name)
    const validEmail = validateEmail(data?.gc_form.email_address)
    if (validName && validCompany && validEmail) {
      sessionStorage.setItem("gc_form_draft", JSON.stringify(data?.gc_form))
      dispatch({ dialog_button_disabled: false })
    } else dispatch({ dialog_button_disabled: true })
  }, [data.gc_form])
  useEffect(() => {
    dispatch({ dialog_button_disabled: true })
    return () => dispatch({ dialog_button_disabled: false })
  }, [])
  return (
    <div className="guest-co-info-form">
      <Input
        value={data.gc_form?.full_name ?? ""}
        className="margin-bottom-2x"
        placeholder="Full name"
        onChange={(e) => updateGCInfo("full_name", e.target.value)}
      />
      <Input
        value={data.gc_form?.company_name ?? ""}
        className="margin-bottom-2x"
        placeholder="Company name"
        onChange={(e) => updateGCInfo("company_name", e.target.value)}
      />
      <Input
        value={data.gc_form?.email_address ?? ""}
        placeholder="E-mail address"
        onChange={(e) => updateGCInfo("email_address", e.target.value)}
      />
    </div>
  )
}

function GuestCheckoutAction() {
  const [data, dispatch] = useContext(dataContext)
  const confirmGuestCheckout = () => {
    dispatch({ dialog_payload: null })
    console.log(data.gc_form)
  }
  return (
    <Button type="primary" onClick={confirmGuestCheckout}>
      Continue
    </Button>
  )
}

function ConfirmationBoostingBody({ creator }) {
  const [data, dispatch] = useContext(dataContext)
  return (
    <div>
      {" "}
      We’ve created a thread with you and @{creator?.username ?? "creator"}. Please keep an eye on your inbox at{" "}
      {data?.me?.email} for the whitelisting code from @{creator?.username ?? "creator"}.
    </div>
  )
}

function EmailCreatorsForm({ section }) {
  const [data, dispatch] = useContext(dataContext)
  const toast = useToast()
  const creatorEmailList = (creators) => {
    let emails = ""
    if (creators.length) {
      emails = creators.map((_cr) => `${_cr.username}<${_cr?.email}>`).join(",") // email address
    }
    dispatch({ creator_email_list: emails })
  }
  useEffect(() => {
    return () => {
      dispatch({ creator_email_list: "" })
    }
  }, [])
  const doCopy = () => {
    copyToClipboard(data.creator_email_list)
    toast("Email Addresses copied")
  }
  return (
    <div className="email-creators-form">
      <div className="nc100-fg body-highlight-font">
        To reach out, you can select the creators you want to reach out to. You will still have to send the e-mails to
        them using your own e-mail application.
      </div>
      <div className="margin-top-4x nc50-fg body-regular-font">Select creators you want to email:</div>
      <CreatorSelectList
        className="creator-select-list-indialog nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-hidden"
        section="creator_circle_creator_emails"
        onChange={(creators) => creatorEmailList(creators)}
      />
      <div className="margin-top-4x nc50-fg body-regular-font">E-mail addresses of selected creators</div>
      <Input
        className="margin-top-2x margin-bottom-4x"
        secondIconElement={CopyLargeFilledMono}
        secondIconClasses="cursor-pointer"
        onSecondIconClick={doCopy}
        value={data.creator_email_list}
      />
    </div>
  )
}

function CopyBrandSpecificInviteLink() {
  const [data, dispatch] = useContext(dataContext)
  const toast = useToast()

  let hostName = window.location.hostname
  // localhost
  if (hostName === "localhost") {
    hostName = window.location.host
  }
  const link_add = `/start-from-brand-invite?refcode=${data?.me?.brand?.ref_code}`
  const prefix = window.location.protocol + "//"

  return (
    <div className="margin-bottom-5x">
      <p className="txt-center margin-bottom-4x">
        Copy the invite link and share with creators you want to join your creator pool
      </p>
      <CopyableInput
        textPart={<div className="primary-fg txt-left">{hostName + link_add}</div>}
        copyableText={prefix + hostName + link_add}
      />
    </div>
  )
}

function AddCreatorDirectlyToDraftCampaign() {
  const navigate = useNavigate()
  const [data, dispatch] = useContext(dataContext)

  const onCreatorsChange = (creators) => {
    const creators_circle = data?.add_creators_directly_to_campaign_list ?? []
    const set = new Set([...creators, ...creators_circle])

    console.log(creators)
    dispatch({
      add_creators_directly_to_campaign_list: [...set],
    })
  }
  return (
    <div>
      <div className="flex a-center j-space-between body-regular-font">
        <div className="nc30-fg">Creator circle</div>
        <div
          className="primary-fg cursor-pointer"
          role="button"
          onClick={() => window.open("/discover-creator/creator-circle", "_self")}
        >
          visit creator circle
        </div>
      </div>
      <CreatorSelectList
        isNotExclusion={true}
        showFollowers={true}
        showEmail={true}
        className=" nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-hidden"
        section="all_circle_creators"
        onChange={(creators) => onCreatorsChange(creators)}
      />
      {/* <div className="flex a-center j-space-between body-regular-font">
            <div className="nc30-fg">Other Creators</div>
            <div className="primary-fg cursor-pointer" role="button" onClick={onVisitingOtherCreator}>see all creators</div>
        </div>
        <CreatorSelectList isNotExclusion={true} className=" nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-hidden"
        section="newly_joined_creators" 
        onChange={creators => onCreatorsChange(creators,true)}/> */}
    </div>
  )
}

function AddCreatorDirectlyToDraftCampaignActions() {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)

  const creatorList = data?.add_creators_directly_to_campaign_list ?? []

  const addToCampaign = async () => {
    const added = await api.brand.addCreatorsToCampaign(creatorList, data?.bd_sec_campaign_summary?.id)
    if (added) {
      api?.alert?.dismiss()

      // to refresh the creators in the details views
      api.brand.dashboardSection({ section: "campaign_creators" })
      api.brand.dashboardSection({ section: "campaign_creator_details" })
    }
  }

  useEffect(() => {
    dispatch({
      add_creators_directly_to_campaign_list: [],
    })
  }, [])

  return (
    <Button
      disabled={!data.add_creators_directly_to_campaign_list?.length || !data?.bd_sec_campaign_summary?.id}
      onClick={addToCampaign}
    >
      Add to campaign
    </Button>
  )
}

function AddCreatorsCampaignForm() {
  const [data, dispatch] = useContext(dataContext)
  const popup = useCampaignPopupHelper()
  const creatorCirclelList = (creators) => {
    dispatch({ creator_campaign_add_list: [...creators] })
  }
  useEffect(() => {
    return () => {
      dispatch({
        creator_campaign_add_list: [],
        add_target_camp: null,
      })
    }
  }, [])
  return (
    <div className="add-creators-to-campaign">
      <div
        onClick={popup.show}
        className="flex vcenter border-radius-4 cursor-pointer position-relative border-1 margin-bottom-4x padding-x-4x padding-y-3x nc100-fg nc05-border"
      >
        <span className="full">{data?.add_target_camp ? data?.add_target_camp.name : "Select Campaign"}</span>
        {DownCaret}
        {popup.shown ? (
          <CampaignPopup
            onDismiss={popup.hide}
            nextDialog="addCreatorsToCampaign"
            hasDraft={false}
            watchNew={true}
            className="bottom-mounted full-container-width"
            kind="CREATOR"
            onCampaignClick={(c) => dispatch({ add_target_camp: c })}
          />
        ) : null}
      </div>
      <div className="margin-top-4x nc50-fg body-regular-font">Select creators you want to add:</div>
      <CreatorSelectList
        showFollowers={true}
        showEmail={true}
        isNotExclusion={true}
        className="creator-select-list-indialog nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-auto"
        section="all_circle_creators"
        onChange={(creators) => creatorCirclelList(creators)}
      />
    </div>
  )
}

function AddCreatorsCampaignAction() {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const toast = useToast()
  const { loaders } = useLoading()
  const addToCampaign = async () => {
    const added = await api.brand.addCreatorsToCampaign(data.creator_campaign_add_list, data?.add_target_camp.id)
    if (added) toast("Added to campaign")
    api?.alert?.dismiss()
  }
  return (
    <Button
      disabled={!data.creator_campaign_add_list?.length || !data.add_target_camp}
      type="primary"
      onClick={addToCampaign}
    >
      {loaders["campaign_create"] || loaders["stuff-to-campaign"] ? <Loader /> : "Add To Campaign"}
    </Button>
  )
}

function CopyEmailActions() {
  const [data] = useContext(dataContext)
  const toast = useToast()
  const doCopy = () => {
    copyToClipboard(data.creator_email_list)
    toast("Email Addresses copied")
  }
  return (
    <Button disabled={!data?.creator_email_list?.length} type="primary" onClick={doCopy}>
      Copy e-mail addresses
    </Button>
  )
}

function NewCampaignForm() {
  const [data, dispatch] = useContext(dataContext)
  useEffect(() => {
    return () => {
      dispatch({
        new_campaign_draft: "",
        pending_campaign_add: null,
      })
    }
  }, [])
  return (
    <div className="create-campaign-form">
      <div className="button-medium-font nc100-fg margin-bottom-2x">Give your campaign a name</div>
      <Input
        value={data?.new_campaign_draft}
        onChange={(e) => dispatch({ new_campaign_draft: e.target.value })}
        placeholder="campaign name"
      />
    </div>
  )
}

function CreateOrEditBoardForm({ board, type, enableHashtagsInput = true, enableCreatorsSelect = false }) {
  const [data, dispatch] = useContext(dataContext)
  const { is_circle_enabled } = useBrandConfig()
  useEffect(() => {
    if (board?.name) {
      dispatch({
        new_board_draft: board?.name,
      })
    }
    return () => {
      dispatch({
        new_board_draft: "",
        pending_board_add: null,
        new_board_hashtags_draft: null,
      })
    }
  }, [])
  const onCreatorsCircleChange = (creators) => {
    // const creators_circle = data?.add_board_from_creator_ecosystem ?? []
    const set = new Set([...creators])
    dispatch({ add_board_from_creator_ecosystem: [...set].map((x) => x.id) })
  }

  const onCreatorsNormalChange = (creators) => {
    // const creators_circle = data?.add_board_from_social_account ?? []
    const set = new Set([...creators])
    dispatch({ add_board_from_unregistered_creator: [...set].map((x) => x.id) })
  }

  return (
    <div className="create-campaign-form">
      <div className="button-medium-font nc100-fg margin-bottom-2x">Give your board a name</div>
      <Input
        value={data?.new_board_draft ?? ""}
        onChange={(e) => dispatch({ new_board_draft: e.target.value })}
        placeholder={type == "tracking" ? "Tracking panel name" : "board name"}
      />

      {enableHashtagsInput && (
        <>
          <div className="button-medium-font nc100-fg margin-bottom-2x margin-top-5x">
            {board ? "Add more hashtags" : "Hashtags"}
          </div>
          <Input
            value={data?.new_board_hashtags_draft ?? ""}
            onChange={(e) => dispatch({ new_board_hashtags_draft: e.target.value })}
            placeholder="pen,pineapple,applepen"
          />
          <div className="button-small-font nc50-fg margin-top ">
            Posts with the mentioned hashtags will get automatically added to the board
          </div>
        </>
      )}

      {enableCreatorsSelect ? (
        <div className="margin-top-4x">
          <div className="margin-bottom-4x">
            <p className="button-medium-font">Select creators for tracking</p>
            <p className="caption-regular nc30-fg"> (You can add creators to your panel anytime from their profiles)</p>
          </div>
          {is_circle_enabled ? (
            <>
              <div className="flex a-center j-space-between body-regular-font">
                <div className="nc30-fg">Creator circle</div>
                <div
                  className="primary-fg cursor-pointer"
                  role="button"
                  onClick={() => window.open("/discover-creator/creator-circle", "_self")}
                >
                  visit creator circle
                </div>
              </div>
              <CreatorSelectList
                isNotExclusion={true}
                showFollowers={true}
                showEmail={true}
                className=" nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-hidden"
                section="all_circle_creators"
                onChange={(creators) => onCreatorsCircleChange(creators)}
              />
            </>
          ) : null}
          <div className="flex a-center j-space-between body-regular-font">
            <div className="nc30-fg">{is_circle_enabled ? "Other" : "All"} creators</div>
            <div
              className="primary-fg cursor-pointer"
              role="button"
              onClick={() => window.open("/discover-creator", "_self")}
            >
              see all creators
            </div>
          </div>
          <CreatorSelectList
            isNotExclusion={true}
            showFollowers={true}
            showEmail={true}
            className=" nc05-border margin-top-2x margin-bottom-4x border-1 border-radius-dynamic overflow-hidden"
            section="all_creators_by_brand"
            onChange={(creators) => onCreatorsNormalChange(creators)}
          />
        </div>
      ) : null}
    </div>
  )
}

function NewCampaignAction({ hasAdd = false, nextDialog = "", kind = "CREATOR" }) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const toast = useToast()
  const createCampaign = async () => {
    const created = api.brand.createNewCampaign(data?.new_campaign_draft, kind)
    if (!created) return false
    if (hasAdd) toast("Added to campaign")
    else toast("Campaign created")
    if (nextDialog) api?.alert?.[nextDialog]?.()
    else api?.alert?.dismiss()
  }

  return (
    <Button type="primary" onClick={createCampaign} disabled={!data?.new_campaign_draft}>
      {loaders["campaign_create"] || loaders["stuff-to-campaign"] ? <Loader /> : "Create Campaign"}
    </Button>
  )
}

function BoardCreateOrEditAction({ hasAdd = false, nextDialog = "", board, type }) {
  const [data, dispatch] = useContext(dataContext)
  const { refreshSection } = useRefreshLocalBoundDataHelper(GET_CALLS.board_list)
  const api = useContext(apiContext)
  const { loaders } = useLoading()
  const toast = useToast()
  const [bl, setBL] = useState(false)

  const createBoard = async () => {
    const hashtagsArray = Array.from(
      new Set([...(board?.hashtags ?? []), ...getArrayFromSeperatorString(data?.new_board_hashtags_draft)]),
    )
    if (!board) setBL(true)
    const created = await api.brand.createOrUpdateNewBoard(data?.new_board_draft, hashtagsArray, board?.id, type)
    if (!created) {
      toast("Some went wrong with the board creation process...", BanSolid)
      setBL(false)
      return false
    }
    // Delay board creation by 4 seconds. only for create
    setTimeout(
      async () => {
        if (hasAdd) toast("Added to board")
        else {
          if (board) {
            toast(type === "tracking" ? "Tracking panel changed" : "Board changed")
            window.location.reload()
          } else {
            refreshSection()
            toast(type === "tracking" ? "Tracking panel created" : "Board created")
            await api.brand.dashboardSection({
              section:
                type == "tracking"
                  ? "board_list_tracking"
                  : data?.board_category == "creator"
                    ? "board_list_creator"
                    : "board_list_content",
            })
            if (created?.board_type == 2 && data?.board_category && !data?.ecosystem_selected_content?.content) {
              // window.open("/brand-tracking","_self")
            }
          }
        }
        setBL(false)
        if (nextDialog) api?.alert?.[nextDialog]?.()
        else api?.alert?.dismiss()
      },
      board ? 0 : 4000,
    )
  }

  return (
    <>
      {/* delete board button */}
      {board && (
        <Button
          type="transparent"
          onClick={() => api?.alert?.deleteBoard({ board })}
          className="margin-right-auto order--1 negative-border-override negative-fg-override negative-bg-onhover nc00-fg-onhover"
        >
          Delete
        </Button>
      )}

      {/* create/update board button */}
      <Button
        type="primary"
        onClick={createBoard}
        className="margin-left"
        disabled={!data?.new_board_draft || loaders["board_create"] || loaders[`bd-section-board_list`]}
      >
        {loaders["board_create"] || loaders[`bd-section-board_list`] || bl ? (
          <Loader />
        ) : board ? (
          "Done"
        ) : (
          "Create new board"
        )}
      </Button>
    </>
  )
}

function BoardDeleteAction({ board }) {
  const { loader, executeCall, payload } = useLocalDataMutation({
    ...MUTATION_CALLS.delete_board,
    endpoint: `/Board/${board?.id}`,
    method: "deleteReq",
  })
  const api = useContext(apiContext)
  const navigate = useNavigate()
  const toast = useToast()
  const deleteBoard = async () => {
    console.log("deleting board", board)
    const deleted = await executeCall()
    if (deleted) {
      toast("Board deleted")
      api?.alert?.dismiss()

      if (board?.board_type == 1 && board?.category == 1 && board?.analytics_type == 1) {
        // AI analytics boards: board_type = 1, category = 1, analytics_type = 1
        navigate("/discover-content/ai-analytics-boards")
      } else if (board?.board_type == 1 && board?.category == 2) {
        // saved creator boards: board_type = 1, category = 2
        navigate("/discover-creator/creator-boards")
      } else if (board?.board_type == 2) {
        // tracking boards: board_type = 2
        navigate("/discover-content/tracking-boards")
      } else {
        // saved content boards: board_type = 1, category = 1
        navigate("/discover-content/saved-content-boards")
      }
    }
  }
  return (
    <Button className="margin-left negative-bg-override" onClick={deleteBoard}>
      {loader ? <Loader /> : "Delete"}
    </Button>
  )
}

function EditIssuesAndSentimentBody({ contentPayload, contentStats }) {
  const [data, dispatch] = useContext(dataContext)
  const [isDataChanged, setIsDataChanged] = useState(false)

  const themes = data?.me?.brand?.sub_brands?.filter((x) => x.sub_brand_type == 2) ?? []
  const nps_score = data?.date_specific_dialog_data?.is_competitor ? contentPayload?.content?.nps_score : null

  const politicalTeams = useMemo(() => {
    return [
      { title: "Kamala", img: KamalaImg, key: "kamala_nps" },
      { title: "Trump", img: TrumpImg, key: "trump_nps" },
      { title: "RFK", img: RFKImg, key: "rfk_nps" },
      { title: "Walz", img: WalzImg, key: "tim_nps" },
      { title: "Vance", img: VanceImg, key: "vance_nps" },
      { title: "Biden", img: BidenImg, key: "biden_nps" },
    ]
  }, [])

  const reactions = useMemo(() => {
    return [
      { title: "Smiley", icon: SmileyFaceMono, value: 1, color: "positive-fg" },
      { title: "Poker", icon: PokerFaceMono, value: 0, color: "attention-fg" },
      { title: "Angry", icon: AngryFaceMono, value: -1, color: "negative-fg" },
      { title: "N/A", icon: "N/A", value: null, color: "" },
    ]
  }, [])

  const statsData = useMemo(() => {
    return {
      content_id: contentStats?.id,
      biden_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "Joe Biden" ? nps_score : null) ??
        contentStats?.biden_nps,
      trump_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "Donald Trump" ? nps_score : null) ??
        contentStats?.trump_nps,
      kamala_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "Kamala Harris" ? nps_score : null) ??
        contentStats?.kamala_nps,
      vance_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "JD Vance" ? nps_score : null) ??
        contentStats?.vance_nps,
      rfk_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "RFK Jr" ? nps_score : null) ??
        contentStats?.rfk_nps,
      tim_nps:
        (data?.date_specific_dialog_data?.originalValue?.brand_title === "Tim Walz" ? nps_score : null) ??
        contentStats?.tim_nps,
      score_rational: { text: "" },
      brands: contentStats?.brands?.filter((x) => x.sub_brand_type == 2).map((x) => x.brand_id) ?? [],
    }
  }, [contentStats, data?.date_specific_dialog_data])

  const handleEditInputChange = (key, val) => {
    dispatch({
      edit_issue_sentiment: {
        ...data?.edit_issue_sentiment,
        [key]: val,
      },
    })
  }
  const handleIssuesChange = (id) => {
    handleEditInputChange(
      "brands",
      data?.edit_issue_sentiment?.brands?.includes(id)
        ? data?.edit_issue_sentiment?.brands?.filter((x) => x !== id)
        : [...data?.edit_issue_sentiment?.brands, id],
    )
  }

  useEffect(() => {
    if (contentStats) {
      dispatch({
        edit_issue_sentiment: {
          ...statsData,
        },
        edit_issue_sentiment_additional: {
          isDataChanged: false,
          prev_issues: statsData?.brands,
        },
      })
    }
    return () => {
      dispatch({
        edit_issue_sentiment: null,
        edit_issue_sentiment_additional: null,
      })
    }
  }, [])

  useEffect(() => {
    if (statsData && data?.edit_issue_sentiment) {
      const statsWithoutRational = { ...statsData, score_rational: {} }
      const editWithoutRational = { ...data?.edit_issue_sentiment, score_rational: {} }
      let isDataChanged = JSON.stringify(statsWithoutRational) !== JSON.stringify(editWithoutRational)

      // check if  brands are changed as id positions are not fixed
      if (!isDataChanged) {
        isDataChanged =
          JSON.stringify(statsData?.brands.sort()) !== JSON.stringify(data?.edit_issue_sentiment?.brands.sort())
      }

      setIsDataChanged(isDataChanged)
      dispatch({
        edit_issue_sentiment_additional: {
          ...data?.edit_issue_sentiment_additional,
          isDataChanged,
        },
      })
    }
  }, [data?.edit_issue_sentiment, statsData])

  return (
    <div className="txt-left">
      <div className="flex gap-x-1 gap-y-2 vcenter wrapped margin-bottom-6x">
        {themes?.map((item, i) => (
          <EmptyCheckBoX
            key={i}
            className={`capsule-button notransition button tertiary small ${data?.edit_issue_sentiment?.brands?.includes(item.id) ? "selected" : ""}`}
            onChange={() => handleIssuesChange(item.id)}
            checked={data?.edit_issue_sentiment?.brands?.includes(item.id)}
          >
            <span className="display-inline-block">{item?.title}</span>
          </EmptyCheckBoX>
        ))}
      </div>
      <div className="border-1 nc10-border border-radius-6 margin-bottom-4x grid grid-cols-12">
        <div className="col-span-4 flex column">
          {politicalTeams.map((item, i) => (
            <div
              key={i}
              className={`flex gap-4 vcenter padding-4x ${i === politicalTeams.length - 1 ? "" : "border-bottom-1 nc10-border"}`}
            >
              <img height={34} width={34} className="border-radius-round" src={item.img} alt={item.title} />
              {reactions.map((reaction, j) => (
                <IconRadio
                  key={j}
                  className={`cursor-pointer ${reaction.title === "N/A" ? "border-left-1 nc30-border padding-left-3x" : ""} ${data?.edit_issue_sentiment?.[item.key] === reaction.value ? reaction.color : ""}`}
                  icon={reaction.icon}
                  value={reaction.value == null ? "" : reaction.value}
                  selected={true}
                  justify="center"
                  onClick={(val) => handleEditInputChange(item.key, val === "" ? null : val)}
                />
              ))}
            </div>
          ))}
        </div>
        <div className="col-span-8 border-left-1 nc10-border">
          {isDataChanged ? (
            <div className="flex column full-height">
              <div className="border-bottom-1 nc10-border padding-4x caption-highlight nc75-fg">Score Rationale</div>
              <TextArea
                enableDebounce
                debounceTime={500}
                onChange={(value) => handleEditInputChange("score_rational", { text: value, source: "client" })}
                className={`full padding-3x border-none-important nc02-bg`}
                placeholder="Let us know your rationale..."
              />
            </div>
          ) : (
            <div className="padding-4x">
              <div className="caption-highlight nc75-fg margin-bottom-2x">Score Rationale</div>
              {contentStats?.score_rational?.text || "N/A"}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function UpdateIssuesAndSentimentAction() {
  const { loaders } = useLoading()
  const toast = useToast()
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)

  const updateIssue = async () => {
    if (!data?.edit_issue_sentiment_additional?.isDataChanged) return

    const payload = {
      ...data?.edit_issue_sentiment,
      brands:
        JSON.stringify(data?.edit_issue_sentiment?.brands?.sort()) ===
        JSON.stringify(data?.edit_issue_sentiment_additional?.prev_issues?.sort())
          ? null
          : data?.edit_issue_sentiment?.brands,
    }

    const updated = await api.brand.updateContentIssueAndSentiment(payload)
    if (updated) {
      toast("Issues and Sentiment updated")
      api?.alert?.dismiss()
      dispatch({
        ecosystem_selected_content: null,
        content_modal_content_array: [],
        content_modal_content_index: 0,
        opened_from: null,
      })
    }
  }

  return (
    <Button
      type="primary"
      onClick={updateIssue}
      className="margin-left"
      disabled={
        !data?.edit_issue_sentiment_additional?.isDataChanged ||
        loaders["update_content_issue_sentiment"] ||
        !data?.edit_issue_sentiment?.score_rational?.text
      }
    >
      {loaders["update_content_issue_sentiment"] ? <Loader /> : null ? "Done" : "Update"}
    </Button>
  )
}

function useSystemAlerts() {
  const dialog = useDialog()
  const toast = useToast()
  const analytics = useAnalytics()
  const [data, dispatch] = useContext(dataContext)
  const cartAdded = () => {
    return toast("Added to cart")
  }

  const dismiss = () => {
    dispatch({ dialog_payload: null })
  }
  const comingSoon = (feature_name) => {
    const fText = typeof feature_name === "string" ? feature_name : "This feature"
    toast(`${fText} is coming soon!`)
  }

  const searchComingSoon = () => {
    analytics.action("content", "search")
    return dialog({
      body: "We are still working on this feature. Thank you for your patience.",
      title: "Coming Soon",
      subtitle: "Search is Coming Soon!",
      icon: SearchMediumFilledMono,
    })
  }

  const comingSoonDialog = (feature) => {
    return dialog({
      body: "We are still working on this feature. Thank you for your patience.",
      title: "Coming Soon",
      subtitle: `${feature} Coming Soon!`,
    })
  }

  const whyAdManagerID = () => {
    return dialog({
      body: "For whitelisting Instagram content, the creator needs to give access to the brand’s Facebook account. Connect to Facebook for instant access to the content, or add your Facebook Business Manager Account ID so the creator to add you manually.",
      title: "Facebook business manager ID",
    })
  }
  const failedConnect = (platform) => {
    analytics.action("creator:profile", "failed_social_connect", platform)
    return dialog({
      body: <ErrorSocialConnection platform={platform} />,
      title: `Connection failed`,
    })
  }
  const GraphRequirements = (platform) => {
    analytics.action("creator:profile", "graph_connect_failed_attempt_account_type_not_correct", platform)
    return dialog({
      body: `Please follow the instructions in step 1 and step 2 to finish converting your profile before proceeding`,
      title: "Steps incomplete",
    })
  }

  const paymentError = (method) => {
    analytics.action("cart", "failed_payment", method)
    return dialog({
      body: `Unfortunately we could not process your ${method} payment. Please make sure you used the correct information and try again.`,
      title: "Payment Failed",
    })
  }

  const systemErr = (method) => {
    return dialog({
      body: `An error occured! Please try again.`,
      title: "Error",
    })
  }

  const prohibitedUse = () => {
    const Prohibited = (
      <div>
        Besides the standard prohibited uses as laid out in the Terms of Service, which include but are not limited to:
        <ul>
          <li>Unlawful Use</li>
          <li>Standalone File Use</li>
          <li>Sensitive Use (content used in an unflattering or unduly controversial use)</li>
          <li>False claim of Authorship, or</li>
          <li>
            Use for any type of activity not explicitly described in the license type acquired, including, but not
            limited to, non-digital use of the content or use in any of the the “non-listed license types” in the Terms
            of Service
          </li>
        </ul>
        This content is also restricted from use by the below criteria. You as the licensee are solely responsible for
        adhering to the additional conditions for this content: ...
      </div>
    )
    return dialog({
      body: Prohibited,
      title: "Prohibited Uses",
      ypadding: 5,
    })
  }
  const deferContentDelivery = () => {
    return dialog({
      body: (
        <span>
          We are working with YouTube and TikTok to make this instantly downloadable. Until then, we will email you the
          content from the creator within 48 hours, or you can download it directly from YouTube and TikTok. Thank you
          for bearing with us as we are working to provide you with a more streamlined experience. For more information,
          feel free to connect with Zelf. support at <MailtoHellozelf />
        </span>
      ),
      title: "Download Content",
      subtitle: "We will e-mail you the content!",
    })
  }
  const deferWhitelisting = () => {
    return dialog({
      body: (
        <span>
          Whitelisting orders require the brands Facebook Business Manager ID to be added to the creator's page (IG) or
          require the creator to generate a unique whitelisting code for you (TT)
          <br />
          .We will connect you with the creator via email within the next two hours; the creator should provide you the
          code within 48 hours. If you have any questions regarding this process, please email <MailtoHellozelf />
        </span>
      ),
      title: "Whitelisting",
    })
  }
  const alertBeforeIGConnect = (callback) => {
    return dialog({
      body: `When Instagram asks for permissioning to Zelf, be sure to also select the second option “Media (Optional)”.`,
      title: "Please note:",
      picture: igPermission,
      callback: callback,
      buttonText: "Continue",
    })
  }
  const alertBeforeTTConnect = (callback) => {
    return dialog({
      body: `If TikTok asks for permissioning to Zelf, be sure to keep the second option “Read your public videos on TikTok” selected`,
      title: "Please note:",
      picture: ttPermission,
      callback: callback,
      buttonText: "Continue",
    })
  }

  const formFieldMissing = () => {
    return dialog({
      body: `Project name, High level project description, Project dates and Brand website are mandatory fields`,
      title: "Please fill in all mandatory fields",
    })
  }
  const invalidWebsiteURL = () => {
    return dialog({
      body: `Please enter a valid url`,
      title: "Invalid brand website",
    })
  }
  const emptyPrice = () => {
    return dialog({
      body: `Please enter a valid price for all your selected services, or deselect the service`,
      title: "Empty price",
    })
  }

  const GuestCheckoutDialog = (onAccept) => {
    return dialog({
      body: <GuestCheckoutForm />,
      action: <GuestCheckoutAction />,
      title: "Confirm project",
      subtitle: "Please provide your name and email address to continue to checkout",
    })
  }

  const emailCreatorsInCircle = () => {
    return dialog({
      body: <EmailCreatorsForm />,
      action: <CopyEmailActions />,
      title: "E-mail creators",
      align: "left",
      paddingY: "0",
    })
  }

  const addCreatorsToCampaign = () => {
    return dialog({
      body: <AddCreatorsCampaignForm />,
      action: <AddCreatorsCampaignAction />,
      title: "Add creators to campaign",
      align: "left",
      paddingY: "0",
    })
  }

  function confirmBoosting(creator) {
    return dialog({
      body: <ConfirmationBoostingBody creator={creator} />,
      title: "Boost post",
    })
  }

  function createNewCampaign(hasAdd = false, nextDialog = "", kind = "CREATOR") {
    return dialog({
      body: <NewCampaignForm />,
      action: <NewCampaignAction kind={kind} hasAdd={hasAdd} nextDialog={nextDialog} />,
      title: "Create new campaign",
      align: "left",
    })
  }

  function createOrEditBoard({
    hasAdd = false,
    nextDialog = "",
    kind = "CREATOR",
    board,
    type,
    enableHashtagsInput = true,
    enableCreatorsSelect = false,
  }) {
    return dialog({
      body: (
        <CreateOrEditBoardForm
          board={board}
          type={type}
          enableHashtagsInput={enableHashtagsInput}
          enableCreatorsSelect={enableCreatorsSelect}
        />
      ),
      action: <BoardCreateOrEditAction kind={kind} hasAdd={hasAdd} nextDialog={nextDialog} board={board} type={type} />,
      title: type == "tracking" ? "Create new tracking panel" : board ? "Edit board" : "Create new Board",
      align: "left",
      forceDialogActionClassName: "cs-dialog-footer flex padding-4x nc00-bg ",
      additionalContentClass: "nc02-bg padding-x-5x scrollable-dialog-content",
      additionalBodyClass: "max-width-400-important",
      forceinnerContentBodyClassName: "",
      paddingY: 6,
    })
  }

  function deleteBoard({ board }) {
    return dialog({
      body: (
        <div>
          Are you sure you want to delete <span className="txt-bold">{board?.name}</span>?
        </div>
      ),
      action: <BoardDeleteAction board={board} />,
      title: "Delete board",
    })
  }

  function copyBrandInviteToCreatorLink() {
    return dialog({
      body: <CopyBrandSpecificInviteLink />,
      title: "Invite creators",
      paddingY: "0",
    })
  }

  function inviteSentConfirmation(campaignName) {
    return dialog({
      body: (
        <div>
          <h3 className="headline-medium-highlight margin-bottom-3x">Invites sent</h3>
          <p className="body-regular-font">
            Progress of the content creation can be found under {campaignName} in the live campaign section.
          </p>
        </div>
      ),

      title: "Invites sent",
    })
  }

  function addCreatorsInDraftCampaignDirectly() {
    return dialog({
      body: <AddCreatorDirectlyToDraftCampaign />,
      action: <AddCreatorDirectlyToDraftCampaignActions />,
      title: "Add creators to campaign",
      paddingY: "0",
      align: "left",
      additionalContentClass: "scrollable-dialog-content",
    })
  }

  function messageCreator(creator) {
    return dialog({
      title: "Message creator",
      body: (
        <div className="txt-center">
          You can e-mail the creator to the following email address to reach out to them:
          <br />
          <br />
          <br />
          {creator?.email ? (
            <a className="primary-fg" href={`mailto:${creator?.email}`}>
              {creator?.email}
            </a>
          ) : (
            "No Email found on system"
          )}
        </div>
      ),
    })
  }

  function ContactCreator(creator) {
    return dialog({
      title: "Contact creator",
      body: (
        <div className="txt-left margin-y-5x margin-x-5x">
          You can contact creator via email or their social media accounts:
          {creator?.email ? (
            <div className="margin-top-5x">
              Email address:{" "}
              <a className="primary-fg txt-bold" href={`mailto:${creator?.email}`}>
                {creator?.email}
              </a>
            </div>
          ) : null}
          {Object.keys(creator?.connected_accounts ?? {}).map((_ca, i) => (
            <div key={i} className="margin-top-5x">
              <span className="txt-capitalize">{_ca} profile: </span>
              <a className="txt-bold" href={getExternalUrlFromSocialAccount(creator?.connected_accounts[_ca], _ca)}>
                {getExternalUrlFromSocialAccount(creator?.connected_accounts[_ca], _ca)}
              </a>
            </div>
          ))}
          {creator?.phone_number && data?.me?.brand ? (
            <div className="margin-top-5x">
              You can also call this number:{" "}
              <a href={`tel:${creator.phone_number}`} className="txt-bold">
                {creator?.phone_number}
              </a>
            </div>
          ) : null}
          {creator?.address && data?.me?.brand ? (
            <div className="margin-top-5x">
              If you want to send any gift, you can send it to this address:
              <br /> <span className="txt-bold">{creator?.address}</span>
            </div>
          ) : null}
        </div>
      ),
    })
  }
  function archiveCreator(creator) {
    return dialog({
      title: "Remove creator",
      body: (
        <div className="txt-center body-regular-font margin-y-5x margin-x-5x">
          Removing this creator will remove all of it's contents from your dashboard. Please confirm this is what you
          would like to do. Also please note: if the creator posts about you in the future again, the creator will
          re-appear.
        </div>
      ),
      action: <ArchiveCreatorAction />,
    })
  }

  function SearchSettings() {
    return dialog({
      title: "Search settings",
      body: (
        <div className="txt-left margin-y-5x margin-x-5x flex column a-center">
          <p className="nc75-fg">it can take up to 5 minutes for the changes to take effect</p>
        </div>
      ),
    })
  }

  function EmailSent() {
    return dialog({
      title: "Email sent",
      body: (
        <div className="txt-left margin-y-5x margin-x-5x flex column a-center">
          <p className="nc75-fg">Your email has been successfully sent to the selected creators</p>
        </div>
      ),
    })
  }

  function unlockFullExperience() {
    return dialog({
      title: "Unlock Full experience",
      body: <UnlockFullExperience />,
      action: <UnlockFullAction />,
      paddingY: 0,
    })
  }
  function ConfirmCreatorContent(campaign_creator_info) {
    return dialog({
      body: (
        <div>
          <p>This is a preview of the email the creator will receive as the confirmation of the contract:</p>
          <Card className="margin-top-3x body-regular-font padding-5x nc100-fg">
            <br />
            <p>Dear {campaign_creator_info?.username},</p>
            <br />
            <p>
              {data?.me?.brand?.name} has invited you to create content for their campaign –{" "}
              <strong className="body-regular-highlight">{data?.selected_campaign_for_viewing.name}</strong>.
              <br />
              <br />
              As agreed, you will receive{" "}
              <strong className="body-regular-highlight">
                $
                {cents2USD(
                  Number(data?.current_creator_payout_table?.[campaign_creator_info?.creator]?.cost_in_usd ?? 0) * 100,
                )}
              </strong>{" "}
              for your contributions, which will be paid out via the account you linked to Zelf. Looking forward to
              having you as part of this campaign!
              <br />
              <br />
              The zelf. team.
            </p>
            <br />
          </Card>
        </div>
      ),
      action: <ConfirmCreatorCampaignPopupAction campaign_creator_info={campaign_creator_info} />,
      title: "Preview Contract",
      align: "left",

      paddingY: 3,
    })
  }

  function addContentUrlToCampaign(campaign_creator_info, index, current_creator_campaign_content) {
    return dialog({
      body: (
        <AddUrlContentToCampaignForm
          campaign_creator_info={campaign_creator_info}
          array_index={index}
          old_value={current_creator_campaign_content}
        />
      ),
      action: (
        <AddUrlContentToCampaignAction
          campaign_creator_info={campaign_creator_info}
          array_index={index}
          old_value={current_creator_campaign_content}
        />
      ),
      title: "Add content url",
      align: "left",
      forceDialogActionClassName: "cs-dialog-footer flex padding-2x nc00-bg ",
      additionalContentClass: "nc02-bg padding-x-9x",
      additionalBodyClass: "max-width-400-important",
      forceinnerContentBodyClassName: "",
      paddingY: 3,
    })
  }

  function ConnectTiktokForLikeComment(campaign_creator_info) {
    return dialog({
      body: (
        <div>To like or comment on a TikTok video directly from Zelf, you need to connect your TikTok account.</div>
      ),
      action: <ConnectPlatform platform="tiktok" />,
      title: "Connect Tiktok",

      paddingY: 3,
    })
  }

  function deleteSubBrand() {
    return dialog({
      title: "Delete page",
      body: (
        <p className="padding-x-10x padding-y-10x">
          Are you sure you want to delete the page? Once deleted you won’t be able to recover it.
        </p>
      ),
      action: <DeleteSubBrand />,
      paddingY: 0,
    })
  }

  function updateBrandMotto(contentPayload) {
    return dialog({
      body: <AIBrandChatSettings />,
      title: "Set your brand tone",
      action: <UpdateBrandAISettingsAction contentPayload={contentPayload} />,
      additionalBodyClass: "max-width-400-important",
      paddingY: 0,
    })
  }

  function playWithGPT(contentPayload, originalContentPayload) {
    return dialog({
      body: <PlayWithGPT contentPayload={contentPayload} originalContentPayload={originalContentPayload} />,
      title: "Set your brand tone",
      additionalBodyClass: "min-width-940-important",
      paddingY: 0,
      additionalContentClass: "scrollable-dialog-content",
    })
  }

  function connectTikAPI() {
    return dialog({
      title: "Connect Tiktok",
      body: (
        <div>
          You already have a Tiktok account connected, this will remove the previous connection, do you want to continue
          ?
        </div>
      ),
      action: (
        <Button onClick={() => connectTikapi()} className="margin-left">
          Yes, connect Tiktok
        </Button>
      ),
      additionalBodyClass: "max-width-400-important",
    })
  }

  function createNewSpace() {
    return dialog({
      title: "Create new Space",
      body: <SelfServePopupHolder />,
      paddingY: 2,
      additionalBodyClass: "min-width-816-important",
      hideFooter: true,
      align: "left",
      additionalContentClass:
        "scrollable-dialog-content border-radius-bottom-right-8 border-radius-bottom-left-8 nc00-fg",
    })
  }

  function removeFromCreatorCircle(creator__user_id) {
    return dialog({
      title: "Remove creator from creator ecosystem",
      body: (
        <>
          This creator won't appear again in your ecosystem.
          <br />
          <br /> Are you sure you want to remove this creator?
        </>
      ),
      action: <RemoveCreatorFromEcosystem creator_user_id={creator__user_id} />,
    })
  }

  function EditIssuesAndSentiment(contentPayload, contentStats) {
    return dialog({
      body: <EditIssuesAndSentimentBody contentPayload={contentPayload} contentStats={contentStats} />,
      title: "Edit Issues and Sentiment",
      additionalBodyClass: "min-width-940-important",
      paddingY: 0,
      additionalContentClass: "scrollable-dialog-content",
      action: <UpdateIssuesAndSentimentAction contentPayload={contentPayload} />,
    })
  }

  return {
    searchComingSoon,
    comingSoon,
    failedConnect,
    prohibitedUse,
    cartAdded,
    paymentError,
    deferContentDelivery,
    systemErr,
    whyAdManagerID,
    deferWhitelisting,
    alertBeforeIGConnect,
    alertBeforeTTConnect,
    GraphRequirements,
    comingSoonDialog,
    formFieldMissing,
    GuestCheckoutDialog,
    emailCreatorsInCircle,
    createNewCampaign,
    addCreatorsToCampaign,
    invalidWebsiteURL,
    dismiss,
    emptyPrice,
    copyBrandInviteToCreatorLink,
    confirmBoosting,
    inviteSentConfirmation,
    addCreatorsInDraftCampaignDirectly,
    messageCreator,
    createOrEditBoard,
    deleteBoard,
    ContactCreator,
    SearchSettings,
    unlockFullExperience,
    ConfirmCreatorContent,
    addContentUrlToCampaign,
    archiveCreator,
    ConnectTiktokForLikeComment,
    deleteSubBrand,
    EmailSent,
    updateBrandMotto,
    playWithGPT,
    connectTikAPI,
    createNewSpace,
    removeFromCreatorCircle,
    EditIssuesAndSentiment,
  }
}

export { useSystemAlerts }
