import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { hotTextbox, formatDate, getDaysCountBetweenDates } from "../helpers/utils"
import { useDebounce } from "../hooks/helperHooks"
import dayjs from "dayjs"
import quarterOfYear from "dayjs/plugin/quarterOfYear"

import { CalendarIcon } from "./Icons"
import { Button } from "./button"
import { Select } from "./select"

dayjs.extend(quarterOfYear)

const Input = ({
  type = "text",
  placeholder,
  value,
  onChange,
  label,
  subheader,
  firstIconElement,
  secondIconElement,
  beforeIconElement,
  error,
  className = "",
  onKeyUp,
  onClick,
  initFocusState = "none",
  inputElementClass = "button-medium-font",
  onSecondIconClick,
  secondIconClasses,
  onBlur,
  ...rest
}) => {
  const iRef = useRef(null)
  useEffect(() => {
    if (iRef.current) {
      switch (initFocusState) {
        case "focus":
          iRef.current.focus()
          break
        case "select":
          hotTextbox(iRef.current)
          break
        default:
      }
    }
  }, [])
  return (
    <label className={`input-container ${className}`} onClick={onClick}>
      {label ? <span className="input-header">{label}</span> : null}
      <label className="input-wrapper ">
        {beforeIconElement ? beforeIconElement : null}
        <input
          ref={iRef}
          className={`input-element ${inputElementClass} ${error ? "error" : ""} ${firstIconElement && secondIconElement ? "extra-padding-for-two-icons" : firstIconElement || secondIconElement ? "extra-padding-for-one-icon" : ""} `}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          {...rest}
        />
        {firstIconElement || secondIconElement ? (
          <div className="input-icon-group-wrapper">
            <div className="input-icon-group">
              {firstIconElement ? (
                <span className="input-icon-container icon-container-medium">{firstIconElement}</span>
              ) : null}
              {secondIconElement ? (
                <span
                  onClick={onSecondIconClick}
                  className={`input-icon-container icon-container-medium ${secondIconClasses}`}
                >
                  {secondIconElement}
                </span>
              ) : null}
            </div>
          </div>
        ) : null}
      </label>
      {subheader ? <span className="input-subheader">{subheader}</span> : null}
    </label>
  )
}

Input.propTypes = {
  type: PropTypes.oneOf(["text", "number", "password", "date", "email", "url"]),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  subheader: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  className: PropTypes.string,
  firstIconElement: PropTypes.node,
  secondIconElement: PropTypes.node,
}

const defaultStyle = {
  display: "block",
  overflow: "hidden",
  resize: "none",
  width: "100%",
}

const AutoHeightTextarea = ({ value, className = "", ...etc }) => {
  const textareaRef = useRef(null)

  useEffect(() => {
    textareaRef.current.style.height = "0px"
    const scrollHeight = textareaRef.current.scrollHeight
    textareaRef.current.style.height = scrollHeight + "px"
  }, [value])

  return <textarea ref={textareaRef} className={`auto-resize-text-area-input ${className}`} value={value} {...etc} />
}

const TextArea = ({ initialValue, className = "", onChange, enableDebounce = false, debounceTime = 1000, ...etc }) => {
  const [value, setValue] = useState(initialValue)
  const debouncedValue = useDebounce(value, debounceTime)

  const handleChange = (e) => {
    setValue(e.target.value)
    if (!enableDebounce) {
      onChange(e.target.value)
    }
  }
  useEffect(() => {
    if (enableDebounce) {
      // console.log(debouncedValue);
      onChange(debouncedValue)
    }
  }, [debouncedValue])

  return <textarea className={`text-area-input ${className}`} value={value} onChange={handleChange} {...etc} />
}

function isValidDate(dateString) {
  // Try to create a new Date object with the given string
  const date = new Date(dateString)

  // Check if the date is valid by comparing it to an invalid date
  return !isNaN(date.getTime())
}

const DatePicker = ({
  className = "",
  noBorder = false,
  initialValue,
  value,
  format = "YYYY-MM-DD", // using dayjs format
  onChange,
  max,
  ...rest
}) => {
  const inputRef = useRef(null)
  const [val, setVal] = useState(initialValue)

  // const handleBlur = (e) => {
  // 	const date = formatDate(new Date(e.target.value), format);
  // 	setVal(date);
  // 	onChange?.(date);
  // }

  const handleOnChange = (e) => {
    const d = e.target.value
    if (!d) return
    if (!isValidDate(d)) return
    // const date = new Date(d);
    // console.log(d)
    // setVal(date);
    onChange?.(d)
  }

  useEffect(() => {
    setVal(value)
  }, [value])

  return (
    <input
      ref={inputRef}
      className={`padding-y-2x min-width-170 border-radius-4 button-medium-font padding-x-3x flex a-center j-center ${noBorder ? "border-none-important" : "border-1 nc10-border"} ${className}`}
      required
      onClick={(e) => inputRef.current && inputRef.current?.showPicker()}
      onKeyDown={(e) => e.preventDefault()}
      type="date"
      value={val} // convert to date format for input
      onChange={handleOnChange}
      max={max} // convert to date format for input
      {...rest}
    />
  )

  // return (
  //   <label className={`nc00-bg border-1 nc10-border cursor-pointer flex a-center j-space-between padding-y-3x padding-x-4x min-width-170 line-height-3 position-relative border-radius-4 ${className}`} >

  //     <input

  //       // ref={inputRef}
  //       className="native-date-picker"
  //       type="date"
  //       value={val} // convert to date format for input
  //       onChange={handleOnChange}
  //       max={max} // convert to date format for input
  //       {...rest}
  //     />
  //     <span className="">{val?.toString()}</span>
  //     <span className="flex-shrink-0 nc50-fg">{CalendarIcon}</span>
  //   </label>
  // );
}

const DateRangePicker = ({
  className = "",
  format = "YYYY-MM-DD", // using dayjs format
  fromDate = dayjs().format("YYYY-MM-DD"), // today
  toDate = dayjs().format("YYYY-MM-DD"),
  onChange,
  maxFromDate = dayjs().format("YYYY-MM-DD"), // today
  maxToDate = dayjs().format("YYYY-MM-DD"),
  autoApply = false,
}) => {
  const [from, setFrom] = useState(fromDate)
  const [to, setTo] = useState(toDate)
  const [maxFrom, setMaxFrom] = useState(maxFromDate)

  const handleFromChange = (val) => {
    const date = formatDate(val, format)
    setFrom(date)
  }
  const handleToChange = (val) => {
    const date = formatDate(val, format)
    if (dayjs(date).isBefore(dayjs(from))) {
      setFrom(date)
    }
    setMaxFrom(formatDate(date, format))
    setTo(date)
  }

  useEffect(() => {
    if (!autoApply) return
    onChange?.(from, to)
  }, [from, to])

  const onApply = () => {
    onChange?.(from, to)
  }

  return (
    <div className={`flex gap-3 a-center headline-small-highlight ${className}`}>
      <span className="nc50-fg">From</span>
      <DatePicker value={from} onChange={handleFromChange} format={format} max={maxFrom} />
      <span className="nc50-fg">To</span>
      <DatePicker value={to} onChange={handleToChange} format={format} max={maxToDate} />
      {autoApply ? null : (
        <Button type="ghost" onClick={onApply}>
          {" "}
          Apply
        </Button>
      )}
    </div>
  )
}

const MegaDateRangePicker = ({
  className = "",
  format = "YYYY-MM-DD", // using dayjs format
  fromDate = dayjs().format("YYYY-MM-DD"), // today
  toDate = dayjs().format("YYYY-MM-DD"),
  onChange,
  onPeriodChange,
  maxFromDate = dayjs().format("YYYY-MM-DD"), // today
  maxToDate = dayjs().format("YYYY-MM-DD"),
  showPeriod = false,
}) => {
  const [selectedRange, setSelectedRange] = useState("last-30-days")
  const [selectedPeriod, setSelectedPeriod] = useState("day")
  const [from, setFrom] = useState(fromDate)
  const [to, setTo] = useState(toDate)
  const [maxFrom, setMaxFrom] = useState(maxFromDate)

  const handleFromChange = (val) => {
    const date = formatDate(val, format)
    if (selectedRange !== "custom") {
      setSelectedRange("custom")
    }
    setFrom(date)
  }
  const handleToChange = (val) => {
    const date = formatDate(val, format)
    if (selectedRange !== "custom") {
      setSelectedRange("custom")
    }
    if (dayjs(date).isBefore(dayjs(from))) {
      setFrom(date)
    }
    setMaxFrom(formatDate(date, format))
    setTo(date)
  }

  const handleSelectChange = (e) => {
    const value = e.target.value
    const today = dayjs().format("YYYY-MM-DD")
    setSelectedRange(value)
    switch (value) {
      case "today":
        setFrom(today)
        setTo(today)
        break
      case "yesterday":
        setFrom(dayjs(today).subtract(1, "day").format("YYYY-MM-DD"))
        setTo(dayjs(today).subtract(1, "day").format("YYYY-MM-DD"))
        break
      case "week":
        setFrom(dayjs(today).startOf("week").format("YYYY-MM-DD"))
        setTo(dayjs(today).endOf("week").format("YYYY-MM-DD"))
        break
      case "last-7-days":
        setFrom(dayjs(today).subtract(7, "day").format("YYYY-MM-DD"))
        setTo(today)
        break
      case "last-week":
        setFrom(dayjs(today).subtract(1, "week").startOf("week").format("YYYY-MM-DD"))
        setTo(dayjs(today).subtract(1, "week").endOf("week").format("YYYY-MM-DD"))
        break
      case "month-to-date":
        setFrom(dayjs(today).startOf("month").format("YYYY-MM-DD"))
        setTo(today)
        break
      case "last-30-days":
        setFrom(dayjs(today).subtract(30, "day").format("YYYY-MM-DD"))
        setTo(today)
        break
      case "last-month":
        setFrom(dayjs(today).subtract(1, "month").startOf("month").format("YYYY-MM-DD"))
        setTo(dayjs(today).subtract(1, "month").endOf("month").format("YYYY-MM-DD"))
        break
      case "quarter-to-date":
        setFrom(dayjs(today).startOf("quarter").format("YYYY-MM-DD"))
        setTo(today)
        break
      case "last-90-days":
        setFrom(dayjs(today).subtract(90, "day").format("YYYY-MM-DD"))
        setTo(today)
        break
      case "last-quarter":
        setFrom(dayjs(today).subtract(1, "quarter").startOf("quarter").format("YYYY-MM-DD"))
        setTo(dayjs(today).subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD"))
        break
      case "custom":
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (selectedRange !== "custom") {
      onChange?.(from, to)
    }
  }, [from, to, selectedRange])

  useEffect(() => {
    onPeriodChange?.(selectedPeriod)
  }, [selectedPeriod])

  const onApply = () => {
    onChange?.(from, to)
  }

  const days = getDaysCountBetweenDates(from, to)
  // console.log(days)

  return (
    <div className={`border-1 nc10-border border-radius-4 nc00-bg flex a-center headline-small-regular ${className}`}>
      <Select
        presentationalBitClass="padding-y-2x-important padding-right-2x-important min-width-170"
        noBorder
        value={selectedRange}
        onChange={handleSelectChange}
      >
        <option value="today">Today</option>
        <option value="yesterday">Yesterday</option>
        <option disabled>-------</option>
        <option value="week">This week</option>
        <option value="last-7-days">Last 7 days</option>
        <option value="last-week">Last week</option>
        <option disabled>-------</option>
        <option value="month-to-date">Month to date</option>
        <option value="last-30-days">Last 30 days</option>
        <option value="last-month">Last month</option>
        <option disabled>-------</option>
        <option value="quarter-to-date">Quarter to date</option>
        <option value="last-90-days">Last 90 days</option>
        <option value="last-quarter">Last quarter</option>
        <option disabled>-------</option>
        <option value="custom">Custom</option>
      </Select>
      <div className="cs-dynamic-separator margin-right-3x"></div>
      <span className="nc50-fg">from</span>
      <DatePicker
        className="date-input-hide-icon min-width-124-important padding-right-2x-important"
        value={from}
        onChange={handleFromChange}
        format={format}
        max={maxFrom}
        noBorder
      />
      <div className="cs-dynamic-separator margin-right-3x"></div>
      <span className="nc50-fg">to</span>
      <DatePicker
        className="date-input-hide-icon min-width-124-important padding-right-2x-important"
        value={to}
        onChange={handleToChange}
        format={format}
        max={maxToDate}
        noBorder
      />
      {showPeriod && (
        <>
          <div className="cs-dynamic-separator"></div>
          <Select
            presentationalBitClass="padding-top-2x-important padding-bottom-2x-important"
            noBorder
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
          >
            {/* <option value="hour">By Hour</option> */}
            <option value="day">By Day</option>
            <option value="week">By Week</option>
            <option value="month" disabled={days < 31}>
              By Month
            </option>
            {/* <option value="quarter">By Quarter</option> */}
            <option value="year" disabled={days < 365}>
              By Year
            </option>
          </Select>
        </>
      )}
      <div className="cs-dynamic-separator"></div>
      <Button type="ghost" onClick={onApply} disabled={selectedRange !== "custom"}>
        Apply
      </Button>
    </div>
  )
}

const OtpInput = ({ length = 6, onChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""))

  const handleChange = (value, index) => {
    const newOtp = [...otp]
    newOtp[index] = value.slice(-1) // Take only the last character
    setOtp(newOtp)
    onChange && onChange(newOtp.join(""))

    // Move to next input field
    if (value && index < length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus()
    }
  }

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      document.getElementById(`otp-input-${index - 1}`).focus()
    }
  }

  const handlePaste = (e) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData("text").slice(0, length) // Limit to OTP length
    const newOtp = [...otp]
    for (let i = 0; i < pasteData.length; i++) {
      newOtp[i] = pasteData[i]
    }
    setOtp(newOtp)
    onChange && onChange(newOtp.join(""))

    // Set focus to the next empty input (if any)
    const nextEmptyIndex = pasteData.length < length ? pasteData.length : length - 1
    document.getElementById(`otp-input-${nextEmptyIndex}`).focus()
  }

  return (
    <div className="otp-input-container">
      {otp.map((value, index) => (
        <input
          key={index}
          id={`otp-input-${index}`}
          type="text"
          // placeholder="x"

          value={value}
          maxLength="1"
          className="otp-input button-medium-font"
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleBackspace(e, index)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  )
}

export { Input, AutoHeightTextarea, TextArea, DatePicker, DateRangePicker, MegaDateRangePicker, OtpInput }
