import React, { useContext, useEffect, useState, useRef } from "react"
import { apiContext } from "../../../contexts/apicontext"
import { dataContext } from "../../../contexts/datacontext"
import { useLoading } from "../../../contexts/loaderContext"
import {
  cents2USD,
  num2Hip,
  convertToPercentage,
  convertToNumber,
  prettyCent2USD,
  _nopropagate,
  getISODateBeforeDays,
  formatDate,
  _empty,
  getDaysCountBetweenDates,
} from "../../../helpers/utils"

import { useBDBoundData, useBDPostData, useLocalBoundData } from "../../../hooks/useBoundData"
import { useToast } from "../../../hooks/useToast"
import { Button } from "../../button"
import { EcoSystemThumbnail } from "../../contentThumbnail"
import { CreatorCard, CreatorCardMini, CreatorCardTiny } from "../../creatorCard"
import {
  WrappedInLoader,
  Card,
  QuantityPicker,
  PlatformIcon,
  StatBlock,
  CommonContentStats,
  ExpandButtonInline,
  Collapsible,
  YoutubeDataAggregationWarning,
} from "../../dumbs"
import {
  DownloadLargeOutlinedMono,
  LeftArrow,
  TrashLargeFilledMono,
  PlaneSmallFilledMono,
  UploadIcon,
  CheckSmallFilledMono,
  TimesLargeFilledMono,
  CheckLargeFilledMono,
  PlusLargeFilledMono,
  PlayMediumFilledMono,
  ThreeDots,
  Email,
  PlaneLargeFilledMono,
  Lock,
  ArchiveLargeMonoOutline,
  PlusMediumFilledMono,
  PersonRunningIcon,
} from "../../Icons"
import { DateRangePicker, Input } from "../../input"
import { FullLoader, Loader } from "../../loaders"
import { ContentShowingSelect, Select } from "../../select"
import { Toggle } from "../../toggle"
import { CampaignPopup, useCampaignPopupHelper } from "./campaignPopup"
import { CampaignSummary } from "./singleCampaign"
import { ScrollSpy } from "../../scrollspy"
import { useModalHelper } from "../../dialogs/creatorContentModal"
import dayjs from "dayjs"
import { BoardSummary } from "./singleBoard"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { IfGuest, IfNotGuest } from "../../functional/auth_components"
import { useAuthHelper } from "../../../hooks/useAuthHelper"
import {
  CreatorFilter,
  ContentFilter,
  DiscoveryPlatformFilter,
  CreatorBoardFilterValues,
} from "../contentAndCreatorFilter"
import { DEFAULT_CREATOR_FILTER_TIMEFRAME, DISCOVERY_POST_SECTION_CONFIG, NEW_CREATOR_TIMEFRAME } from "../../../consts"
import { useCampaignHelpers, useNavigateToCreatorProfile } from "../../../hooks/helperHooks"
import { GET_CALLS } from "../../../consts"
import { CreateInsight } from "./inslightGeneration"
function PlainSectionSubtitle({ children }) {
  return <span className="headline-small-regular nc30-fg margin-left">{children}</span>
}

// [Default: impact, Options:impact,views,total_engagement,engagement_rate,total_posts ]

const SORT_BY_CREATOR = [
  { title: "Impact", value: "impact" },
  { title: "Views", value: "views" },
  { title: "Total engagement", value: "total_engagement" },
  { title: "Engagement Rate", value: "engagement_rate" },
  { title: "Total posts", value: "total_posts" },
  { title: "Has not posted", value: "last_content_not_posted_timeframe" },
]

const SORT_BY_CONTENT = [
  { title: "EMV", value: "emv_in_cents" },
  { title: "Views", value: "views" },
  { title: "Total engagement", value: "total_engagement" },
  { title: "Engagement Rate", value: "engagement_rate" },
  { title: "Time", value: "timestamp" },
]
//[-1: AllTime, 30: 30Days, 60: 60Days, 365: 1 Year, 7: 7Day, 1: 1Day/24hrs]
const TIME_FRAME_WITHOUT_ALL_TIME = [
  { title: "24 hours", value: "1" },
  { title: "7 days", value: "7" },
  { title: "30 days", value: "30" },
  { title: "60 days", value: "60" },
  { title: "365 days", value: "365" },
]

const TIME_FRAME = [...TIME_FRAME_WITHOUT_ALL_TIME, { title: "All time", value: "-1" }]

function SubtitleCount({ section, prefix = "", postfix = "", hip = true, brackets = true }) {
  const [data] = useContext(dataContext)
  const content = data?.[`bd_sec_${section}_raw`]?.total_items
  if (!content) return null
  ;<PlainSectionSubtitle>
    {brackets ? "(" : ""} {prefix ? `${prefix} ` : ""} {hip ? num2Hip(content) : content} {postfix ? ` ${postfix}` : ""}{" "}
    {brackets ? ")" : ""}
  </PlainSectionSubtitle>
}
function useCommonFeatures(section, cutoffAt) {
  const [data, dispatch] = useContext(dataContext)
  const isViewAllMode = !cutoffAt || (data?.be_section_view_all && data?.be_section_view_all === section)
  const cutoff = (items) => {
    if (!items?.length) return []
    if (isViewAllMode) return items
    return items.slice(0, cutoffAt)
  }
  const hasVisual = !data?.be_section_view_all || data?.be_section_view_all === section
  return {
    cutoff,
    hasVisual,
    isViewAllMode,
  }
}
function TitleSection({
  useDateRange = false,
  title,
  children,
  forceViewAllMode = false,
  showViewAll = true,
  viewAllText,
  className = "",
  filters = [],
  section = "",
  sorts = null,
  timeFrame = null,
  sortAndTimeFrame,
  setSortAndTimeFrame,
  showDiscoveryPlatform,
  showFilter = false,
  showContentCountInTitle = false,
  pagination,
  onViewLessClick,
  customViewAllAction,
}) {
  const [data, dispatch] = useContext(dataContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const api = useContext(apiContext)
  const changeFilters = (slug, value) => {
    const newFilters = {
      ...sortAndTimeFrame,
    }
    if (value) {
      newFilters[slug] = value
    } else {
      delete newFilters[slug]
    }
    setSortAndTimeFrame(newFilters)
  }
  const viewAll = () => {
    if (customViewAllAction) {
      customViewAllAction()
      return
    }
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set("be_section_view_all", section)
    setSearchParams(newSearchParams)
  }
  const viewLess = () => {
    console.log("view less clicks")
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete("be_section_view_all")
    onViewLessClick && onViewLessClick()
    setSearchParams(newSearchParams)
  }
  const isViewAllMode = data.be_section_view_all === section

  const onCreateNewCampaignClick = () => {
    console.log("creating new campaign ")
    api?.alert?.createNewCampaign()
  }
  useEffect(() => {}, [sortAndTimeFrame, data?.discovery_section_platform])

  return (
    <div className={`be-list-section-header flex vcenter margin-bottom-5x ${className}`}>
      <div className="full be-list-header-left-section flex vcenter">
        <div className={`be-list-header-title headline-medium-highlight nc100-fg flex vcenter margin-right-2x  `}>
          <>
            {isViewAllMode && !forceViewAllMode ? (
              <div onClick={viewLess} className="margin-right-2x cursor-pointer nc50-fg">
                {LeftArrow}
              </div>
            ) : null}
            {isViewAllMode && !forceViewAllMode ? (
              <span className="cursor-pointer" onClick={viewLess}>
                {title}
              </span>
            ) : (
              <span>{title}</span>
            )}
          </>
        </div>
        {(sorts && isViewAllMode && showFilter) || forceViewAllMode ? (
          <>
            {" "}
            <span className="nc50-fg margin-left-3x">by</span>{" "}
            <Select
              type="content"
              styleType="capsule"
              onChange={(e) => changeFilters("sort_by", e.target.value)}
              value={sortAndTimeFrame["sort_by"] ?? ""}
              className="sort-filter margin-x-2x flex-shrink-0"
            >
              {sorts?.map((_srt) => (
                <option key={_srt?.value} value={_srt?.value}>
                  {_srt?.title}
                </option>
              ))}
            </Select>{" "}
          </>
        ) : null}

        {!useDateRange && ((timeFrame && isViewAllMode && showFilter) || forceViewAllMode) && (
          <>
            {" "}
            <span className="nc50-fg ">
              {" "}
              {sortAndTimeFrame["sort_by"] === "last_content_not_posted_timeframe" ? (
                <>in</>
              ) : (
                <>of {sortAndTimeFrame["timeframe"] === "-1" ? "" : "last"}</>
              )}
            </span>{" "}
            <Select
              type="content"
              styleType="capsule"
              onChange={(e) => changeFilters("timeframe", e.target.value)}
              value={sortAndTimeFrame["timeframe"] ?? ""}
              className="sort-filter margin-x-2x"
            >
              {timeFrame?.map((_srt) => (
                <option key={_srt?.value} value={_srt?.value}>
                  {_srt?.title}
                </option>
              ))}
            </Select>{" "}
          </>
        )}
        {isViewAllMode && useDateRange && !_empty(sortAndTimeFrame) && (
          <>
            <DateRangePicker
              fromDate={sortAndTimeFrame?.post_date__gte}
              toDate={sortAndTimeFrame?.post_date__lte}
              onChange={(from, to) => {
                setSortAndTimeFrame({
                  ...sortAndTimeFrame,
                  post_date__gte: from,
                  post_date__lte: to,
                })
              }}
            />
          </>
        )}

        {children || showContentCountInTitle ? (
          <div className="be-list-extra-section">
            {children}
            {showContentCountInTitle ? (
              <span className="headline-small-regular nc30-fg margin-left">
                {pagination?.total_items ? `(${pagination?.total_items} posts)` : null}
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
      {showViewAll && !isViewAllMode ? (
        <Button type="ghost" onClick={viewAll}>
          {viewAllText}
        </Button>
      ) : isViewAllMode && showDiscoveryPlatform ? (
        <DiscoveryPlatformFilter />
      ) : null}
    </div>
  )
}
function BECreatorList({
  section,
  className,
  children,
  title,
  cutoffAt = 0,
  sorts = null,
  cardMode = "normal",
  mini = false,
  polling = false,
  onlyWhenData = false,
  showFilter = false,
  forceViewAllButton = false,
  guestBlurAfter = 5,
  showTailIcon,
  onTailIconClick,
  useTimeframeStats = false,
  showStats,
  triggerReload = [],
  itemFilterFunction,
  skipBrandRefresh = false,
  skipDiscoveryPlatformRefresh = false,
  skipSettingQueryFilter = false,
  showDiscoveryPlatform = true,
  hideCreatorTimeFramedRow = false,
}) {
  const [data, dispatch] = useContext(dataContext)
  const [filterQueryParam, setFilterQueryParam] = useState("")
  const { cutoff, hasVisual, isViewAllMode } = useCommonFeatures(section, cutoffAt)
  const { loader, payload, pagination, pollingStatus } = useBDBoundData({
    section: !showFilter || filterQueryParam ? section : "",
    polling: polling,
    queryFilter: filterQueryParam,
    skipBrandRefresh: skipBrandRefresh,
    skipDiscoveryPlatformRefresh: skipDiscoveryPlatformRefresh,
  })
  const { loader: stats_loader, payload: content_stats_payload } = useBDBoundData({
    section: (!showFilter || filterQueryParam) && isViewAllMode ? "creator_stats" : "",
    polling,
    triggerReload,
    queryFilter: filterQueryParam,
    skipBrandRefresh: skipBrandRefresh,
    skipDiscoveryPlatformRefresh: skipDiscoveryPlatformRefresh,
  })
  const [detailOpen, sDO] = useState(false)
  const navigate = useNavigate()
  const { isGuest } = useAuthHelper()
  const api = useContext(apiContext)
  const [sortAndTimeFrame, setSortAndTimeFrame] = useState({})
  const [filters, setFilters] = useState({})
  const toast = useToast()
  const showProfile = useNavigateToCreatorProfile()

  let filtered_payload = itemFilterFunction && Array.isArray(payload) ? payload?.filter(itemFilterFunction) : payload
  const onApply = () => {
    if (skipSettingQueryFilter) return
    let _filters = {
      ...sortAndTimeFrame,
      ...filters,
    }
    if (showFilter && !Object.keys(_filters).length) {
      return
    }
    if (_filters["sort_by"] === "last_content_not_posted_timeframe") {
      _filters["sort_by"] = "total_posts"
      _filters["last_content_not_posted_timeframe"] = _filters["timeframe"]
      delete _filters["timeframe"]
    }
    if (_filters?.engagement_rate_for_brand__gte) {
      _filters.engagement_rate_for_brand__gte = Number(_filters.engagement_rate_for_brand__gte) / 100
    }
    if (filters?.is_influencer !== undefined) {
      // 1 is influencer, 0 is not influencer, null is all
      if (filters.is_influencer == 1) {
        _filters.is_influencer = true
      } else if (filters.is_influencer == 0) {
        _filters.is_influencer = false
      } else {
        delete _filters.is_influencer
      }
    }
    // last_content_not_posted_timeframe
    if (_filters["board_filter"]) {
      _filters = {
        ..._filters,
        ...CreatorBoardFilterValues[_filters["board_filter"]].filter,
      }
    }
    delete _filters["board_filter"]

    _filters.fe_section = section
    let _filterQuery = new URLSearchParams(_filters).toString()
    dispatch({
      [`bd_sec_${section}_page`]: null,
      ["bd_sec_" + section]: null,
      ["bd_sec_" + section + "_raw"]: null,
    })

    if (filterQueryParam === _filterQuery) {
      api.brand.dashboardSection({ section: section, filter: _filterQuery })
    }
    setFilterQueryParam(_filterQuery)
  }

  useEffect(() => {
    if (section === "new_creators_by_brand") {
      const day = Number(NEW_CREATOR_TIMEFRAME)
      const isoString = getISODateBeforeDays(day)
      setSortAndTimeFrame({
        first_content_scraped_for_brand__gte: isoString,
        sort_by: "first_content_scraped_for_brand",
      })
    } else if (section === "top_creators_by_brand") {
      setSortAndTimeFrame({
        sort_by: "views",
        timeframe: DEFAULT_CREATOR_FILTER_TIMEFRAME,
      })
      setFilters({
        ...filters,
        views_for_brand__gte: "20000",
      })
    } else if (section === "all_creators_by_brand") {
      setSortAndTimeFrame({
        sort_by: "views",
        timeframe: DEFAULT_CREATOR_FILTER_TIMEFRAME,
      })
    } else if (section === "influencers_by_brand") {
      setSortAndTimeFrame({
        sort_by: "views",
        timeframe: DEFAULT_CREATOR_FILTER_TIMEFRAME,
      })
      setFilters({
        ...filters,
        is_influencer: true,
      })
    }
  }, [])

  useEffect(() => {
    onApply()
  }, [sortAndTimeFrame])

  useEffect(() => {
    if (sortAndTimeFrame?.sort_by === "last_content_not_posted_timeframe" && sortAndTimeFrame?.timeframe === "-1") {
      setSortAndTimeFrame((s) => {
        return { ...s, timeframe: "7" }
      })
    }
  }, [sortAndTimeFrame?.sort_by])

  const onBoardClick = async (board) => {
    const urlParams = new URLSearchParams(filterQueryParam ?? "")
    const _filters = Object.fromEntries(urlParams.entries())
    if (data?.sub_brand_id) {
      _filters.brand_id = data?.sub_brand_id
    }
    let _filterQuery = new URLSearchParams(_filters).toString()
    const res = await api.common.postRequestHelper({
      endpoint: `/SocialAccount/board_bulk/?${_filterQuery}`,
      section: section + "_social_account",
      payload: { board: board.id, for_brand: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id },
      dataContextPrefix: "bd_sec_",
      loaders: ["bd-section-default", `bd-section-${section}`, section + "_social_account"],
      requestType: "post",
    })
    if (res) {
      toast(`${res?.count ?? "Filtered"} creators added to ${board?.name ? board.name : "Board"}.`)
    }
  }

  if (!hasVisual) return null
  if (onlyWhenData && !filtered_payload?.length) return null

  let metricsTitle = "views"
  let totalMetricKey = "total_views"
  let timeFrameMetricKey = `total_views_last_${sortAndTimeFrame?.timeframe ?? 60}_days`

  switch (sortAndTimeFrame?.sort_by) {
    case "impact":
      metricsTitle = "impact"
      totalMetricKey = "total_impact"
      timeFrameMetricKey = `total_impact_last_${sortAndTimeFrame?.timeframe ?? 60}_days`
      break
    case "total_engagement":
      metricsTitle = "engagement"
      totalMetricKey = "total_engagement"
      timeFrameMetricKey = `total_engagement_last_${sortAndTimeFrame?.timeframe ?? 60}_days`
      break
    case "engagement_rate":
      metricsTitle = "engagement rate"
      totalMetricKey = "engagement_rate"
      timeFrameMetricKey = `avg_engagement_rate_last${sortAndTimeFrame?.timeframe ?? 60}_days`
      break
  }

  return (
    <div
      className={`brand-ecosystem-creator-list brand-creator-list-${section} ${className} ${isGuest ? "guest-mode-list" : ""} guest-blur-after-${guestBlurAfter}`}
    >
      {title ? (
        <TitleSection
          section={section}
          showFilter={showFilter}
          sorts={section === "new_creators_by_brand" ? null : SORT_BY_CREATOR}
          timeFrame={
            section === "new_creators_by_brand"
              ? null
              : sortAndTimeFrame?.sort_by === "last_content_not_posted_timeframe"
                ? TIME_FRAME_WITHOUT_ALL_TIME
                : TIME_FRAME
          }
          showViewAll={forceViewAllButton || (cutoffAt > 0 && filtered_payload?.length > cutoffAt)}
          viewAllText={"VIEW ALL"}
          showDiscoveryPlatform={showDiscoveryPlatform}
          sortAndTimeFrame={sortAndTimeFrame}
          setSortAndTimeFrame={setSortAndTimeFrame}
          title={title}
        >
          {children}
        </TitleSection>
      ) : null}
      {showFilter && isViewAllMode ? (
        <div className="flex a-center j-center margin-bottom-4x margin-top-10x">
          <CreatorFilter
            sorts={
              !(section === "new_creators_by_brand")
                ? null
                : [...SORT_BY_CREATOR, { title: "Time", value: "first_content_scraped_for_brand" }]
            }
            onApply={onApply}
            filters={filters}
            setFilters={setFilters}
            sortAndTimeFrame={sortAndTimeFrame}
            onBoardClick={onBoardClick}
            setSortAndTimeFrame={setSortAndTimeFrame}
          />
        </div>
      ) : null}

      {showStats && isViewAllMode ? (
        <Card className={` margin-y-5x`}>
          <WrappedInLoader loader={stats_loader} data={content_stats_payload}>
            <PublicVSOwnedStats
              isYoutube={data?.discovery_section_platform === "youtube"}
              payload={content_stats_payload}
              timeFrameString=""
              audienceSummaryText={`${title ?? "Contents"} summary`}
              skipOwnedSummary
              skipInfluencerSummery
            />
            {/* <div className="flex j-end a-center padding-5x">
                    {!loader || !payload ? <ExpandButtonInline className="" trigger={detailOpen} onClick={() => sDO(!detailOpen)}/> : null}
                </div>
                
                <Collapsible expanded={detailOpen}>
                    <CreatorOverviewSection guestBlurAfter={10} section={detailOpen && filterQueryParam ?section:null} queryFilter={filterQueryParam}/>
                </Collapsible> */}
          </WrappedInLoader>
        </Card>
      ) : null}
      <WrappedInLoader
        data={filtered_payload}
        loader={loader || (polling && !filtered_payload?.length && pollingStatus !== "end")}
        silent={data[`bd_sec_${section}_page`] > 1}
        noContentText="No creators found"
      >
        <div className={mini === false ? "be-list-section-creator-list" : "be-list-section-creator-list-mini"}>
          {cutoff(filtered_payload)?.map?.((_creator) => {
            return (
              <React.Fragment key={_creator.uuid ?? _creator.id}>
                {mini === false ? (
                  <CreatorCard
                    mode={cardMode}
                    onClick={() =>
                      showProfile(
                        _creator,
                        _creator?.for_brand
                          ? _creator?.for_brand === data?.me?.brand?.id
                            ? null
                            : _creator?.for_brand
                          : null,
                      )
                    }
                    creator={_creator}
                  />
                ) : (
                  <CreatorCardMini
                    creator={_creator}
                    totalPostKey={section === "all_circle_creators" ? "posts_about" : "total_posts"}
                    timeFramePostKey={`total_posts_last_${sortAndTimeFrame?.timeframe ?? 60}_days`}
                    metricsTitle={metricsTitle}
                    totalMetricsKey={totalMetricKey}
                    timeFrameMetricsKey={timeFrameMetricKey}
                    timeFrame={sortAndTimeFrame?.timeframe > 0 ? sortAndTimeFrame?.timeframe : 60}
                    onClick={() =>
                      showProfile(
                        _creator,
                        _creator?.for_brand
                          ? _creator?.for_brand === data?.me?.brand?.id
                            ? null
                            : _creator?.for_brand
                          : null,
                      )
                    }
                    isCreatorCircle={section === "all_circle_creators" || hideCreatorTimeFramedRow}
                    showTailIcon={showTailIcon}
                    onTailIconClick={() => onTailIconClick(_creator)}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
        <IfNotGuest>
          {pagination?.hasNext && !polling && isViewAllMode ? (
            <ScrollSpy
              rebindTrigger={data[`bd_sec_${section}_raw`]?.pagination?.next}
              loader={loader}
              onEngage={pagination.loadNext}
            />
          ) : null}
        </IfNotGuest>
      </WrappedInLoader>
    </div>
  )
}

function BEContentList({
  section,
  className,
  children,
  title,
  cutoffAt = 0,
  sorts = null,
  polling = false,
  item_modifier_func = (x) => x,
  force_show = false,
  guestBlurAfter = 5,
  onlyWhenData = false,
  triggerReload = [],
  showFilter = false,
  forceViewAllButton = false,
  showStats = false,
  additionalFilters = {},
}) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const [filterQueryParam, setFilterQueryParam] = useState("")
  const {
    loader,
    payload: original_payload,
    pagination,
    pollingStatus,
  } = useBDBoundData({
    section: !showFilter || filterQueryParam ? section : "",
    polling: polling,
    triggerReloadBy: triggerReload,
    queryFilter: filterQueryParam,
  })
  const { cutoff, hasVisual, isViewAllMode } = useCommonFeatures(section, cutoffAt)
  const { loader: stats_loader, payload: content_stats_payload } = useBDBoundData({
    section: (!showFilter || filterQueryParam) && isViewAllMode ? "content_stats" : "",
    polling,
    triggerReload,
    queryFilter: filterQueryParam,
  })
  const [detailOpen, sDO] = useState(false)
  const showModal = useModalHelper()
  const [sortAndTimeFrame, setSortAndTimeFrame] = useState({})
  const [filters, setFilters] = useState({})
  const { isGuest } = useAuthHelper()
  const modified_payload = original_payload?.map(item_modifier_func)

  // TODO : @Farhat
  // make it use the useFilteredContent hook

  const onApply = () => {
    const _filters = {
      ...sortAndTimeFrame,
      ...filters,
      ...additionalFilters,
    }
    if (showFilter && !Object.keys(_filters).length) {
      return
    }
    dispatch({
      [`bd_sec_${section}_page`]: null,
      ["bd_sec_" + section]: null,
      ["bd_sec_" + section + "_raw"]: null,
    })
    if (_filters?.engagement_rate__gte) {
      _filters.engagement_rate__gte = Number(_filters.engagement_rate__gte) / 100
    }
    if (_filters?.is_not_influencer_content) {
      _filters.is_influencer_content = false
      delete _filters.is_not_influencer_content
    }

    if (_filters?.exc_comment_mentioned && section == "all_posts") {
      _filters.is_tagged_post = true
    }
    _filters.fe_section = section
    let _filterQuery = new URLSearchParams(_filters).toString()

    if (filterQueryParam === _filterQuery) {
      api.brand.dashboardSection({ section: section, filter: _filterQuery })
    }
    setFilterQueryParam(_filterQuery)
  }

  useEffect(() => {
    if (DISCOVERY_POST_SECTION_CONFIG[section]?.filters) {
      setFilters(DISCOVERY_POST_SECTION_CONFIG[section]?.filters)
    }

    if (DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame) {
      setSortAndTimeFrame(DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame)
    }
  }, [data?.sub_brand_id])

  useEffect(() => {
    if (!showFilter) return
    onApply()
  }, [sortAndTimeFrame, data?.sub_brand_id])

  if (onlyWhenData && loader) {
    return <FullLoader />
  }

  if (onlyWhenData && data?.be_section_view_all !== section && !modified_payload?.length) return null

  if (!hasVisual && !force_show) return null
  return (
    <div
      className={`brand-ecosystem-content-list brand-content-list-${section} ${className} ${isGuest ? "guest-mode-list" : ""} guest-blur-after-${guestBlurAfter}`}
    >
      {title ? (
        <TitleSection
          section={section}
          showViewAll={forceViewAllButton || (cutoffAt > 0 && modified_payload?.length > cutoffAt)}
          showDiscoveryPlatform={true}
          sorts={!showFilter ? null : SORT_BY_CONTENT}
          timeFrame={!showFilter ? null : TIME_FRAME}
          showFilter={showFilter}
          viewAllText="VIEW ALL"
          sortAndTimeFrame={sortAndTimeFrame}
          setSortAndTimeFrame={setSortAndTimeFrame}
          title={title}
        >
          {children}
        </TitleSection>
      ) : null}

      {showFilter && isViewAllMode ? (
        <div className="flex a-center j-center margin-bottom-4x margin-top-10x">
          <ContentFilter
            preAppliedFilters={DISCOVERY_POST_SECTION_CONFIG[section]?.filters}
            showPostOnlyFiler={section === "all_posts"}
            onApply={onApply}
            filters={filters}
            setFilters={setFilters}
            sortAndTimeFrame={sortAndTimeFrame}
            setSortAndTimeFrame={setSortAndTimeFrame}
          />
        </div>
      ) : null}

      {showStats && isViewAllMode ? (
        <Card className={` margin-y-5x`}>
          <WrappedInLoader loader={stats_loader} data={content_stats_payload}>
            <PublicVSOwnedStats
              isYoutube={data?.discovery_section_platform === "youtube"}
              payload={content_stats_payload}
              timeFrameString=""
              audienceSummaryText={`${title ?? "Contents"} summary`}
              skipOwnedSummary
              skipInfluencerSummery
            />
            <div className="flex j-end a-center padding-5x">
              {!loader || !modified_payload ? (
                <ExpandButtonInline className="" trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
              ) : null}
            </div>

            <Collapsible expanded={detailOpen}>
              {/* using general content for the timebeing, gonna remove the content compeletly */}
              <ContentOverviewSection
                guestBlurAfter={10}
                section={detailOpen && filterQueryParam ? "general_contents" : null}
                queryFilter={filterQueryParam}
              />
            </Collapsible>
          </WrappedInLoader>
        </Card>
      ) : null}
      <WrappedInLoader
        data={modified_payload}
        loader={loader || (polling && !modified_payload?.length && pollingStatus !== "end")}
        silent={data[`bd_sec_${section}_page`] > 1}
        noContentText="No contents found"
      >
        <div className="be-list-section-content-list">
          {cutoff(modified_payload)?.map?.((_item, _idx) => (
            <EcoSystemThumbnail
              onClick={() => showModal(_item, cutoff(modified_payload), _idx, section)}
              key={_item.content.uuid}
              payload={_item}
              hideThumbNPSIcon={data?.me?.brand?.settings?.is_political_dashboard_enabled}
            />
          ))}
        </div>
        <IfNotGuest>
          {pagination?.hasNext && !polling && isViewAllMode ? (
            <ScrollSpy
              rebindTrigger={data[`bd_sec_${section}_raw`]?.pagination?.next}
              loader={loader}
              onEngage={pagination.loadNext}
            />
          ) : null}
        </IfNotGuest>
      </WrappedInLoader>
    </div>
  )
}

function BELocalContentList({
  section,
  className,
  children,
  title,
  cutoffAt = 0,
  item_modifier_func = (x) => x,
  force_show = false,
  guestBlurAfter = 5,
  onlyWhenData = false,
  showFilter = false,
  forceViewAllButton = false,
  showStats = false,
  additionalFilters = {},
  customSectionDetailsOverwrite = {}, // overwrite endpoint and other details
  showContentCountInTitle = false,
  itemFilterFunction,
  brandId,
  customViewAllAction,
  viewAllCustomSectionName,
  skipSubBrand,
  skipPlatform,
  waitTillMainFilterSet = false,
  fromDate,
  toDate,
  showCreateInsight = false,
  hideFilterUI = false,
  hideTableDetails = false,
}) {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const [filterQueryParam, setFilterQueryParam] = useState("")
  const _filterObject = filterQueryParam
    ? { ...Object.fromEntries(new URLSearchParams(filterQueryParam).entries()) }
    : {}
  const {
    loader,
    payload: original_payload,
    pagination,
    refetch: refetchContent,
  } = useLocalBoundData({
    ...GET_CALLS?.[section],
    skip: !(!showFilter || filterQueryParam),
    filter: _filterObject,
    sub_brand_id: skipSubBrand ? null : brandId ? brandId : data?.sub_brand_id,
    platform: skipPlatform ? null : (data?.discovery_section_platform ?? "tiktok"),
    ...customSectionDetailsOverwrite,
  })
  const view_all_section = viewAllCustomSectionName ?? section
  const { cutoff, hasVisual, isViewAllMode } = useCommonFeatures(view_all_section, cutoffAt)
  const {
    loader: stats_loader,
    payload: content_stats_payload,
    refetch: refetchStats,
  } = useLocalBoundData({
    ...GET_CALLS.content_stats,
    skip: !((!showFilter || filterQueryParam) && isViewAllMode),
    filter: _filterObject,
    sub_brand_id: skipSubBrand ? null : brandId ? brandId : data?.sub_brand_id,
    platform: skipPlatform ? null : (data?.discovery_section_platform ?? "tiktok"),
  })
  const [detailOpen, sDO] = useState(false)
  const showModal = useModalHelper()
  const [internalSortAndTimeFrame, setInternalSortAndTimeFrame] = useState({})
  const [internalFilter, setInternalFilter] = useState({})
  const { isGuest } = useAuthHelper()
  let filtered_payload =
    itemFilterFunction && Array.isArray(original_payload)
      ? original_payload?.filter(itemFilterFunction)
      : original_payload
  const modified_payload = filtered_payload?.map(item_modifier_func)

  const filters = internalFilter
  const setFilters = setInternalFilter
  const sortAndTimeFrame = internalSortAndTimeFrame
  const setSortAndTimeFrame = setInternalSortAndTimeFrame

  const need_refresh_name = `need_refresh_${section}`

  const onApply = () => {
    if (waitTillMainFilterSet) {
      if (!Object.keys(sortAndTimeFrame).length && !Object.keys(filters).length) {
        return
      }
    }

    let _filters = {
      ...sortAndTimeFrame,
      ...filters,
      ...additionalFilters,
    }
    if (showFilter && !Object.keys(_filters).length) {
      return
    }
    dispatch({
      [`bd_sec_${section}_page`]: null,
      ["bd_sec_" + section]: null,
      ["bd_sec_" + section + "_raw"]: null,
    })
    if (_filters?.engagement_rate__gte) {
      _filters.engagement_rate__gte = Number(_filters.engagement_rate__gte) / 100
    }
    if (_filters?.is_not_influencer_content) {
      _filters.is_influencer_content = false
      delete _filters.is_not_influencer_content
    }

    if (_filters?.exc_comment_mentioned && section == "all_posts") {
      _filters.is_tagged_post = true
    }
    if (sortAndTimeFrame?.sort_by) {
      _filters.sort_by = sortAndTimeFrame?.sort_by
    }
    if (_filters?.is_owned_by_brand) {
      delete _filters?.mention_type__not
    }
    _filters.fe_section = section
    let _filterQuery = new URLSearchParams(_filters).toString()

    if (filterQueryParam === _filterQuery) {
      // api.brand.dashboardSection({section:section,filter:_filterQuery})
      refetchContent()
      refetchStats()
    }
    setFilterQueryParam(_filterQuery)
  }

  const setDefaultFilterForSpecificSection = () => {
    if (DISCOVERY_POST_SECTION_CONFIG[section]?.filters) {
      setFilters(DISCOVERY_POST_SECTION_CONFIG[section]?.filters)
    }

    if (DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame) {
      setSortAndTimeFrame(DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame)
    }
  }

  useEffect(() => {
    setDefaultFilterForSpecificSection()
  }, [section])

  useEffect(() => {
    if (fromDate && toDate) {
      setSortAndTimeFrame((prev) => {
        return {
          ...prev,
          post_date__gte: fromDate,
          post_date__lte: toDate,
        }
      })
    }
  }, [fromDate, toDate])

  useEffect(() => {
    if (!showFilter) return
    onApply()
  }, [sortAndTimeFrame])

  useEffect(() => {
    if (data?.[need_refresh_name]) {
      refetchContent()
      refetchStats()
      dispatch({
        [need_refresh_name]: false,
      })
    }
  }, [data?.[need_refresh_name]])

  if (onlyWhenData && data?.be_section_view_all !== view_all_section && !modified_payload?.length) return null

  if (!hasVisual && !force_show) return null
  return (
    <div
      className={`brand-ecosystem-content-list brand-content-list-${section} ${className} ${isGuest ? "guest-mode-list" : ""} guest-blur-after-${guestBlurAfter}`}
    >
      {title ? (
        <TitleSection
          section={view_all_section}
          onViewLessClick={setDefaultFilterForSpecificSection}
          showViewAll={forceViewAllButton || (cutoffAt > 0 && modified_payload?.length > cutoffAt)}
          showDiscoveryPlatform={true}
          sorts={!showFilter ? null : SORT_BY_CONTENT}
          timeFrame={!showFilter ? null : TIME_FRAME}
          showFilter={showFilter}
          customViewAllAction={customViewAllAction}
          viewAllText="VIEW ALL"
          sortAndTimeFrame={sortAndTimeFrame}
          setSortAndTimeFrame={setSortAndTimeFrame}
          pagination={pagination}
          showContentCountInTitle={showContentCountInTitle}
          title={title}
          useDateRange={fromDate && toDate}
        >
          {children}
        </TitleSection>
      ) : null}

      {showFilter && isViewAllMode && !hideFilterUI ? (
        <div className="flex a-center j-center margin-bottom-4x margin-top-10x">
          <ContentFilter
            preAppliedFilters={DISCOVERY_POST_SECTION_CONFIG[section]?.filters}
            showPostOnlyFiler={section === "all_posts"}
            onApply={onApply}
            filters={filters}
            setFilters={setFilters}
            sortAndTimeFrame={sortAndTimeFrame}
            setSortAndTimeFrame={setSortAndTimeFrame}
          />
        </div>
      ) : null}

      {showStats && isViewAllMode ? (
        <div className="flex gap-2">
          <Card className={`full margin-y-5x`}>
            <WrappedInLoader loader={stats_loader} data={content_stats_payload}>
              <PublicVSOwnedStats
                isYoutube={data?.discovery_section_platform === "youtube"}
                payload={content_stats_payload}
                timeFrameString=""
                audienceSummaryText={`${title ?? "Contents"} summary`}
                skipOwnedSummary
                skipInfluencerSummery
              />
              {showCreateInsight || hideTableDetails ? null : (
                <>
                  <div className="flex j-end a-center padding-5x">
                    {!loader || !modified_payload ? (
                      <ExpandButtonInline className="" trigger={detailOpen} onClick={() => sDO(!detailOpen)} />
                    ) : null}
                  </div>

                  {showCreateInsight || hideTableDetails ? null : (
                    <Collapsible expanded={detailOpen}>
                      {
                        <ContentOverviewSection
                          guestBlurAfter={10}
                          section={detailOpen && filterQueryParam ? section : null}
                          queryFilter={filterQueryParam}
                        />
                      }
                    </Collapsible>
                  )}
                </>
              )}
            </WrappedInLoader>
          </Card>
          {showCreateInsight ? (
            <CreateInsight
              filter={!data?.sub_brand_id ? _filterObject : { ..._filterObject, brand_id: data?.sub_brand_id }}
              className="full margin-y-5x"
              stats={content_stats_payload}
            />
          ) : null}
        </div>
      ) : null}
      <WrappedInLoader
        data={modified_payload}
        loader={loader}
        silent={pagination?.page >= 1}
        noContentText="No contents found"
      >
        <div className="be-list-section-content-list">
          {cutoff(modified_payload)?.map?.((_item, _idx) => (
            <EcoSystemThumbnail
              onClick={() => showModal(_item, cutoff(modified_payload), _idx, section)}
              key={_idx}
              payload={_item}
              hideThumbNPSIcon={
                data?.me?.brand?.settings?.is_political_dashboard_enabled ||
                data?.me?.brand?.settings?.is_political_engage_enabled
              }
            />
          ))}
        </div>
        <IfNotGuest>
          {pagination?.hasNext && isViewAllMode ? (
            <ScrollSpy rebindTrigger={pagination?.next} loader={loader} onEngage={pagination.loadNext} />
          ) : null}
        </IfNotGuest>
      </WrappedInLoader>
    </div>
  )
}

function BECampaignList({
  section = "campaign_detail_list",
  className,
  children,
  title,
  cutoffAt = 0,
  sorts = null,
  filters = [],
  polling = false,
}) {
  const [data, dispatch] = useContext(dataContext)
  const [isArchive, setIsArchive] = useState(false)
  const [clientSideArchiveOrRestore, setClientSideArchiveOrRestore] = useState({})
  const api = useContext(apiContext)
  const { loader, payload, pagination, pollingStatus } = useLocalBoundData({
    ...GET_CALLS[section],
    filter: { is_archived: isArchive },
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const { setCampaignQueryParam } = useCampaignHelpers()
  const location = useLocation()
  const navigate = useNavigate()
  const toast = useToast()

  const onCampaignClick = (_item) => {
    dispatch({ selected_campaign_for_viewing: _item })
    const newPath = `${location.pathname}/${_item?.id}`
    navigate(newPath)
  }

  const onArchiveClick = (campaign) => {
    toast(`${campaign?.name} was moved to ${!isArchive ? "Archive" : "Active"} `)
    setClientSideArchiveOrRestore((s) => {
      return { ...s, [campaign?.id]: true }
    })
  }

  const onCreateNewCampaignClick = () => {
    console.log("creating new campaign ")
    api?.alert?.createNewCampaign()
  }

  useEffect(() => {
    setClientSideArchiveOrRestore({})
  }, [isArchive])
  const _payload = payload?.filter((x) => !clientSideArchiveOrRestore?.[x?.id])
  return (
    <div className={`brand-ecosystem-campaign-list brand-content-list-${section} ${className}`}>
      <div className="flex j-space-between a-center margin-bottom-5x">
        <h3 className="headline-large flex a-center">
          Creator Campaigns
          <Button
            className="margin-left"
            type="transparent"
            size="medium"
            onClick={onCreateNewCampaignClick}
            beforeElement={<div className="primary-fg flex a-center j-center ">{PlusMediumFilledMono}</div>}
          >
            <span className="primary-fg">Create new campaign</span>
          </Button>
        </h3>
        <div className="flex a-center">
          <Button
            className="capsule-button"
            type="tertiary"
            size="large"
            onClick={() => setIsArchive(false)}
            beforeElement={<div className="nc100-fg flex a-center j-center ">{PersonRunningIcon}</div>}
          >
            <span className={`${isArchive ? "nc100-fg" : "primary-fg"}`}>Active Campaigns</span>
          </Button>
          <p className="button-small-font margin-x-3x nc30-fg">/</p>
          <Button
            className="capsule-button"
            type="tertiary"
            size="large"
            onClick={() => setIsArchive(true)}
            beforeElement={<div className="nc100-fg flex a-center j-center ">{ArchiveLargeMonoOutline}</div>}
          >
            <span className={`${!isArchive ? "nc100-fg" : "primary-fg"}`}>Archived</span>
          </Button>
        </div>
      </div>
      <WrappedInLoader
        data={payload}
        loader={loader || (polling && !payload?.length && pollingStatus !== "end")}
        silent={data[`bd_sec_${section}_page`] > 1}
        noContentText="No campaigns found"
      >
        <div className="be-list-section-campaign-list">
          {_payload?.length ? null : (
            <p className="body-regular-font nc50-fg txt-center margin-top-2x">No campaigns found</p>
          )}
          {_payload?.map?.((_item) => (
            <CampaignSummary
              className="margin-bottom-2x"
              onArchiveClick={onArchiveClick}
              showArchiveIcon={!isArchive}
              onClick={() => onCampaignClick(_item)}
              campaign={_item}
              key={_item.uuid}
            />
          ))}
        </div>
        {pagination?.hasNext ? (
          <ScrollSpy rebindTrigger={pagination?.next} loader={loader} onEngage={pagination.loadNext} />
        ) : null}
      </WrappedInLoader>
    </div>
  )
}

function BEBoardList({
  section = "boards",
  className,
  children,
  title,
  cutoffAt = 0,
  type,
  pageTitle,
  filter = {},
  enableHashtagsInAddForm = true,
  enableCreatorsInAddForm = false,
  hideCreateBoard = false,
}) {
  const [data, dispatch] = useContext(dataContext)
  const [isArchive, setIsArchive] = useState(false)
  const { loader, payload, pagination } = useLocalBoundData({
    ...GET_CALLS.board_list,
    filter: {
      ...filter,
      is_archived: isArchive,
    },
  })
  const [clientSideArchiveOrRestore, setClientSideArchiveOrRestore] = useState({})
  const { cutoff, hasVisual, isViewAllMode } = useCommonFeatures(section, cutoffAt)
  const toast = useToast()
  const api = useContext(apiContext)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!hasVisual) return

    setClientSideArchiveOrRestore({})
  }, [isArchive])

  if (!hasVisual) return null
  const openUpNewBoardDialog = () => {
    api?.alert?.createOrEditBoard({
      type,
      enableHashtagsInput: enableHashtagsInAddForm,
      enableCreatorsSelect: enableCreatorsInAddForm,
    })
  }
  const onBoardChange = (_item) => {
    const sections = [
      "selected_board_content",
      "selected_board_content",
      "selected_board_added_creator",
      "selected_board_creator",
    ]
    sections.map(api.brand.resetSection)
    dispatch({ selected_board_for_viewing: _item })
    const newPath = `${location.pathname}/${_item?.id}`
    navigate(newPath)
  }

  const onArchiveClick = (board) => {
    toast(`${board?.name} was moved to ${!isArchive ? "Archive" : "Active"} `)
    setClientSideArchiveOrRestore((s) => {
      return { ...s, [board?.id]: true }
    })
  }
  const _payload = payload?.filter((x) => !clientSideArchiveOrRestore?.[x?.id])

  return (
    <div className={`brand-ecosystem-campaign-list brand-content-list-${section} ${className}`}>
      <div className="flex j-space-between margin-bottom-7x">
        <h3 className="headline-large nc100-fg flex vcenter margin-right-2x ">
          {pageTitle ? pageTitle : "Boards"}
          {Boolean(pagination?.total) && (
            <span className="headline-small-regular nc30-fg margin-left">
              ({pagination.total} {pagination.total > 1 ? "groups" : "group"} total)
            </span>
          )}
        </h3>
        {hideCreateBoard ? null : (
          <Button
            type="ghost"
            size="large"
            className="transparent-bg-override"
            onClick={openUpNewBoardDialog}
            beforeElement={<div className=" flex a-center j-center">{PlusLargeFilledMono}</div>}
          >
            Create new board
          </Button>
        )}
        <div className="flex a-center margin-left-auto">
          <Button
            className="capsule-button"
            type="tertiary"
            size="large"
            onClick={() => setIsArchive(false)}
            beforeElement={<div className="nc100-fg flex a-center j-center ">{PersonRunningIcon}</div>}
          >
            <span className={`${isArchive ? "nc100-fg" : "primary-fg"}`}>Active</span>
          </Button>
          <p className="button-small-font margin-x-3x nc30-fg">/</p>
          <Button
            className="capsule-button"
            type="tertiary"
            size="large"
            onClick={() => setIsArchive(true)}
            beforeElement={<div className="nc100-fg flex a-center j-center ">{ArchiveLargeMonoOutline}</div>}
          >
            <span className={`${!isArchive ? "nc100-fg" : "primary-fg"}`}>Archived</span>
          </Button>
        </div>
      </div>
      <WrappedInLoader data={payload} loader={loader} silent={pagination?.page >= 1} noContentText="No Boards found">
        <div className="be-list-section-campaign-list">
          {cutoff(_payload)?.map?.((_item) => (
            <BoardSummary
              onArchiveClick={onArchiveClick}
              showArchiveIcon={!isArchive}
              key={_item.id}
              className="margin-bottom-2x"
              onClick={() => onBoardChange(_item)}
              board={_item}
            />
          ))}
        </div>
        {pagination?.hasNext ? (
          <ScrollSpy rebindTrigger={pagination?.next} loader={loader} onEngage={pagination.loadNext} />
        ) : null}
      </WrappedInLoader>
    </div>
  )
}

function CreatorSelectList({
  section,
  className = "",
  dataContextCustomSection = "",
  onChange,
  isNotExclusion = false,
  skipCheckbox = false,
  filterFunc = (x) => x,
  keepOldState = false,
  showFollowers = false,
  selectAllDefault = false,
  showEmail = false,
}) {
  const { loader, payload } = useBDBoundData({
    section,
    shallowConnect: true,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
  })
  const [data, dispatch] = useContext(dataContext)
  // const [_internalExclution, _setExclusion] = useState(new Set());

  const internal_section_name = dataContextCustomSection ? dataContextCustomSection : `${section}_creator_select`
  const default_set_variable_name = internal_section_name + "_default_select"
  const internalExclusion = new Set(...[data?.[internal_section_name] ?? []])

  const setExclusion = (exclusion) => {
    dispatch({
      [internal_section_name]: [...exclusion],
    })
  }
  const updateListItems = (e, id) => {
    const newExclusion = new Set([...internalExclusion])
    if (newExclusion.has(id)) newExclusion.delete(id)
    else newExclusion.add(id)
    setExclusion(newExclusion)
    if (isNotExclusion) {
      onChange(payload.filter((e) => newExclusion.has(e.id)))
    } else {
      onChange(payload.filter((e) => !newExclusion.has(e.id)))
    }
  }
  useEffect(() => {
    if (!isNotExclusion) {
      if (payload) onChange(payload)
    }
  }, [payload])

  useEffect(() => {
    if (!keepOldState) {
      dispatch({
        [internal_section_name]: [],
      })
    }
  }, [])
  const _payload = payload?.filter(filterFunc)
  useEffect(() => {
    if (!_payload?.length) return
    if (!selectAllDefault) return
    if (data?.[default_set_variable_name]) {
      return
    }
    dispatch({ [default_set_variable_name]: true })
    const _exlution = new Set(_payload.map((x) => x.id))
    setExclusion(_exlution)
    onChange(_payload.filter((e) => _exlution.has(e.id)))
  }, [_payload, selectAllDefault])

  if (!loader && !_payload?.length)
    return <div className="nc30-fg caption-regular-font padding-3x">{"No creators found"}</div>
  return (
    <div className={`creator-selectable-list ${className}`}>
      <WrappedInLoader data={payload} loader={loader}>
        {_payload?.map((_creator) => (
          <CreatorCardTiny
            showFollowers={showFollowers}
            showEmail={showEmail}
            skipCheckbox={skipCheckbox}
            key={_creator.id}
            creator={_creator}
            value={isNotExclusion ? internalExclusion.has(_creator.id) : !internalExclusion.has(_creator.id)}
            onChange={(e) => updateListItems(e, _creator.id)}
          />
        ))}
      </WrappedInLoader>
    </div>
  )
}

const ListHead = ({ className = "", children, ...props }) => (
  <div
    className={`padding-y-3x padding-x-3x caption-regular-font nc50-fg border-bottom-2 nc05-border flex vcenter ${className}`}
    {...props}
  >
    {children}
  </div>
)

const Column = ({ children, className = "" }) => (
  <div
    className={`flex hcenter overview-section-column vcenter padding-y-3x padding-x-2x nc05-border border-bottom-1 ${className}`}
  >
    {children}
  </div>
)

const InfoRow = ({ creator, mode = "circle", showAddToCampaign = false, showAllStats = false }) => {
  const api = useContext(apiContext)
  const toast = useToast()
  const [data, dispatch] = useContext(dataContext)
  const { loaders } = useLoading()
  const [deleted, setDeleted] = useState(false)
  const [localLoader, setLL] = useState(false)
  const popup = useCampaignPopupHelper()
  const _camp = data?.selected_campaign_for_viewing
  const addToCampaign = async (campaign) => {
    setLL(true)
    const added = api.brand.addCreatorsToCampaign([creator], campaign?.id)
    setLL(false)
    if (added) toast("Added to campaign")
  }
  const deleteAction = async () => {
    let removed = false
    if (_camp) removed = await api.brand.removeCreatorFromCampaign(creator, _camp)
    else removed = await api.brand.removeFromEcosystem(creator)
    if (removed) setDeleted(true)
  }
  const saveDraft = () => {
    dispatch({
      pending_campaign_add: {
        creators: [creator],
      },
    })
  }
  if (deleted) return null
  return (
    <>
      <div className="creator-info overview-section-column flex vcenter padding-y-3x padding-x-2x nc05-border border-bottom-1">
        <img
          className="summary-card-avatar-co margin-right-2x"
          alt={creator?.username}
          src={creator.profile_picture_url}
        />
        <div className="headline-small-highlight">{creator?.username}</div>
      </div>
      <Column>{creator?.content_count ? num2Hip(creator?.content_count) : <span className="nc30-fg">N/A</span>}</Column>
      <Column>{creator?.total_views ? num2Hip(creator?.total_views) : <span className="nc30-fg">N/A</span>}</Column>
      <Column>
        {creator?.total_engagement ? num2Hip(creator?.total_engagement) : <span className="nc30-fg">N/A</span>}
      </Column>
      <Column>
        {creator?.engagement_of_views ? (
          convertToPercentage(creator?.engagement_of_views, 1)
        ) : (
          <span className="nc30-fg">N/A</span>
        )}{" "}
      </Column>
      <Column>
        {creator?.engagement_of_followers ? (
          convertToPercentage(creator?.engagement_of_followers, 1)
        ) : (
          <span className="nc30-fg">N/A</span>
        )}
      </Column>
      {showAddToCampaign || showAllStats ? (
        <>
          <Column>
            {creator?.cost_per_view ? (
              `$${cents2USD(creator?.cost_per_view * 1000, true, 2)}`
            ) : (
              <span className="nc30-fg">N/A</span>
            )}{" "}
          </Column>
          <Column>
            {creator?.cost_per_engagement ? (
              `$${cents2USD(creator?.cost_per_engagement, true, 2)}`
            ) : (
              <span className="nc30-fg">N/A</span>
            )}
          </Column>
          {showAllStats ? null : (
            <>
              <div className="flex vcenter action-button-per-creator primary-fg nc05-border border-bottom-1 position-relative">
                {_camp ? null : (
                  <div className="full txt-right cursor-pointer" onClick={popup.show}>
                    {localLoader ? <loader /> : "Add to campaign"}
                    {popup.shown ? (
                      <CampaignPopup
                        onDismiss={popup.hide}
                        className="right-mounted"
                        onCampaignClick={addToCampaign}
                        onAddNewClick={saveDraft}
                        kind="CREATOR"
                      />
                    ) : null}
                  </div>
                )}
              </div>
              <div className="cursor-pointer delete-button flex hcenter vcenter padding-y-3x padding-x-2x nc50-fg nc05-border border-bottom-1">
                {/* {loaders[`remove-creator-${creator.uuid}-from-circle`] || loaders[`remove-creator-${creator.uuid}-from-campaign`] ? <Loader/> : TrashLargeFilledMono} */}
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  )
}
function CreatorOverviewSection({
  section = "",
  className = "",
  _loader = null,
  _payload = null,
  queryFilter,
  showAddToCampaign,
  showAllStats = false,
}) {
  const { loader, payload, pagination, internalSectionName } = useBDBoundData({ section, queryFilter: queryFilter })
  const [data, dispatch] = useContext(dataContext)
  const finalData = _payload ? _payload : payload
  return (
    <div className={`creator-overview-be border-top-2 nc05-border overview-${section} ${className}`}>
      <WrappedInLoader loader={loader || _loader} data={payload || _payload}>
        <div
          className={`creators-overview-inner txt-center  ${showAllStats ? "stats-without-action" : showAddToCampaign ? "" : "only-basic-info"} `}
        >
          <ListHead>Creator</ListHead>
          <ListHead>Posts total</ListHead>
          <ListHead>Total views</ListHead>
          <ListHead>Total Engagement</ListHead>
          <ListHead className="double-title-co">
            <div className="margin-bottom txt-center">Engagement rate</div>
            <div className="txt-center">Of views</div>
            <div className="txt-center">followers</div>
          </ListHead>
          {showAddToCampaign || showAllStats ? (
            <>
              <ListHead className="">
                <span className="full txt-center">CPM</span>
              </ListHead>
              <ListHead>Cost per engagement</ListHead>
              {showAllStats ? null : (
                <div className="padding-y-3x flex vcenter txt-right padding-x-3x button-medium-font primary-fg download-all-co border-bottom-2 nc05-border cursor-pointer">
                  {/* <div className="download-button-inner-co full flex vcenter">
                        Download
                        {DownloadLargeOutlinedMono}
                    </div> */}
                </div>
              )}
            </>
          ) : null}
          {/* the loop */}
          {finalData?.map((_creator, _id) => (
            <InfoRow showAllStats={showAllStats} creator={_creator} key={_id} showAddToCampaign={showAddToCampaign} />
          ))}
        </div>
        <IfNotGuest>
          {pagination?.hasNext ? (
            <ScrollSpy
              rebindTrigger={data[`bd_sec_${internalSectionName}_raw`]?.pagination?.next}
              loader={loader}
              onEngage={pagination.loadNext}
            />
          ) : null}
        </IfNotGuest>
      </WrappedInLoader>
    </div>
  )
}
function ContentOverviewAction({ content }) {
  const [mnu, setMnu] = useState(false)
  const api = useContext(apiContext)
  const wEvt = useRef(() => setMnu(false))
  const openMenu = (e) => {
    _nopropagate(e)
    window.addEventListener("click", wEvt.current, { once: true })
    setMnu(true)
  }
  useEffect(() => {
    return () => window.removeEventListener("click", wEvt)
  }, [])
  const buttonAction = (evt) => {
    setMnu(false)
    window.removeEventListener("click", wEvt)
    switch (evt) {
      case "invite":
        return api?.alert?.copyBrandInviteToCreatorLink()
      case "message":
        return api?.alert?.messageCreator(content.creator)
      case "addToCampaign":
        return console.log("Add to campaign")
      default:
        console.log("noevent")
    }
  }
  return (
    <div className="content-action-dots cursor-pointer margin-left-auto">
      <div className="hot-area padding flex vcenter" onClickCapture={openMenu}>
        {ThreeDots}
      </div>
      {mnu ? (
        <div
          onClick={_nopropagate}
          className="context-menu-content-action nc00-bg nc10-border border-1 nc75-fg body-regular-font border-radius-4 line-shadow"
        >
          <IfNotGuest>
            <div
              onClick={() => buttonAction("addToCampaign")}
              className={` padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter ${content.creator?.creator_circle ? "" : "action-disabled-faded"}`}
            >
              <span className="margin-right flex a-center j-center">{PlusLargeFilledMono}</span> Add creator to campaign
            </div>
            <div
              onClick={() => buttonAction("invite")}
              className={`padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter ${content.creator?.creator_circle ? "action-disabled-faded" : ""}`}
            >
              <span className="margin-right-2x flex a-center j-center">{PlaneSmallFilledMono}</span> Invite creator to
              zelf.
            </div>
            <div
              onClick={() => buttonAction("message")}
              className="padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter"
            >
              <span className="margin-right-2x flex a-center j-center">{Email}</span> Contact creator
            </div>
          </IfNotGuest>
          <IfGuest>
            <div
              onClick={api?.alert?.unlockFullExperience}
              className={`padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter`}
            >
              <span className="margin-right-2x flex a-center j-center">{Lock}</span> Add creator to campaign
            </div>
            <div
              onClick={api?.alert?.unlockFullExperience}
              className={`padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter`}
            >
              <span className="margin-right-2x flex a-center j-center">{Lock}</span> Invite creator to zelf.
            </div>
            <div
              onClick={api?.alert?.unlockFullExperience}
              className={`padding-x-3x padding-y-2x nc05-bg-onhover flex vcenter`}
            >
              <span className="margin-right-2x flex a-center j-center">{Lock}</span> Contact creator
            </div>
          </IfGuest>
        </div>
      ) : null}
    </div>
  )
}
function ContentOverviewSection({
  fromDate,
  toDate,
  section,
  customSectionDetailsOverwrite = {},
  className = "",
  createEngagementRateClientSideIfMissing = false,
  guestBlurAfter = 5,
  queryFilter = "",
  customFilter = {},
  skipSubBrand = false,
  skipPlatform = false,
}) {
  const queryFilterObj = queryFilter ? Object.fromEntries(new URLSearchParams(queryFilter).entries()) : {}
  if (fromDate && toDate) {
    ;(queryFilterObj.post_date__gte = fromDate), (queryFilterObj.post_date__lte = toDate)
    delete queryFilterObj.time_frame
  }
  const [data, dispatch] = useContext(dataContext)
  const { loader, payload, pagination } = useLocalBoundData({
    ...GET_CALLS?.[section],
    ...customSectionDetailsOverwrite,
    sub_brand_id: skipSubBrand ? null : data?.sub_brand_id,
    platform: skipPlatform ? null : data?.discovery_section_platform,
    filter: { ...queryFilterObj, ...customFilter },
    skip: !section,
  })
  const show = useModalHelper()
  const { isGuest } = useAuthHelper()
  const showProfile = useNavigateToCreatorProfile()
  const openContentModal = (item, payload, index) => show(item, payload, index, section)
  const navigate = useNavigate()

  return (
    <div
      className={`discovery-section section-content-overview ${className} ${isGuest ? "guest-mode-list" : ""} guest-blur-after-${guestBlurAfter}`}
    >
      <WrappedInLoader loader={loader} data={payload} silent={pagination?.page >= 1}>
        <div className="discover-analytics-inner">
          <ListHead className="grid-fixed-header nc00-bg">Date</ListHead>
          <ListHead className="grid-fixed-header nc00-bg">Video</ListHead>
          <ListHead className="padding-x-3x grid-fixed-header nc00-bg">Creator</ListHead>
          <ListHead className="padding-x-2x grid-fixed-header nc00-bg j-center">EMV</ListHead>
          <ListHead className="txt-center flex a-center j-center grid-fixed-header nc00-bg">
            Total
            <br /> views
          </ListHead>
          <ListHead className="txt-center flex a-center j-center grid-fixed-header nc00-bg">
            Total
            <br /> engagement
          </ListHead>
          <ListHead className="txt-center flex a-center j-center grid-fixed-header nc00-bg">
            Engagement <br /> rate
          </ListHead>
          <ListHead className="grid-fixed-header nc00-bg">
            <span className="txt-right full-width">Actions</span>
          </ListHead>
          {payload?.map?.((_item, _idx) => (
            <React.Fragment key={_item?.content?.id}>
              <Column className="caption-highlight-font nc50-fg">
                {dayjs(_item.content.timestamp).format("MMM DD")}
              </Column>
              <div
                onClick={() => openContentModal(_item, payload, _idx)}
                className="item-title cursor-pointer flex vcenter nc05-border border-bottom-1"
              >
                <div className="title-holder nc75-fg flex vcenter padding-x-2x padding-y button-small-font">
                  <span className="primary-fg margin-right flex vcenter">{PlayMediumFilledMono}</span>
                  <span className="hide-extra-text">{_item.content.title}</span>
                </div>
              </div>
              <div
                className="item-creator full-width flex vcenter nc05-border border-bottom-1 padding-x-3x cursor-pointer"
                onClick={() => showProfile(_item?.creator)}
              >
                <img
                  className="summary-section-avatar margin-right-2x"
                  src={_item.creator.profile_picture_url}
                  alt={_item.creator.name}
                />
                <span className="hide-extra-text headline-small-highlight">
                  {_item.creator.username ?? "@" + _item.creator.name}
                </span>
              </div>
              <Column className="padding-x-2x cursor-pointer">
                ${num2Hip(cents2USD(_item.content.emv_in_cents), undefined, undefined)}
              </Column>
              <Column>{num2Hip(_item.content.views)}</Column>
              <Column>{num2Hip(_item.content.total_engagement)}</Column>
              {/* checking required data to prevent divide by zero error */}
              <Column>
                {createEngagementRateClientSideIfMissing &&
                !_item.content.engagement_of_views &&
                _item?.content?.total_engagement &&
                _item?.content.views
                  ? convertToPercentage(_item?.content?.total_engagement / _item?.content.views, 1)
                  : convertToPercentage(_item.content.engagement_of_views, 1)}
              </Column>
              <div className="padding-x-3x flex vcenter nc05-border border-bottom-1">
                <span
                  className="cursor-pointer primary-fg txt-right"
                  onClick={() => openContentModal(_item, payload, _idx)}
                >
                  View post
                </span>
                <ContentOverviewAction content={_item} />
              </div>
            </React.Fragment>
          ))}
        </div>
        <IfNotGuest>
          {pagination?.hasNext ? <ScrollSpy loader={loader} onEngage={pagination.loadNext} /> : null}
        </IfNotGuest>
      </WrappedInLoader>
    </div>
  )
}

const TrendingRankTable = ({ fromDate, toDate, categoryId, subCategoryId, platform }) => {
  const [subSection, setSubSection] = useState("earned_posts")
  const [data, dispatch] = useContext(dataContext)

  const section = `god_view_trending_${subSection}`

  let filter = {
    section: `${section}_rank_table`,
    post_date__gte: fromDate,
    post_date__lte: toDate,
    ...DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame,
  }

  if (subCategoryId) {
    filter.sub_category_id = subCategoryId
  } else if (categoryId) {
    filter.category_id = categoryId
  }

  const { loader, payload, pagination } = useLocalBoundData({
    ...GET_CALLS["god_view_rank_table"],
    filter: {
      ...filter,
    },
    platform: data?.discovery_section_platform,
    skip: platform == "youtube",
  })

  const openContentModal = (item, payload, index) => {
    const data = {
      brand: {
        id: item?.brand_id,
        name: item?.brand_name,
        title: item?.brand_title,
      },
      filters: {
        ...DISCOVERY_POST_SECTION_CONFIG[section]?.sortAndTimeFrame,
        god_view_brand_id_list: item?.brand_id,
      },
      from_date: fromDate,
      to_date: toDate,
    }

    dispatch({
      date_specific_dialog_data: data,
    })
  }

  if (platform == "youtube")
    return (
      <Card className={"circle-stat-block margin-y-3x"}>
        <YoutubeDataAggregationWarning />
      </Card>
    )

  return (
    <Card className={"circle-stat-block margin-y-3x"}>
      <div className="nc10-border border-top-1 border-bottom-1 padding-y-4x padding-x-6x flex vcenter nc75-fg button-medium-font">
        <div
          className={`cursor-pointer ${subSection === "earned_posts" ? "" : "nc30-fg"}`}
          onClick={() => setSubSection("earned_posts")}
        >
          Earned
        </div>
        <div className="nc30-fg margin-x-2x button-regular-font">/</div>
        <div
          className={`cursor-pointer ${subSection === "influencer_posts" ? "" : "nc30-fg"}`}
          onClick={() => setSubSection("influencer_posts")}
        >
          Influencer
        </div>
        <div className="nc30-fg margin-x-2x body-regular-font">/</div>
        <div
          className={`cursor-pointer  ${subSection === "owned_posts" ? "" : "nc30-fg"}`}
          onClick={() => setSubSection("owned_posts")}
        >
          Owned
        </div>
      </div>
      <WrappedInLoader loader={loader} data={payload} silent={pagination?.page >= 1} noContentText="No data found">
        <div className="section-content-overview">
          <div className="discover-analytics-inner trending-analytics-inner">
            <ListHead className="j-center grid-fixed-header nc00-bg">Rank</ListHead>
            <ListHead className="padding-x-2x grid-fixed-header nc00-bg">Brand name</ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">EMV</ListHead>
            {/* <ListHead className="txt-center grid-fixed-header nc00-bg j-center">
              total trending <br /> creators
            </ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              total trending <br /> posts
            </ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              total <br /> creators
            </ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              total <br /> posts
            </ListHead> */}
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              Total <br /> views
            </ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              Total <br /> engs
            </ListHead>
            <ListHead className="txt-center j-center grid-fixed-header nc00-bg">
              Eng <br /> rate
            </ListHead>
            <ListHead className="grid-fixed-header nc00-bg"></ListHead>

            {Array.isArray(payload) &&
              payload?.map?.((_item, _idx) => (
                <React.Fragment key={_idx}>
                  <Column className="caption-highlight-font nc50-fg ">{_idx + 1}</Column>
                  <Column className="block-component headline-small-regular">
                    {_item?.brand_title ?? _item?.brand_name}
                  </Column>
                  <Column className="txt-center headline-small-regular">{num2Hip(_item?.emv, 1, null)}</Column>
                  {/* <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_trending_creators)}</Column>
                <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_trending_contents)}</Column>
                <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_creators)}</Column>
                <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_posts)}</Column> */}
                  <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_views)}</Column>
                  <Column className="txt-center headline-small-regular">{num2Hip(_item?.total_engagement)}</Column>
                  <Column className="txt-center headline-small-regular">
                    {convertToPercentage(_item?.engagement_rate, 1)}
                  </Column>
                  <Column className="padding-x-3x flex vcenter nc05-border border-bottom-1">
                    <span
                      className="cursor-pointer primary-fg txt-right button-medium-font"
                      onClick={() => openContentModal(_item, payload, _idx)}
                    >
                      View posts
                    </span>
                  </Column>
                </React.Fragment>
              ))}
          </div>
          <IfNotGuest>
            {pagination?.hasNext ? <ScrollSpy loader={loader} onEngage={pagination.loadNext} /> : null}
          </IfNotGuest>
        </div>
      </WrappedInLoader>
    </Card>
  )
}

function CreatorRow({ creator, payload = {}, updateCreatorCart, onInvite }) {
  const cost_in_usd = payload?.cost_in_usd
  const content_count = payload?.content_count ?? 1
  const is_posting_enabled = Boolean(payload?.is_posting_enabled)

  const isAlreadyInvited = Boolean(creator?.invitation_sent_at)

  const onQuantityChange = (increment) => {
    let _q = content_count
    if (increment) {
      _q = _q + 1
    } else if (content_count > 1) {
      _q = _q - 1
    }

    updateCreatorCart({ id: creator?.id, newValue: { content_count: _q } })
  }

  const togglePosting = () =>
    updateCreatorCart({ id: creator?.id, newValue: { is_posting_enabled: !is_posting_enabled } })

  return (
    <>
      <div className="no-border a-center j-start flex padding-y-4x  padding-x-4x ">
        <img
          className="summary-card-avatar-co margin-right-2x"
          alt={creator?.username}
          src={creator.profile_picture_url}
        />
        <div className="headline-small-highlight">{creator?.username}</div>
      </div>
      <Column className="border-none-important">
        {creator?.content_count ? (
          <span className="button-small-font">{creator?.content_count}</span>
        ) : (
          <QuantityPicker
            quantity={content_count}
            onIncreament={() => onQuantityChange(true)}
            onDecreament={() => onQuantityChange(false)}
          />
        )}
      </Column>
      <Column className="border-none-important">
        {creator?.cost_in_cents || (isAlreadyInvited && creator?.cost_in_cents == 0) ? (
          <div className="button-large-font">${prettyCent2USD(creator?.cost_in_cents)} </div>
        ) : (
          <Input
            placeholder="$$$"
            value={cost_in_usd ?? ""}
            onChange={(e) => {
              updateCreatorCart({ id: creator?.id, newValue: { cost_in_usd: convertToNumber(e.target.value) } })
            }}
          />
        )}
      </Column>
      <Column className="border-none-important">
        {isAlreadyInvited ? (
          <div className="flex a-center j-center nc50-fg">
            {" "}
            {creator?.is_posting_enabled ? CheckLargeFilledMono : TimesLargeFilledMono}
          </div>
        ) : (
          <Toggle value={is_posting_enabled} onChange={() => togglePosting()} />
        )}
      </Column>
      <Column className="border-none-important">{creator?.email}</Column>
      <div className="padding-x-2x margin-x-auto">
        {isAlreadyInvited ? (
          <Button onClick={onInvite} type={"ghost"} size={"small"} beforeElement={CheckSmallFilledMono}>
            invite sent
          </Button>
        ) : (
          <Button onClick={onInvite} type={"tertiary"} size={"small"} beforeElement={PlaneSmallFilledMono}>
            Send invite
          </Button>
        )}
      </div>

      <hr className="hr full-span-grid-column " />
    </>
  )
}

function PlatformIconFromUrls({ url_object = {} }) {
  return (
    <div className="flex a-center">
      {" "}
      {Object.keys(url_object).map((platform, i) => {
        if (!url_object[platform]) {
          return null
        }
        return (
          <div className="flex a-center j-center margin-right-2x">
            <PlatformIcon platform={platform} color />
          </div>
        )
      })}
    </div>
  )
}

function CreatorPayoutRow({
  creator,
  payload = {},
  updateCreatorCart,
  onConfirmContract,
  onAddUrl,
  content,
  onConfirmPayment,
  customPrice,
}) {
  const cost_in_usd = payload?.cost_in_usd
  const content_count = payload?.content_count ?? 1

  const isAlreadyConfirmed = Boolean(creator?.is_contract_confirmed)
  const isAlreadyPayed = Boolean(content?.is_payment_confirmed)

  const onQuantityChange = (increment) => {
    let _q = content_count
    if (increment) {
      _q = _q + 1
    } else if (content_count > 1) {
      _q = _q - 1
    }

    updateCreatorCart({ id: creator?.creator, newValue: { content_count: _q } })
  }

  return (
    <>
      <div className="no-border a-center j-start flex padding-y-4x  padding-x-4x ">
        <img
          className="summary-card-avatar-co margin-right-2x"
          alt={creator?.username}
          src={creator.profile_picture_url}
        />
        <div className="headline-small-highlight">{creator?.username}</div>
      </div>
      <Column className="border-none-important">
        {creator?.content_count || isAlreadyConfirmed ? (
          <span className="button-small-font primary-fg cursor-pointer flex" onClick={onAddUrl}>
            {Object.keys(content?.content_url ?? {}).length ? (
              <PlatformIconFromUrls url_object={content?.content_url} />
            ) : (
              "Add content URL"
            )}{" "}
          </span>
        ) : (
          <QuantityPicker
            quantity={content_count}
            onIncreament={() => onQuantityChange(true)}
            onDecreament={() => onQuantityChange(false)}
          />
        )}
      </Column>
      <Column className="border-none-important">
        {creator?.cost_in_cents || (isAlreadyConfirmed && creator?.cost_in_cents == 0) ? (
          <div className="button-large-font">${prettyCent2USD(customPrice ?? creator?.cost_in_cents)} </div>
        ) : (
          <Input
            placeholder="$$$"
            value={cost_in_usd ?? ""}
            onChange={(e) => {
              updateCreatorCart({ id: creator?.creator, newValue: { cost_in_usd: convertToNumber(e.target.value) } })
            }}
          />
        )}
      </Column>
      <Column className="border-none-important">{creator?.email}</Column>
      <div className="padding-x-2x margin-x-auto">
        {!isAlreadyConfirmed ? (
          <Button onClick={onConfirmContract} type={"primary"} size={"small"}>
            Confirm Contract
          </Button>
        ) : !isAlreadyPayed ? (
          <Button onClick={onConfirmPayment} type={"secondary"} size={"small"}>
            Confirm Payment
          </Button>
        ) : (
          "Payment Confirmed"
        )}
      </div>
      <hr className="hr full-span-grid-column " />
    </>
  )
}
function CreatorPayoutTableOverviewSection({
  section = "",
  openAddCreatorsDialog,
  className = "",
  _loader = null,
  _payload = null,
}) {
  const api = useContext(apiContext)
  const [data, dispatch] = useContext(dataContext)
  const { loader, payload } = useBDBoundData({ section: "campaign_creators" })
  const { loader: creator_update_post_loader, executeCall: updateCreator } = useBDPostData({
    section: "change_creator_payout_campaign",
  })
  const { loader: contentUpdateLoader, executeCall: updateContent } = useBDPostData({
    section: "add_content_to_campaign",
  })
  const toast = useToast()

  const finalData = payload

  const cartUpdateHelper = ({ id, newValue = {} }) => {
    api.brand.updateManyObjectTypeInDataContent({
      id,
      newValue,
      dataContextVariableName: "current_creator_payout_table",
    })
  }

  const onConfirm = async (creator) => {
    const price = data?.current_creator_payout_table?.[creator?.creator]?.cost_in_usd
    if (price == undefined || price == null || price == "") {
      toast(`Please enter cost`, null)
      return
    }
    if (!creator?.is_contract_confirmed) {
      await api?.alert?.ConfirmCreatorContent(creator)
      return
    }
  }

  const onConfirmPayment = async (campaign_creator_info, content_index, current_content) => {
    await updateContent([
      {
        campaign_creator: campaign_creator_info.id,
        is_payment_confirmed: true,
        array_index: content_index,
        content_url: { ...current_content?.content_url },
      },
    ])
    await api.brand.dashboardSection({ section: "campaign_creators" })
  }

  const onAddUrl = async (campaign_creator_info, index, current_content) => {
    api?.alert?.addContentUrlToCampaign(campaign_creator_info, index, current_content)
  }

  useEffect(() => {
    dispatch({ current_creator_payout_table: null })
  }, [])

  return (
    <div className={`creator-overview-be  overview-${section} ${className}`}>
      <WrappedInLoader loader={loader || _loader} data={payload || _payload}>
        <hr className="hr full-span-grid-column " />
        <div className="creator-invite-table-grid creator-payout-grid">
          <ListHead className="justify-self-start border-none-important a-center j-center">Creator</ListHead>
          <ListHead className="border-none-important j-center">Number of videos</ListHead>
          <ListHead className="border-none-important j-center">Cost</ListHead>
          <ListHead className="border-none-important j-center">email address</ListHead>
          <ListHead className="border-none-important j-center">action</ListHead>
          <hr className="hr full-span-grid-column " />
          {finalData?.map((campaign_creator_info) => {
            const _content_count = campaign_creator_info?.content_count

            if (_content_count) {
              const leaguer = new Array(_content_count).fill(campaign_creator_info)
              const contents = campaign_creator_info?.contents

              const d = leaguer?.map((_content, i) => {
                const possibleArrayIndex = contents[i]?.array_index ?? campaign_creator_info?.contents?.length ?? 0
                return (
                  <CreatorPayoutRow
                    creator={campaign_creator_info}
                    key={campaign_creator_info.id + "c" + i}
                    payload={data?.current_creator_payout_table?.[campaign_creator_info?.creator]}
                    updateCreatorCart={cartUpdateHelper}
                    onConfirmContract={() => onConfirm(campaign_creator_info)}
                    onAddUrl={() => onAddUrl(campaign_creator_info, possibleArrayIndex, contents[i])}
                    content={contents[i]}
                    customPrice={campaign_creator_info?.cost_in_cents / _content_count}
                    onConfirmPayment={() => onConfirmPayment(campaign_creator_info, possibleArrayIndex, contents[i])}
                  />
                )
              })
              return d
            }
            return (
              <CreatorPayoutRow
                creator={campaign_creator_info}
                key={campaign_creator_info.id}
                payload={data?.current_creator_payout_table?.[campaign_creator_info?.creator]}
                updateCreatorCart={cartUpdateHelper}
                onConfirmContract={() => onConfirm(campaign_creator_info)}
              />
            )
          })}
          {/* price and send all button */}
          <div className="full-span-grid-column">
            <div className="margin-x-auto full-width flex j-center padding-y-2x">
              <Button className="" type="ghost" onClick={openAddCreatorsDialog} beforeElement={PlusLargeFilledMono}>
                Add contracts
              </Button>
            </div>
          </div>
        </div>
      </WrappedInLoader>
    </div>
  )
}

const PvOTitleWrapper = ({ title, subTitle, children, className }) => (
  <div className={className}>
    <div>
      <div className="stat-headline margin-bottom-5x margin-top-4x">
        <span className="headline-medium-highlight primary-fg margin-right margin-left">{title}</span>
        <span className="button-small-font nc30-fg">{subTitle}</span>
      </div>
      <div className="creator-circle-stats flex">{children}</div>
    </div>
  </div>
)

function PublicVSOwnedStats({
  fromDate,
  toDate,
  payload,
  influencersPostSection,
  ownedPostsSection,
  skipOwnedSummary = true,
  skipInfluencerSummery = false,
  timeFrameString = "(past 60 days)",
  loader = false,
  audienceSummaryText = "Earned post summary",
  skipCreatorPublic = false,
  isYoutube = false,
  brand_id = null,
}) {
  const [data] = useContext(dataContext)
  const _sub_id = brand_id ?? data?.sub_brand_id

  const dateRangeFilters = fromDate && toDate ? { post_date__gte: fromDate, post_date__lte: toDate } : {}

  const { loader: _owned_summery_loader, payload: _owned_summery_payload } = useLocalBoundData({
    ...GET_CALLS?.[ownedPostsSection],
    filter: {
      ...GET_CALLS?.[ownedPostsSection]?.filter,
      ...dateRangeFilters,
    },
    platform: data?.discovery_section_platform,
    sub_brand_id: _sub_id,
    skip: skipOwnedSummary,
  })
  let { loader: _influencer_loader, payload: _influencer_payload } = useLocalBoundData({
    ...GET_CALLS?.[influencersPostSection],
    filter: {
      ...GET_CALLS?.[influencersPostSection]?.filter,
      ...dateRangeFilters,
    },
    platform: data?.discovery_section_platform,
    sub_brand_id: _sub_id,
    skip: skipInfluencerSummery,
  })
  let [tfLeft, tfRight] = timeFrameString?.split(",")
  if (!tfRight) tfRight = tfLeft
  const gap = skipInfluencerSummery ? 4 : 2
  _influencer_payload = _influencer_payload?.total_posts === 0 ? null : _influencer_payload
  if (isYoutube) {
    return <YoutubeDataAggregationWarning />
  }
  return (
    <>
      <div className="creator-circle-actions flex j-space-between a-center   margin-x-5x">
        {!skipOwnedSummary && (
          <>
            {_owned_summery_loader ? (
              <FullLoader loader={_owned_summery_loader} payload={_owned_summery_payload} />
            ) : (
              <PvOTitleWrapper
                className={` padding-x-4x padding-top-4x padding-bottom-7x `}
                title={`Owned post summary`}
                subTitle={tfRight}
              >
                <CommonContentStats
                  showEngagementRateByFollower={
                    data.discovery_section_platform === "instagram" && data?.discovery_section_content_form === "IMAGE"
                  }
                  data={_owned_summery_payload}
                  gap={gap}
                  globalSeparator={false}
                />
              </PvOTitleWrapper>
            )}
          </>
        )}

        {skipInfluencerSummery ? null : (
          <>
            <div className="vertical-divider nc05-bg"></div>
            {_influencer_loader ? (
              <FullLoader loader={_influencer_loader} payload={_influencer_payload} />
            ) : (
              <PvOTitleWrapper
                className={` padding-x-4x padding-top-4x padding-bottom-7x  `}
                title={"Influencer summary"}
                subTitle={tfRight}
              >
                <CommonContentStats
                  showEngagementRateByFollower={
                    data.discovery_section_platform === "instagram" && data?.discovery_section_content_form === "IMAGE"
                  }
                  data={_influencer_payload}
                  gap={gap}
                  globalSeparator={false}
                />
              </PvOTitleWrapper>
            )}
          </>
        )}

        <>
          {!skipInfluencerSummery && !skipOwnedSummary && <div className="vertical-divider nc05-bg"></div>}
          {loader ? (
            <FullLoader loader={loader} payload={payload} />
          ) : (
            <PvOTitleWrapper
              className={`nc05-border padding-x-4x padding-top-4x padding-bottom-7x `}
              title={audienceSummaryText}
              subTitle={tfLeft}
            >
              {/* {skipCreatorPublic ? null :<StatBlock className={`padding-x-${gap}x`} align="center" separator={false}  title={<>Creators <br/> posted</>} data={payload?.total_creators ?? payload?.total_creators_public}/>} */}
              <CommonContentStats
                skipCreatorPublic={skipCreatorPublic}
                showEngagementRateByFollower={
                  data.discovery_section_platform === "instagram" && data?.discovery_section_content_form === "IMAGE"
                }
                postfixOfDataName="_public"
                data={payload}
                gap={gap}
                globalSeparator={false}
              />
            </PvOTitleWrapper>
          )}
        </>
      </div>
    </>
  )
}

const BeSimpleContentStats = ({
  fromDate,
  toDate,
  title,
  subTitle,
  filters = {},
  platform = "tiktok",
  sub_brand_id = null,
  gap = 2,
  content_form = "VIDEO",
  className = "",
}) => {
  const dateRangeFilters = fromDate && toDate ? { post_date__gte: fromDate, post_date__lte: toDate } : {}
  const { loader: stats_loader, payload: stats_payload } = useLocalBoundData({
    ...GET_CALLS?.content_stats,
    filter: {
      ...filters,
      ...dateRangeFilters,
    },
    platform: platform,
    sub_brand_id: sub_brand_id,
  })
  return (
    <div className={` padding-x-2x padding-top-4x padding-bottom-7x ${className}`}>
      <WrappedInLoader data={stats_payload} loader={stats_loader}>
        <PvOTitleWrapper title={title} subTitle={subTitle}>
          <CommonContentStats
            showEngagementRateByFollower={platform === "instagram" && content_form === "IMAGE"}
            data={stats_payload}
            gap={1}
            padding="padding"
            globalSeparator={false}
            width="min-width-56"
          />
        </PvOTitleWrapper>
      </WrappedInLoader>
    </div>
  )
}

export {
  BEContentList,
  BELocalContentList,
  BECreatorList,
  TitleSection,
  CreatorSelectList,
  BECampaignList,
  SubtitleCount,
  PlainSectionSubtitle,
  CreatorOverviewSection,
  ContentOverviewSection,
  TrendingRankTable,
  PublicVSOwnedStats,
  BEBoardList,
  CreatorPayoutTableOverviewSection,
  BeSimpleContentStats,
}
