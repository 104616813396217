import { useContext, useEffect, useState } from "react"
import { MegaDateRangePicker } from "../input"
import { formatDate, generateDateArray, getSubtractedDate, num2Hip } from "../../helpers/utils"
import { DiscoveryPlatformFilter } from "../structural/contentAndCreatorFilter"
import { dataContext } from "../../contexts/datacontext"
import { Card, WrappedInLoader } from "../dumbs"
import { BarChart, NotEnoughData } from "../ui/graphs"
import { GET_CALLS } from "../../consts"
import { useLocalBoundData } from "../../hooks/useBoundData"

import Chart from "react-apexcharts"

const colors = [
  "#6FAEA9",
  "#556EB0",
  "#5A9FDE",
  "#ECB84E",
  "#E7824B",
  "#BC51AD",
  "#68C769",
  "#A1FDFF",
  "#F35F69",
  "#7A4D4E",
]

const kpmGraphsOptions = {
  dataLabels: {
    offsetY: -20,
    style: {
      fontSize: "10px",
      colors: ["#767B8A"],
    },
    formatter: function (val, opts) {
      return num2Hip(val)
    },
  },
}

const timeSeriesChartOptions = {
  chart: {
    fontFamily: "Poppins",
    height: 350,
    type: "line",
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
  },
  colors: [
    "#2B0236",
    "#451E5D",
    "#634975",
    "#72286F",
    "#934379",
    "#9452A5",
    "#846FAA",
    "#C27AC0",
    "#C89CE4",
    "#DFA8E4",
  ],
  stroke: {
    width: 3, // Adjust the stroke width
    curve: "smooth",
    lineCap: "round", // Make the end of the line rounded
  },
  grid: {
    borderColor: "#e7e7e7",
    row: {
      colors: ["#ffffff"], // takes an array which will be repeated on columns
    },
  },
  xaxis: {
    type: "category",
    labels: {
      // formatter: (value) => {
      //   // return value
      //   // Convert the value (which is a date string) to a Date object
      //   // Format the date using date-fns
      //   return formatDate(value) // or any other format you prefer
      // },
      // rotate: -90,
    },
  },
  yaxis: {
    title: {
      style: {
        fontWeight: 400,
      },
    },
    // min: 5,
    // max: 40
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    // floating: true,
  },
}

const KVMDataParser = (allData = [], fieldName) => {
  if (!allData) return
  const mainBrand = allData?.find((x) => !x.is_sub_brand)

  let _data = mainBrand
    ? [mainBrand, ...allData.filter((x) => x.is_sub_brand)]
    : [...allData.filter((x) => x.is_sub_brand)]

  if (fieldName === "nps") {
    // if (
    //   (!mainBrand?.total_neutral_nps && mainBrand?.total_neutral_nps !== 0) ||
    //   (!mainBrand?.total_positive_nps && mainBrand?.total_positive_nps !== 0) ||
    //   (!mainBrand?.total_negative_nps && mainBrand?.total_negative_nps !== 0)
    // ) {
    //   return null
    // }
    const positive = mainBrand?.total_positive_nps ?? 0
    const negative = mainBrand?.total_negative_nps ?? 0
    const neutral = mainBrand?.total_neutral_nps ?? 0

    const _total = positive + negative + neutral
    if (_total == 0) return null

    let nps = [
      (positive / _total).toFixed(4) * 100,
      (negative / _total).toFixed(4) * 100,
      (neutral / _total).toFixed(4) * 100,
    ]
    // nps = nps.map(x=>x.toFixed(2))
    return nps
  }

  return _data.map((brand) => {
    return {
      x: brand?.brand_title ?? brand?.brand_name,
      y: brand?.[fieldName],
    }
  })
}

const seriesDataParser = ({ data: allData, key = "total_followers", period = "day" }) => {
  if (!allData || !allData?.length) return { series: [], categories: [] }
  const fieldName = key

  const dateRanges = new Set() // Store unique date ranges
  const brandGroups = new Map() // Map to group brand followers by brand ID

  // Collect unique date ranges
  allData.forEach((item) => {
    dateRanges.add(`${item.start_date} → ${item.end_date}`)
  })

  const dateRangeArray = [...dateRanges]

  // Initialize brand groups with empty data arrays for each date range
  allData.forEach((item) => {
    if (!brandGroups.has(item.brand)) {
      brandGroups.set(item.brand, {
        id: item.brand,
        is_sub_brand: item.is_sub_brand,
        name: item?.brand_title,
        brand_name: item?.brand_name,
        color: null, // To be assigned later
        data: new Array(dateRangeArray.length).fill(null),
      })
    }
  })

  // Populate brand data into the correct index
  allData.forEach((item) => {
    const dateKey = `${item.start_date} → ${item.end_date}`
    const index = dateRangeArray.indexOf(dateKey)
    brandGroups.get(item.brand).data[index] = item[fieldName]
  })

  // Assign colors to brands
  let brandIndex = 0
  for (let brand of brandGroups.values()) {
    brand.color = colors[brandIndex % colors.length] // Cycle through colors
    brandIndex++
  }

  return {
    series: Array.from(brandGroups.values()),
    categories: dateRangeArray.map((dateRange) => {
      const dates = dateRange.split(" → ")
      return {
        start: dates[0],
        end: dates[1],
        formatted: period === "day" ? dates[0] : formatDate(dates[0], "MMM D") + " - " + formatDate(dates[1], "MMM D"),
      }
    }),
  }
}

const KPMGraphs = ({ fromDate, toDate, platform = "tiktok" }) => {
  const [data, dispatch] = useContext(dataContext)
  let competitors = data?.me?.brand?.sub_brands?.filter((x) => x?.sub_brand_type === 1).map((x) => x?.id) ?? []
  competitors?.unshift(data?.me?.brand?.id)

  const { payload: kpm_payload, loader: kpm_loader } = useLocalBoundData({
    ...GET_CALLS.key_performance_metrics,
    filter: {
      brand_id_list: competitors?.join(","),
      post_date__gte: fromDate,
      post_date__lte: toDate,
    },
    platform,
  })

  return (
    <>
      <h2 className="headline-medium-highlight margin-top-4x margin-bottom-4x">Key performance metrics</h2>
      <WrappedInLoader data={kpm_payload} loader={kpm_loader}>
        <div className="flex margin-bottom-4x">
          <BarChart
            title="Brand attention"
            subtitle="(All engagement)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_brand_engagement")}
            options={kpmGraphsOptions}
          />
          <BarChart
            title="Brand attention"
            subtitle="(likes)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_likes")}
            options={kpmGraphsOptions}
          />
          <BarChart
            title="Brand attention"
            subtitle="(comments)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_comments")}
            options={kpmGraphsOptions}
          />
          <BarChart
            title="Brand attention"
            subtitle="(shares)"
            className={`full`}
            data={KVMDataParser(kpm_payload, "total_shares")}
            options={kpmGraphsOptions}
          />
        </div>
        <div className="flex margin-bottom-8x">
          <BarChart
            title="Follower count"
            subtitle="(today)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_followers")}
            options={kpmGraphsOptions}
          />
          <BarChart
            title="# of people posting"
            subtitle="(total)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_people_posting")}
            options={kpmGraphsOptions}
          />
          <BarChart
            title="# of posts"
            subtitle="(total)"
            className={`margin-right-2x full padding-x-2x`}
            data={KVMDataParser(kpm_payload, "total_posts")}
            options={kpmGraphsOptions}
          />
        </div>
      </WrappedInLoader>
    </>
  )
}

const EngagementTimeSeries = ({ fromDate, toDate, platform = "tiktok", period = "day", onClick }) => {
  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_competitor_total_engagement,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })

  const { categories, series } = seriesDataParser({
    data: payload,
    key: "total_engagement",
    period,
  })

  const _onMarkerClick = (event, chartContext, config) => {
    if (!series[config?.seriesIndex]) return

    const brand = series[config?.seriesIndex]
    const dates = categories[config?.dataPointIndex]

    const data = {
      brand: {
        id: brand.id,
        name: brand.brand_name,
        title: brand.name,
        is_sub_brand: brand.is_sub_brand,
      },
      from_date: dates.start,
      to_date: dates.end,
    }
    onClick?.(data)
  }

  return (
    <Card className={`padding-y-5x padding-x-5x full margin-bottom-4x`}>
      <h3 className=" margin-bottom-4x headline-small-highlight">Total brand engagement</h3>
      <WrappedInLoader data={payload} loader={loader}>
        {!payload || !payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              ...timeSeriesChartOptions,
              chart: { ...timeSeriesChartOptions.chart, events: { markerClick: _onMarkerClick } },
              yaxis: {
                title: {
                  ...timeSeriesChartOptions.yaxis.title,
                  text: "Total Brand Engagement",
                },
                labels: { formatter: (x) => num2Hip(x) },
              },
              xaxis: {
                ...timeSeriesChartOptions.xaxis,
                categories: categories.map((x) => x.formatted),
              },
              tooltip: {
                custom: undefined,
              },
            }}
            series={series}
            type="line"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const FollowerCount = ({ fromDate, toDate, platform = "tiktok", period = "day" }) => {
  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_followers,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })

  const { categories, series } = seriesDataParser({
    data: payload,
    key: "followers",
    period,
  })

  const _onMarkerClick = (event, chartContext, config) => {
    // const data = series[config?.seriesIndex]?.data?.[config?.dataPointIndex]
    // // console.log(data)
    // if (onMarkerClick) {
    //   onMarkerClick(data)
    // }
  }

  return (
    <Card className={`padding-y-5x padding-x-5x full`}>
      <h3 className=" margin-bottom-4x headline-small-highlight">Follower count</h3>
      <WrappedInLoader data={payload} loader={loader}>
        {!payload || !payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              ...timeSeriesChartOptions,
              chart: { ...timeSeriesChartOptions.chart, events: { markerClick: _onMarkerClick } },
              yaxis: {
                title: {
                  ...timeSeriesChartOptions.yaxis.title,
                  text: "# of followers",
                },
                labels: { formatter: (x) => num2Hip(x) },
              },
              xaxis: {
                ...timeSeriesChartOptions.xaxis,
                categories: categories.map((x) => x.formatted),
              },
              tooltip: {
                custom: undefined,
              },
            }}
            series={series}
            type="line"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const PeoplePosting = ({ fromDate, toDate, platform = "tiktok", period = "day", onClick }) => {
  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.total_people_posted,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })

  const { categories, series } = seriesDataParser({
    data: payload,
    key: "total_people_posted",
    period,
  })

  const _onMarkerClick = (event, chartContext, config) => {
    if (!series[config?.seriesIndex]) return

    const brand = series[config?.seriesIndex]
    const dates = categories[config?.dataPointIndex]

    const data = {
      brand: {
        id: brand.id,
        name: brand.brand_name,
        title: brand.name,
        is_sub_brand: brand.is_sub_brand,
      },
      from_date: dates.start,
      to_date: dates.end,
    }
    onClick?.(data)
  }

  return (
    <Card className={`padding-y-5x padding-x-5x full`}>
      <h3 className=" margin-bottom-4x headline-small-highlight">Number of people posting</h3>
      <WrappedInLoader data={payload} loader={loader}>
        {!payload || !payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              ...timeSeriesChartOptions,
              chart: { ...timeSeriesChartOptions.chart, events: { markerClick: _onMarkerClick } },
              yaxis: {
                title: {
                  ...timeSeriesChartOptions.yaxis.title,
                  text: "# of people posting",
                },
                labels: { formatter: (x) => num2Hip(x) },
              },
              xaxis: {
                ...timeSeriesChartOptions.xaxis,
                categories: categories.map((x) => x.formatted),
              },
              tooltip: {
                custom: undefined,
              },
            }}
            series={series}
            type="line"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const BrandCompetitorsStats = () => {
  const [selectedPeriod, setSelectedPeriod] = useState("day")
  const [showPeriod, setShowPeriod] = useState(false)
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 30, "YYYY-MM-DD"))
  const [toDate, setToDate] = useState(formatDate(new Date(), "YYYY-MM-DD"))

  const [data, dispatch] = useContext(dataContext)

  const openContentPopup = (data) => {
    if (!data?.brand) {
      toast("No data on that day")
      return
    }

    dispatch({
      date_specific_dialog_data: data,
    })
  }

  useEffect(() => {
    // add scroll to section check
    const dateRangeSection = document.querySelector("#title-date")
    const timeSeriesSection = document.querySelector("#time-series-start")
    document.addEventListener("scroll", () => {
      if (dateRangeSection) {
        const rect = dateRangeSection.getBoundingClientRect()
        if (rect.top <= 60) {
          dateRangeSection.classList.add("nc00-bg", "border-bottom-1", "nc05-border")
        } else {
          dateRangeSection.classList.remove("nc00-bg", "border-bottom-1", "nc05-border")
        }
      }
      if (timeSeriesSection) {
        const rect = timeSeriesSection.getBoundingClientRect()
        if (rect.top <= 120 || rect.bottom <= window.innerHeight / 2) {
          setShowPeriod(true)
        } else {
          setShowPeriod(false)
        }
      }
    })

    return () => {
      document.removeEventListener("scroll", () => {})
    }
  }, [])

  const platform = data?.discovery_section_platform ?? "tiktok"

  return (
    <div className="padding-top-4x">
      <div className="sticky-date-bar padding-y" id="title-date">
        <div className="content-area flex gap-4 a-center">
          <h2 className="headline-large">Competitor Overview</h2>
          <MegaDateRangePicker
            className="margin-left-auto"
            fromDate={fromDate}
            toDate={toDate}
            onChange={(from, to) => {
              setFromDate(from)
              setToDate(to)
            }}
            onPeriodChange={(period) => setSelectedPeriod(period)}
            showPeriod={showPeriod}
          />
          <DiscoveryPlatformFilter />
        </div>
      </div>

      <div className="content-area">
        <div className="padding-top-4x">
          <KPMGraphs fromDate={fromDate} toDate={toDate} platform={platform} />

          <h2 className="headline-medium-highlight margin-top-4x margin-bottom-4x" id="time-series-start">
            Time series - key results
          </h2>
          <EngagementTimeSeries
            fromDate={fromDate}
            toDate={toDate}
            platform={platform}
            period={selectedPeriod}
            onClick={openContentPopup}
          />
          <div className="grid gap-4 grid-cols-2">
            <FollowerCount fromDate={fromDate} toDate={toDate} platform={platform} period={selectedPeriod} />
            <PeoplePosting
              fromDate={fromDate}
              toDate={toDate}
              platform={platform}
              period={selectedPeriod}
              onClick={openContentPopup}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export { BrandCompetitorsStats, KVMDataParser }
