import React, { useContext, useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import {
  cents2USD,
  formatDate,
  generateDateArray,
  getMonthStartAndEndDateBasedOnCurrentMonth,
  getPreviousPeriod,
  getStartDateOfWeekBasedOnCurrentWeek,
  getSubtractedDate,
  num2Hip,
} from "../../helpers/utils"
import { useLocalBoundData } from "../../hooks/useBoundData"
import { useToast } from "../../hooks/useToast"
import { Card, WrappedInLoader } from "../dumbs"
import { Select } from "../select"
import { BarChart, DonutChart, IncreaseDecreaseChart, NotEnoughData } from "../ui/graphs"
import { Navigate } from "react-router-dom"
import { GET_CALLS } from "../../consts"
import { NPSGraphs } from "./politicalDashboard"
import { DateRangePicker, MegaDateRangePicker } from "../input"
import dayjs from "dayjs"
import { DiscoveryPlatformFilter } from "../structural/contentAndCreatorFilter"
import { Button } from "../button"

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
}

const barChartConfig = {
  // colors: ["#cbbcff", "#663ff1"],
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        // Customize the displayed value here.
        // This is just a simple example.
        return num2Hip(val)
      },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      columnWidth: "80%",
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },
  dataLabels: {
    // enabled: false  // This will hide the data labels
    offsetY: -16,
    style: {
      fontSize: "12px",
      colors: ["#767B8A"],
    },
    formatter: function (val, opts) {
      // const _val = num2Hip(val, 2, 0)
      // console.log(val, _val)
      return num2Hip(val)
    },
  },
  xaxis: {
    categories: ["Owned", "Earned", "Influencer"],
    labels: {
      style: {
        colors: "#767B8A",
        fontSize: "15px",
      },
    },
  },
  yaxis: {
    show: false,
  },
  grid: {
    show: false, // This will hide the grid
    padding: {
      left: 0,
    },
  },
  stroke: {
    colors: ["transparent"],
    width: 3,
  },
}

const brandKVMDataParser = (allData, fieldName) => {
  if (!allData || (!allData?.last_period && !allData?.current_period)) return []
  let current_period = allData?.current_period?.[fieldName] ?? 0
  let last_period = allData?.last_period?.[fieldName] ?? 0

  if (fieldName === "engagement_rate") {
    current_period = (current_period * 100).toFixed(1)
    last_period = (last_period * 100).toFixed(1)
  } else if (fieldName == "total_emv_in_cents") {
    current_period = cents2USD(current_period)
    last_period = cents2USD(last_period)
  }

  if (fieldName === "nps") {
    const positive = allData?.current_period?.total_positive_nps ?? 0
    const negative = allData?.current_period?.total_negative_nps ?? 0
    const neutral = allData?.current_period?.total_neutral_nps ?? 0

    const _total = positive + negative + neutral
    if (_total == 0) return null

    let nps = [
      (positive / _total).toFixed(4) * 100,
      (neutral / _total).toFixed(4) * 100,
      (negative / _total).toFixed(4) * 100,
    ]
    // nps = nps.map(x=>x.toFixed(2))
    return nps
  }
  // console.log(fieldName, last_period, current_period)

  return [
    {
      x: "previous period",
      y: last_period,
    },
    {
      x: "this period",
      y: current_period,
    },
  ]
}

const SplittedComparisonChart = ({ data, title, onBarClick, dataLabelFormatter }) => {
  return (
    <Card className="full">
      <h3 className="border-bottom-1 nc05-border padding-x-4x padding-y-3x margin-bottom headline-small-highlight flex">
        {title}
      </h3>
      {data && data.length ? (
        <Chart
          series={data}
          options={{
            ...barChartConfig,
            chart: {
              ...barChartConfig.chart,
              events: {
                click: onBarClick,
              },
            },
            dataLabels: {
              ...barChartConfig.dataLabels,
              formatter: dataLabelFormatter ?? barChartConfig.dataLabels.formatter,
            },
          }}
          type="bar"
          height={250}
        />
      ) : (
        <NotEnoughData />
      )}
    </Card>
  )
}

const brandSplitKVMDataParser = (allData, fieldName, fieldPrefix = "total_") => {
  if (!allData || (!allData?.last_period && !allData?.current_period)) return []

  const _data = {
    last_period: [
      allData?.last_period?.[`${fieldPrefix}owned_${fieldName}`] ?? 0,
      allData?.last_period?.[`${fieldPrefix}earned_${fieldName}`] ?? 0,
      allData?.last_period?.[`${fieldPrefix}influencer_${fieldName}`] ?? 0,
    ],
    current_period: [
      allData?.current_period?.[`${fieldPrefix}owned_${fieldName}`] ?? 0,
      allData?.current_period?.[`${fieldPrefix}earned_${fieldName}`] ?? 0,
      allData?.current_period?.[`${fieldPrefix}influencer_${fieldName}`] ?? 0,
    ],
  }
  if (fieldName == "emv_in_cents") {
    _data.last_period = _data.last_period.map((x) => cents2USD(x))
    _data.current_period = _data.current_period.map((x) => cents2USD(x))
  }

  return [
    {
      name: "Previous period",
      color: "#cbbcff",
      data: _data.last_period,
    },
    {
      name: "This period",
      color: "#663FF1",
      data: _data.current_period,
    },
  ]
}

export const timeseriesdataparcer = (
  allData,
  fieldName,
  brand_id,
  numberOfDays = 60,
  skipFillZero = false,
  colorConfig,
  period = "day",
  sort = false,
) => {
  if (!allData) return
  const _data = {}

  for (let d of allData) {
    const date = period === "week" ? getStartDateOfWeekBasedOnCurrentWeek(d?.week, d?.year) : d?.date

    if (!_data?.[d?.brand_name]?.data) {
      _data[d.brand_name] = {
        data: [
          {
            x: date,
            y: d[fieldName],
            originalValue: d,
          },
        ],
        name: d?.brand_title ?? d?.brand_name,
        color: colorConfig?.[d?.brand]?.color,
      }
    } else {
      _data[d.brand_name].data.push({
        x: date,
        y: d[fieldName],
        originalValue: d,
      })
    }
  }
  if (!skipFillZero) {
    for (let e in _data) {
      _data[e].data = generateDateArray(numberOfDays, _data[e].data)
    }
  }
  const _d = Object.keys(_data)?.map((brand_name) => ({ ..._data[brand_name], data: _data[brand_name]?.data }))
  if (sort) {
    _d.map((brandData) => {
      brandData?.data?.sort((a, b) => new Date(a.x) - new Date(b.x))
    })
  }

  return _d
}

const brand_performance_parser = (allData, data_type, period = "day") => {
  if (!allData) return
  const data1 = []
  const data2 = []
  const data3 = []
  const category = []

  let data_key = ""
  switch (data_type) {
    case "posts":
      data_key = `total_posts`
      break
    case "views":
      data_key = `total_views`
      break
    case "emv":
      data_key = `total_emv_in_cents`
      break
    default:
      data_key = `total_post_mentions_count`
  }

  allData.forEach((x, i) => {
    data1.push(data_type == "emv" ? cents2USD(x[data_key]).toFixed(1) : x[data_key])
    data2.push(x["total_comment_mentions_count"])
    data3.push(x["total_posts"] - (x["total_post_mentions_count"] + x["total_comment_mentions_count"]))
    category.push(
      period == "day" ? x?.start_date : formatDate(x?.start_date, "MMM D") + " - " + formatDate(x?.end_date, "MMM D"),
    )
  })

  const data = {
    series: [
      {
        name:
          data_type === "posts"
            ? "Posts"
            : data_type === "views"
              ? "Views"
              : data_type === "emv"
                ? "EMV"
                : "Mentioned in post",
        data: data1,
      },
    ],
    category,
  }

  if (data_type === "mentions") {
    // data.series.push({
    //   name: "Indirect Mention",
    //   data: data3,
    // })
    data.series.push({
      name: "Mentioned in comment",
      data: data2,
    })
  }
  return data
}

const engagementParser = (allData, time, activeLegends) => {
  if (!allData) return
  const totalLikes = []
  const totalComments = []
  const totalShares = []
  const rollingAverage = []
  const category = []

  if (time === "timeframe=365&period=month") {
    //sort by month & year both
    allData.sort((a, b) => {
      if (a.year < b.year) return -1
      if (a.year > b.year) return 1
      return a.month - b.month
    })
  } else if (time === "timeframe=84&period=week") {
    allData.sort((a, b) => {
      return a.week - b.week
    })
  } else {
    // 2024-06-05 this is the date format
    allData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
  }
  allData.forEach((element, i) => {
    totalLikes.push(element.total_likes)
    totalComments.push(element.total_comments)
    totalShares.push(element.total_shares)
    category.push(
      time === "timeframe=365&period=month"
        ? `${monthMap[element.month]}`
        : time == "timeframe=84&period=week"
          ? formatDate(getStartDateOfWeekBasedOnCurrentWeek(element.week))
          : element.date,
    )

    // if activeLegends has 0,1,3 then show all data
    // if activeLegends has 0,1 then show only sum of likes and comments
    // if activeLegends has 0,2 then show only sum of likes and shares
    // if activeLegends has 1,2 then show only sum of comments and shares
    // if activeLegends has 0 then show only likes
    // if activeLegends has 1 then show only comments
    // if activeLegends has 2 then show only shares
    // if activeLegends has nothing then empty the data

    if (activeLegends.length === 3) {
      rollingAverage.push(
        (element.rolling_avg_likes + element.rolling_avg_comments + element.rolling_avg_shares)?.toFixed(2),
      )
    } else if (activeLegends.length === 2) {
      if (activeLegends.includes(0) && activeLegends.includes(1)) {
        rollingAverage.push((element.rolling_avg_likes + element.rolling_avg_comments)?.toFixed(2))
      } else if (activeLegends.includes(0) && activeLegends.includes(2)) {
        rollingAverage.push((element.rolling_avg_likes + element.rolling_avg_shares)?.toFixed(2))
      } else if (activeLegends.includes(1) && activeLegends.includes(2)) {
        rollingAverage.push((element.rolling_avg_comments + element.rolling_avg_shares)?.toFixed(2))
      }
    } else if (activeLegends.length === 1) {
      if (activeLegends.includes(0)) {
        rollingAverage.push(element.rolling_avg_likes?.toFixed(2))
      } else if (activeLegends.includes(1)) {
        rollingAverage.push(element.rolling_avg_comments?.toFixed(2))
      } else if (activeLegends.includes(2)) {
        rollingAverage.push(element.rolling_avg_shares?.toFixed(2))
      }
    } else {
      rollingAverage.push(0)
    }
    // rollingAverage.push(time === "timeframe=84&period=week" ? element.rolling_avg_all_weekly?.toFixed(2) : element.rolling_avg_all?.toFixed(2))
  })

  // dummy data for days
  // if(time == "timeframe=12"){
  //     for (let i = 0; i < 31; i++) {
  //         category.push(i + 1)
  //         totalLikes.push(Math.floor(Math.random() * 1000000) + 1)
  //         totalComments.push(Math.floor(Math.random() * 1000000) + 1)
  //         totalShares.push(Math.floor(Math.random() * 1000000) + 1)
  //         rollingAverage.push(Math.floor(Math.random() * 1000000) + 1)
  //     }
  // }

  return {
    series: [
      {
        name: "Likes",
        data: totalLikes,
        type: "bar",
      },
      {
        name: "Comments",
        data: totalComments,
        type: "bar",
      },
      {
        name: "Shares",
        data: totalShares,
        type: "bar",
      },
      // {
      //     name: "Rolling Average",
      //     data: rollingAverage,
      //     type: 'line',
      // }
    ],
    category,
  }
}

const engagement_comparison_parser = (allData, data_type, source_type, period) => {
  if (!allData) return { series: [], categories: [] }

  let source_prefix = ""
  switch (source_type) {
    case "owned":
      source_prefix = "owned_"
      break
    case "earned":
      source_prefix = "earned_"
      break
    case "influencer":
      source_prefix = "influencer_"
      break
    default:
      source_prefix = ""
  }
  let data_key = ""
  switch (data_type) {
    case "engagements":
      data_key = `total_${source_prefix}engagement`
      break
    case "views":
      data_key = `total_${source_prefix}views`
      break
    case "comments":
      data_key = `total_${source_prefix}comments`
      break
    case "shares":
      data_key = `total_${source_prefix}shares`
      break
    case "engagement-rate":
    default:
      data_key = `${source_prefix}engagement_rate`
  }

  const categories = []
  const data = []

  allData?.forEach((element, i) => {
    categories.push(
      period == "day"
        ? element?.start_date
        : formatDate(element?.start_date, "MMM D") + " - " + formatDate(element?.end_date, "MMM D"),
    )
    if (data_type === "engagement-rate") {
      data.push((element?.[data_key] * 100).toFixed(2))
    } else {
      data.push(element?.[data_key] ?? 0)
    }
  })

  return {
    series: data,
    categories,
  }
}

const NPSstream = (allData, period = "day") => {
  if (!allData || !allData.length) return { series: [], categories: [], originalData: [] }
  const categories = []
  const originalData = []
  const series = [
    {
      name: "negative",
      type: "column",
      data: [],
    },

    {
      name: "neutral",
      type: "column",
      data: [],
    },
    {
      name: "positive",
      type: "column",
      data: [],
    },
  ]

  allData?.forEach((element, i) => {
    const _total =
      (element?.total_positive_nps ?? 0) + (element?.total_negative_nps ?? 0) + (element?.total_neutral_nps ?? 0)
    if (!_total) {
      console.log("nps graph || skipping for zero  value")
      return
    }

    categories.push(
      period == "day"
        ? element?.start_date
        : formatDate(element?.start_date, "MMM D") + " - " + formatDate(element?.end_date, "MMM D"),
    )
    series[0].data.push(((element?.total_negative_nps / _total) * 100).toFixed(2))
    series[1].data.push(((element?.total_neutral_nps / _total) * 100).toFixed(2))
    series[2].data.push(((element?.total_positive_nps / _total) * 100).toFixed(2))

    originalData.push(element)
  })

  return {
    series,
    categories,
    originalData,
  }
}

let multiGraph = {
  series: [
    {
      name: "negative",
      type: "column",
      data: [4, 34, 2, 22, 2, 4, 34, 2, 22, 2],
      // stackedGroup: 'group1'
    },

    {
      name: "neutral",
      type: "column",
      data: [12, 0, 10, 8, 8, 12, 0, 10, 8, 8],
      // stackedGroup: 'group1'
    },
    {
      name: "positive",
      type: "column",
      data: [84, 66, 90, 70, 90, 84, 66, 90, 70, 90],
      // stackedGroup: 'group1'
    },
  ],
  options: {
    dataLabels: {
      // enabled: false  // This will hide the data labels
      enabled: false,
    },
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 0, 0, 0],
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#DD5C5C", "#FF9D00", "#43D37D", "#000000"],
    xaxis: {
      // type:"datetime"
    },
    yaxis: [
      {
        max: 100,
        min: 0,
        tickAmount: 4,
        // title: {
        //   text: "Response %",
        // },
      },
      {
        max: 100,
        show: false,
        min: 0,
        // title: {
        //   text: "Response %",
        // },
      },
      {
        max: 100,
        min: 0,
        show: false,
        // title: {
        //   text: "Response %",
        // },
      },
      // {
      //     seriesName: 'NPS',
      //     opposite: true,
      //     min: -100,
      //     max: 100,
      //     forceNiceScale: false,
      //     tickAmount: 4,
      //     title: {
      //         text: "NPS"
      //     }
      // }
    ],
    markers: {
      size: 5,
    },
  },
}

const PeriodComparison = ({ className = "", fromDate, toDate, platform = "tiktok", onChartClick }) => {
  const [data, dispatch] = useContext(dataContext)

  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.brand_key_performance_metrics,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
    },
    platform,
  })

  function determineNpsColor(data) {
    const currentNegative = data?.nps_based_content_count?.current_day?.total_negative_nps ?? 0
    const lastNegative = data?.nps_based_content_count?.last_day?.total_negative_nps ?? 0

    const currentTotal = data?.nps_based_content_count?.current_day?.total_posts ?? 1 // Avoid division by zero
    const lastTotal = data?.nps_based_content_count?.last_day?.total_posts ?? 1

    const currentNegativePercentage = (currentNegative / currentTotal) * 100
    const lastNegativePercentage = (lastNegative / lastTotal) * 100

    const increasePercentage = lastNegative > 0 ? ((currentNegative - lastNegative) / lastNegative) * 100 : 0

    if (currentNegativePercentage > 25 || increasePercentage > 150) {
      return { color: "Red", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%" }
    }

    if (currentNegativePercentage > 10 || increasePercentage > 75) {
      return { color: "Orange", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%" }
    }

    return { color: "Green", currentNegativePercentage: currentNegativePercentage.toFixed(2) + "%" }
  }

  const onTodaySentimentClick = () => {
    const brand = data?.me?.brand
    const _data = {
      brand: {
        id: brand?.id,
        title: brand?.title,
        name: brand?.name,
        is_sub_brand: brand?.is_sub_brand,
      },
      from_date: dayjs().format("YYYY-MM-DD"),
      to_date: dayjs().format("YYYY-MM-DD"),
    }
    if (determineNpsColor(payload).color === "red") {
      _data.nps_score = "-1"
    }

    onChartClick?.(_data)
  }
  const onIncreaseDecreaseChartClick = (data, index) => {
    if (!payload.current_period && !payload.last_period) return
    const currentOrLast = index === 0 ? "last_period" : "current_period"
    let startDate = fromDate
    let endDate = toDate
    if (currentOrLast === "last_period") {
      const _dates = getPreviousPeriod(fromDate, toDate)
      startDate = _dates.startDate
      endDate = _dates.endDate
    }
    const _data = {
      brand: {
        id: payload?.current_period?.brand,
        title: payload?.current_period?.brand_title,
        name: payload?.current_period?.brand_name,
        is_sub_brand: payload?.current_period?.is_sub_brand,
      },
      from_date: startDate,
      to_date: endDate,
    }

    onChartClick?.(_data)
  }

  const onDonutClick = (dataPointIndex) => {
    // 0 pos, 1 neu, 2 neg
    if (!payload.current_period && !payload.last_period) return
    const currentOrLast = dataPointIndex === 0 ? "last_period" : "current_period"
    let startDate = fromDate
    let endDate = toDate
    if (currentOrLast === "last_period") {
      const _dates = getPreviousPeriod(fromDate, toDate)
      startDate = _dates.startDate
      endDate = _dates.endDate
    }
    const _data = {
      brand: {
        id: payload?.current_period?.brand,
        title: payload?.current_period?.brand_title,
        name: payload?.current_period?.brand_name,
        is_sub_brand: payload?.current_period?.is_sub_brand,
      },
      from_date: startDate,
      to_date: endDate,
      filters: {
        nps_score: dataPointIndex === 0 ? "1" : dataPointIndex === 1 ? "0" : "-1",
      },
    }

    onChartClick?.(_data)
  }

  const onKeyPerformanceIndicatorClick = (event, chartContext, config) => {
    if (!payload.current_period && !payload.last_period) return

    const currentOrLast = config.seriesIndex === 0 ? "last_period" : "current_period"
    let startDate = fromDate
    let endDate = toDate
    if (currentOrLast === "last_period") {
      const _dates = getPreviousPeriod(fromDate, toDate)
      startDate = _dates.startDate
      endDate = _dates.endDate
    }
    const _data = {
      brand: {
        id: payload?.current_period?.brand,
        title: payload?.current_period?.brand_title,
        name: payload?.current_period?.brand_name,
        is_sub_brand: payload?.current_period?.is_sub_brand,
      },
      from_date: startDate,
      to_date: endDate,
    }

    let filters = {}
    if (config.dataPointIndex === 0) {
      // Owned
      filters = {
        is_owned_by_brand: true,
      }
    } else if (config.dataPointIndex === 1) {
      // Earned
      filters = {
        is_influencer_content: false,
        is_owned_by_brand: false,
      }
    } else if (config.dataPointIndex === 2) {
      // Influencer
      filters = {
        is_influencer_content: true,
      }
    }
    _data.filters = filters

    onChartClick?.(_data)
  }

  return (
    <>
      <WrappedInLoader data={payload} loader={loader}>
        <div className="grid grid-cols-4 gap-4 margin-bottom-6x">
          <Card className={`padding-top-5x full ${className}`}>
            <h3 className="padding-x-5x margin-bottom headline-small-highlight flex">Today’s Sentiment Status</h3>
            <div className="txt-center padding-x-8x padding-y-6x margin-top-8x">
              {payload?.nps_based_content_count?.current_day ? (
                <>
                  {determineNpsColor(payload).color === "red" ? (
                    <>
                      <div
                        className="negative-bg border-radius-5 padding-y-4x nc00-fg margin-bottom-3x"
                        style={{ backgroundColor: "#ed2e2e" }}
                      >
                        Red
                      </div>
                      <div className="margin-bottom-3x headline-small-highlight">
                        Significant increase in negative engagement
                      </div>
                    </>
                  ) : determineNpsColor(payload).color === "orange" ? (
                    <>
                      <div
                        className="orange-bg border-radius-5 padding-y-4x nc00-fg margin-bottom-3x"
                        style={{ backgroundColor: "#ffa500" }}
                      >
                        Orange
                      </div>
                      <div className="margin-bottom-3x headline-small-highlight">
                        Moderate increase in negative engagement
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="positive-bg border-radius-5 padding-y-4x nc00-fg margin-bottom-3x"
                        style={{ backgroundColor: "#32eac9" }}
                      >
                        Green
                      </div>
                    </>
                  )}
                  <Button type="ghost" className="margin-x-auto" onClick={onTodaySentimentClick}>
                    See Content
                  </Button>
                </>
              ) : (
                <NotEnoughData heightClassName="min-height-160" />
              )}
            </div>
          </Card>
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="Total # of posts"
            series={brandKVMDataParser(payload, "total_posts") ?? []}
            width={290}
            onBarClick={onIncreaseDecreaseChartClick}
          />
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="Total # of views"
            series={brandKVMDataParser(payload, "total_views") ?? []}
            width={290}
            onBarClick={onIncreaseDecreaseChartClick}
          />
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="EMV"
            series={brandKVMDataParser(payload, "total_emv_in_cents") ?? []}
            onBarClick={onIncreaseDecreaseChartClick}
            numberStyler={(x) => `$${num2Hip(x)}`}
          />
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="Total # of engagements"
            series={brandKVMDataParser(payload, "total_brand_engagement") ?? []}
            width={290}
            onBarClick={onIncreaseDecreaseChartClick}
          />
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="Engagement rate"
            series={brandKVMDataParser(payload, "engagement_rate") ?? []}
            width={290}
            numberStyler={(x) => `${x}%`}
            onBarClick={onIncreaseDecreaseChartClick}
          />
          <IncreaseDecreaseChart
            className="padding-bottom-3x"
            title="Total # of @mentions"
            series={brandKVMDataParser(payload, "total_mentions_count") ?? []}
            width={290}
          // onBarClick={onIncreaseDecreaseChartClick}
          />
          <DonutChart
            className="padding-top-3x-important padding-bottom-3x-important"
            titleClassName="border-bottom-1 nc05-border padding-bottom-3x"
            title="Sentiment"
            data={brandKVMDataParser(payload, "nps")}
            colors={["#32EAC9", "#C9F8FF", "#F67985"]}
            options={{
              plotOptions: {
                pie: {
                  startAngle: 0,
                  endAngle: 360,
                  donut: {
                    size: "55%",
                    labels: {
                      show: false,
                    },
                  },
                },
              },
            }}
            onDonutClick={onDonutClick}
          />
        </div>
      </WrappedInLoader>
      <div className="flex j-space-between margin-bottom-4x">
        <h3 className="headline-medium-highlight">Key performance indicators about your brand</h3>
        <div className="flex gap-4 headline-small-regular nc50-fg">
          <div className="flex gap-1 vcenter">
            <span className="border-radius-3 display-inline-block min-height-10 min-width-10 rb40-bg"></span>
            <span>Previous Period</span>
          </div>
          <div className="flex gap-1 vcenter">
            <span className="border-radius-3 display-inline-block min-height-10 min-width-10 rb100-bg"></span>
            <span>This Period</span>
          </div>
        </div>
      </div>
      <div>
        <WrappedInLoader data={payload} loader={loader}>
          <div className="grid grid-cols-3 gap-4 margin-bottom-4x">
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "posts")}
              title="# of posts"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "views")}
              title="# of views"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "emv_in_cents")}
              title="EMV"
              onBarClick={onKeyPerformanceIndicatorClick}
              dataLabelFormatter={(val) => `$${num2Hip(val)}`}
            />
          </div>
          <div className="grid grid-cols-4 gap-4">
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "engagement")}
              title="# of engagements"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "shares")}
              title="# of shares"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "comments")}
              title="# of comments"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
            <SplittedComparisonChart
              data={brandSplitKVMDataParser(payload, "likes")}
              title="# of likes"
              onBarClick={onKeyPerformanceIndicatorClick}
            />
          </div>
        </WrappedInLoader>
      </div>
    </>
  )
}

const EngagementComparison = ({ fromDate, toDate, platform = "tiktok", period = "day", onBarClick }) => {
  const [type, setType] = useState("engagements")
  const [dataGroup, setDataGroup] = useState("all")

  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_brand_engagement_comparison,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })
  const { series, categories } = engagement_comparison_parser(payload, type, dataGroup, period)

  const onBarClicked = (event, chartContext, config) => {
    const data = payload?.[config.dataPointIndex]
    if (!data) return

    const _data = {
      brand: {
        id: data?.brand_id,
        title: data?.brand_title,
        name: data?.brand_name,
        is_sub_brand: data?.is_sub_brand,
      },
      from_date: data?.start_date,
      to_date: data?.end_date,
    }
    onBarClick?.(_data)
  }

  return (
    <Card className={`padding-y-5x padding-x-5x full `}>
      <div className="flex vcenter j-space-between margin-bottom-4x">
        <h3 className=" body-highlight-font">Engagement comparison</h3>
        <div className="flex vcenter j-end gap-2">
          <button
            type="button"
            onClick={() => setType("engagements")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "engagements" ? "nc75-fg" : "nc30-fg"}`}
          >
            Engagements
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("views")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "views" ? "nc75-fg" : "nc30-fg"}`}
          >
            Views
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("comments")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "comments" ? "nc75-fg" : "nc30-fg"}`}
          >
            Comments
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("shares")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "shares" ? "nc75-fg" : "nc30-fg"}`}
          >
            Shares
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("engagement-rate")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "engagement-rate" ? "nc75-fg" : "nc30-fg"}`}
          >
            Engagement Rate
          </button>
        </div>
      </div>

      <WrappedInLoader data={payload} loader={loader}>
        {!payload || !payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              chart: {
                type: "bar",
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
                events: {
                  click: onBarClicked,
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                },
              },
              colors: ["#663FF1"],
              xaxis: {
                categories: categories,
              },
              yaxis: {
                labels: {
                  formatter: (x) => (type === "engagement-rate" ? `${num2Hip(x)}%` : num2Hip(x)),
                },
              },
              dataLabels: {
                offsetY: -20,
                style: {
                  fontSize: "10px",
                  colors: ["#767B8A"],
                },
                formatter: (val) => (type === "engagement-rate" ? `${num2Hip(val)}%` : num2Hip(val)),
              },
              grid: {
                borderColor: "#F1F2F3",
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
              },
            }}
            series={[
              {
                name: type === "engagement-rate" ? "Engagement Rate" : type,
                data: series,
              },
            ]}
            height={350}
            type="bar"
          />
        )}
        <div className="flex vcenter j-center gap-2">
          <button
            type="button"
            onClick={() => setDataGroup("all")}
            className={`cursor-pointer transparent-bg transparent-border ${dataGroup === "all" ? "nc75-fg" : "nc30-fg"}`}
          >
            All
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setDataGroup("owned")}
            className={`cursor-pointer transparent-bg transparent-border ${dataGroup === "owned" ? "nc75-fg" : "nc30-fg"}`}
          >
            owned
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setDataGroup("earned")}
            className={`cursor-pointer transparent-bg transparent-border ${dataGroup === "earned" ? "nc75-fg" : "nc30-fg"}`}
          >
            earned
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setDataGroup("influencer")}
            className={`cursor-pointer transparent-bg transparent-border ${dataGroup === "influencer" ? "nc75-fg" : "nc30-fg"}`}
          >
            influencer
          </button>
        </div>
      </WrappedInLoader>
    </Card>
  )
}

const PerformanceComparison = ({
  className = "",
  fromDate,
  toDate,
  delay = 200,
  platform = "tiktok",
  period = "day",
  onBarClick,
}) => {
  const [type, setType] = useState("mentions")
  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_brand_performance_comparison,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })

  const _value = brand_performance_parser(payload, type, period)

  const onBarClicked = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0) return
    const data = payload?.[config.dataPointIndex]
    if (!data) return
    const _data = {
      brand: {
        id: data?.brand_id,
        title: data?.brand_title,
        name: data?.brand_name,
        is_sub_brand: data?.is_sub_brand,
      },
      from_date: data?.start_date,
      to_date: data?.end_date,
    }
    if (type === "mentions") {
      _data.filters = {
        mention_type: config.seriesIndex === 0 ? 1 : 2,
      }
    }
    onBarClick?.(_data)
  }

  let dataLabelConfig = {}
  if (type == "mentions") {
    dataLabelConfig = {
      offsetY: 0,
      style: {
        fontSize: "10px",
        colors: ["#fff"],
      },
    }
  } else {
    dataLabelConfig = {
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: ["#767B8A"],
      },
    }
  }
  return (
    <Card className={`padding-y-5x padding-x-5x full margin-bottom-4x ${className}`}>
      <div className="flex j-space-between">
        <h3 className="headline-small-highlight">Performance Comparison</h3>
        <div className="flex vcenter j-end gap-2">
          <button
            type="button"
            onClick={() => setType("mentions")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "mentions" ? "nc75-fg" : "nc30-fg"}`}
          >
            Mentions
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("posts")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "posts" ? "nc75-fg" : "nc30-fg"}`}
          >
            Posts
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("views")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "views" ? "nc75-fg" : "nc30-fg"}`}
          >
            Views
          </button>
          <span className="nc10-fg body-small-font">/</span>
          <button
            type="button"
            onClick={() => setType("emv")}
            className={`cursor-pointer transparent-bg transparent-border ${type === "emv" ? "nc75-fg" : "nc30-fg"}`}
          >
            EMV
          </button>
        </div>
      </div>
      <WrappedInLoader data={payload} loader={loader}>
        {!payload || !payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              yaxis: {
                title: {
                  text: "Total mentions",
                },
              },
              chart: {
                type: "bar",
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
                events: {
                  click: onBarClicked,
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    position: type === "mentions" ? "center" : "top", // top, center, bottom
                  },
                },
              },
              xaxis: {
                categories: _value?.category,
              },
              yaxis: {
                labels: {
                  formatter: (x) => (type === "emv" ? `$${num2Hip(x)}` : num2Hip(x)),
                },
              },
              dataLabels: {
                ...dataLabelConfig,
                formatter: function (val, opts) {
                  return val > 0 ? num2Hip(val) : ""
                },
              },
              grid: {
                borderColor: "#F1F2F3",
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  lines: {
                    show: true,
                  },
                },
              },
            }}
            series={_value.series}
            type="bar"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const SentimentTimeSeries = ({ fromDate, toDate, platform = "tiktok", period = "day", onBarClick }) => {
  const { payload: nps_payload, loader: nps_loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_nps,
    filter: {
      post_date__gte: fromDate,
      post_date__lte: toDate,
      platform,
      period,
    },
  })

  const { series, categories, originalData } = NPSstream(nps_payload, period)

  const onNpsDateClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0) return
    const nps_score_map = {
      0: "-1",
      1: "0",
      2: "1",
    }
    const data = originalData[config?.dataPointIndex]
    if (!data) return
    onBarClick?.({
      brand: {
        id: data?.brand,
        title: data?.brand_title,
        name: data?.brand_name,
        is_sub_brand: data?.is_sub_brand,
      },
      filters: {
        nps_score: nps_score_map[config?.seriesIndex],
      },
      from_date: data?.start_date,
      to_date: data?.end_date,
    })
  }

  return (
    <Card className={`full padding-y-5x margin-bottom-4x`}>
      <h3 className="headline-small-highlight margin-bottom-4x padding-x-5x">{"Sentiment"}</h3>
      <WrappedInLoader data={nps_payload} loader={nps_loader}>
        {!nps_payload || !nps_payload?.length ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              ...{
                ...multiGraph.options,
                colors: ["#F67979", "#C9FFF5", "#32EAC9"],
                grid: {
                  borderColor: "#F1F2F3",
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
                xaxis: {
                  categories,
                },
                tooltip: {
                  y: {
                    formatter: (val) => `${num2Hip(val)}%`,
                  },
                },
                states: {
                  hover: {
                    filter: {
                      type: "none",
                    },
                  },
                },
              },
              chart: { ...multiGraph.options.chart, events: { click: onNpsDateClick } },
            }}
            series={series}
            type="bar"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const BrandStats = (props) => {
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 30, "YYYY-MM-DD"))
  const [toDate, setToDate] = useState(formatDate(new Date(), "YYYY-MM-DD"))
  const [period, setPeriod] = useState("day")
  const [showPeriod, setShowPeriod] = useState(false)

  const [data, dispatch] = useContext(dataContext)

  const platform = data?.discovery_section_platform ?? "tiktok"

  const toast = useToast()

  const openContentPopup = (data) => {
    if (!data?.brand) {
      toast("No data on that day")
      return
    }

    dispatch({
      date_specific_dialog_data: data,
    })
  }

  useEffect(() => {
    // add scroll to section check
    const dateRangeSection = document.querySelector("#title-date")
    const timeSeriesSection = document.querySelector("#time-series-start")
    document.addEventListener("scroll", () => {
      if (dateRangeSection) {
        const rect = dateRangeSection.getBoundingClientRect()
        if (rect.top <= 60) {
          dateRangeSection.classList.add("nc00-bg", "border-bottom-1", "nc05-border")
        } else {
          dateRangeSection.classList.remove("nc00-bg", "border-bottom-1", "nc05-border")
        }
      }
      if (timeSeriesSection) {
        const rect = timeSeriesSection.getBoundingClientRect()
        // console.log(rect.bottom, window.innerHeight / 2)
        if (rect.top <= 120 || rect.bottom <= window.innerHeight / 2) {
          setShowPeriod(true)
        } else {
          setShowPeriod(false)
        }
      }
    })

    return () => {
      document.removeEventListener("scroll", () => { })
    }
  }, [])

  if (data?.me?.brand?.settings?.is_political_engage_enabled) {
    // navigate to comments dashboard page
    return <Navigate to="/brand-dashboard/comments" />
  }

  return (
    <div className="padding-top-4x">
      <div className="sticky-date-bar padding-y" id="title-date">
        <div className="content-area flex gap-4 a-center">
          <h2 className="headline-large">Brand Analytics</h2>
          <MegaDateRangePicker
            className="margin-left-auto"
            fromDate={fromDate}
            toDate={toDate}
            onChange={(from, to) => {
              setFromDate(from)
              setToDate(to)
            }}
            onPeriodChange={(period) => setPeriod(period)}
            showPeriod={showPeriod}
          />
          <DiscoveryPlatformFilter />
        </div>
      </div>
      <div className="content-area">
        <div className="padding-top-4x">
          <PeriodComparison fromDate={fromDate} toDate={toDate} platform={platform} onChartClick={openContentPopup} />
          <div className="flex j-space-between a-center">
            <h2 className="headline-medium-highlight margin-top-8x margin-bottom-4x" id="time-series-start">
              Time series data for your brand
            </h2>
          </div>
          <PerformanceComparison
            fromDate={fromDate}
            toDate={toDate}
            platform={platform}
            period={period}
            onBarClick={openContentPopup}
          />
          <SentimentTimeSeries
            fromDate={fromDate}
            toDate={toDate}
            platform={platform}
            period={period}
            onBarClick={openContentPopup}
          />
          <EngagementComparison
            fromDate={fromDate}
            toDate={toDate}
            platform={platform}
            period={period}
            onBarClick={openContentPopup}
          />
        </div>
      </div>
    </div>
  )
}

export { BrandStats }
